export const validateEmail = (value: string) => {
  let user = value.substring(0, value.indexOf('@'));
  let domain = value.substring(value.indexOf('@') + 1, value.length);

  if (
    user.length >= 1 &&
    domain.length >= 3 &&
    user.search('@') == -1 &&
    domain.search('@') == -1 &&
    user.search(' ') == -1 &&
    domain.search(' ') == -1 &&
    domain.search('.') != -1 &&
    domain.indexOf('.') >= 1 &&
    domain.lastIndexOf('.') < domain.length - 1
  )
    return true;
};

export const validatePhone = (value: string) => {
  if (value.length === 17) return true;
  return false;
};

export const cpfMask = (cpfValue: string) => {
  return cpfValue
    .replace(/\D+/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
};

export const phoneMask = (phoneValue: string) => {
  const onlyPhoneDigits = phoneValue.replace(/\D/g, '');

  return onlyPhoneDigits.replace(
    /^(\d{0,2})(\d{0,2})(\d{0,5})(\d{0,4})$/,
    function (match, ddd, prefix, sufix1, sufix2) {
      let formattedPhone = '+';
      if (ddd) {
        formattedPhone += ddd;
        if (prefix) {
          formattedPhone += ' ' + prefix;
          if (sufix1) {
            formattedPhone += ' ' + sufix1;
            if (sufix2) {
              formattedPhone += ' ' + sufix2;
            }
          }
        }
      }
      return formattedPhone;
    },
  );
};

export const passportMask = (passportValue: string) => {
  return passportValue.replace(/\D+/g, '');
};

export const removeSpecialCharacters = (value: string) => {
  value = value.split('.').join('');
  value = value.split('-').join('');
  value = value.split('(').join('');
  value = value.split(')').join('');
  value = value.split(' ').join('');
  value = value.split('+').join('');
  return value;
};

export const validateCPF = (strCPF: string) => {
  if (!strCPF) return false;
  strCPF = strCPF.replace(/[^\d]+/g, '');
  let sum;
  let remainder;
  sum = 0;
  if (
    strCPF.length !== 11 ||
    strCPF === '00000000000' ||
    strCPF === '11111111111' ||
    strCPF === '22222222222' ||
    strCPF === '33333333333' ||
    strCPF === '44444444444' ||
    strCPF === '55555555555' ||
    strCPF === '66666666666' ||
    strCPF === '77777777777' ||
    strCPF === '88888888888' ||
    strCPF === '99999999999'
  ) {
    return false;
  }

  for (let i = 1; i <= 9; i += 1) {
    sum += parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  }
  remainder = (sum * 10) % 11;
  if (remainder === 10 || remainder === 11) remainder = 0;
  if (remainder !== parseInt(strCPF.substring(9, 10))) return false;
  sum = 0;
  for (let i = 1; i <= 10; i++) {
    sum += parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  }
  remainder = (sum * 10) % 11;
  if (remainder === 10 || remainder === 11) remainder = 0;
  if (remainder !== parseInt(strCPF.substring(10, 11))) return false;
  return true;
};

export const escapeSpecialCharacters = (content: string) => {
  return content.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
};
