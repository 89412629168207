import { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { WarningModal } from './WarningModal';

import {
  ButtonsArea,
  IconContainer,
  MainContainer,
  StyledIcon,
  TextContainer,
  StyledText,
  StyledTitle,
  StyledLinkButton,
  Container,
} from './styled';
import { getWorksheetUrl } from '../../helpers/getWorksheetUrl';
import { EmployeesWorksheetSteps, ImportStatus, ImportType } from '../../types';
import { worksheetComponentInfo } from '../../componentsText';

interface ProgressCardsProps {
  importProcess: any;
  importStatus: ImportStatus;
  successRowsQt: number;
  failedRowsQt: number;
  onCancel: any;
}

export const ProgressCards = ({
  importProcess,
  importStatus,
  successRowsQt,
  failedRowsQt,
  onCancel,
}: ProgressCardsProps) => {
  const navigate = useNavigate();
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const operation: ImportType =
    importProcess?.action === 'CREATE_ONLY'
      ? ImportType.insert
      : ImportType.update;

  const page = useMemo(() => {
    switch (importStatus) {
      case ImportStatus.PROCESSING:
        return (
          <MainContainer setColor={importStatus}>
            <Container>
              <IconContainer setColor={importStatus}>
                <StyledIcon
                  setColor={importStatus}
                  name="IconCircleCheck"
                  fill="none"
                  size={30}
                  stroke="1.7px"
                />
              </IconContainer>
              <TextContainer>
                <StyledTitle variant="body2">
                  {`${worksheetComponentInfo[operation].progressCards.processing.title}`}
                </StyledTitle>
                <StyledText
                  variant="body4"
                  children={`${worksheetComponentInfo[operation].progressCards.processing.description}`}
                />
                <ButtonsArea>
                  <StyledLinkButton
                    variant="secondary"
                    onClick={() => {
                      navigate(
                        getWorksheetUrl(
                          operation,
                          EmployeesWorksheetSteps.importStatus,
                        ),
                        {
                          state: {
                            refresh: true,
                            status: importStatus,
                          },
                        },
                      );
                    }}
                  >
                    Acompanhar progresso
                  </StyledLinkButton>
                </ButtonsArea>
              </TextContainer>
            </Container>
            <div></div>
          </MainContainer>
        );
      case ImportStatus.SUCCESS:
        return (
          <MainContainer setColor={importStatus}>
            <Container>
              <IconContainer setColor={importStatus}>
                <StyledIcon
                  setColor={importStatus}
                  name="IconCircleCheck"
                  fill="none"
                  size={30}
                  stroke="1.7px"
                />
              </IconContainer>
              <TextContainer>
                <StyledTitle variant="body2">
                  {`${worksheetComponentInfo[operation].progressCards.success.title}`}
                </StyledTitle>
                <StyledText
                  variant="body4"
                  children={`${worksheetComponentInfo[operation].progressCards.success.description}`}
                />
              </TextContainer>
            </Container>
            <StyledIcon
              setColor={importStatus}
              name="IconX"
              size={18}
              onClick={() => onCancel()}
            />
          </MainContainer>
        );
      case ImportStatus.SUCCESS_WITH_ERRORS:
        return (
          <MainContainer setColor={importStatus}>
            <Container>
              <IconContainer setColor={importStatus}>
                <StyledIcon
                  setColor={importStatus}
                  name="IconCircleX"
                  fill="none"
                  size={30}
                  stroke="1.7px"
                />
              </IconContainer>
              <TextContainer>
                <StyledTitle setColor="error10" variant="body3">
                  {`${worksheetComponentInfo[operation].progressCards.successWithErrors.title}`}
                </StyledTitle>

                <StyledText
                  setColor="error10"
                  variant="body4"
                  children={
                    successRowsQt === 1
                      ? `${successRowsQt} ${worksheetComponentInfo[operation].progressCards.successWithErrors.success.singular}`
                      : `${successRowsQt} ${worksheetComponentInfo[operation].progressCards.successWithErrors.success.plural}`
                  }
                />
                <StyledText
                  setColor="error10"
                  variant="body4"
                  children={
                    failedRowsQt === 1
                      ? `${failedRowsQt} ${worksheetComponentInfo[operation].progressCards.successWithErrors.error.singular}`
                      : `${failedRowsQt} ${worksheetComponentInfo[operation].progressCards.successWithErrors.error.plural}`
                  }
                />
                <ButtonsArea>
                  <StyledLinkButton
                    variant="secondary"
                    onClick={() => {
                      navigate(
                        getWorksheetUrl(
                          operation,
                          EmployeesWorksheetSteps.importStatus,
                        ),
                        {
                          state: {
                            status: importStatus,
                          },
                        },
                      );
                    }}
                  >
                    Confira os erros
                  </StyledLinkButton>
                </ButtonsArea>
              </TextContainer>
            </Container>
            <StyledIcon
              setColor={importStatus}
              name="IconX"
              size={18}
              onClick={() => {
                setOpenWarningModal(true);
              }}
            />
          </MainContainer>
        );
      case ImportStatus.ERRORED:
        return (
          <MainContainer setColor={importStatus}>
            <Container>
              <IconContainer setColor={importStatus}>
                <StyledIcon
                  setColor={importStatus}
                  name="IconCircleX"
                  fill="none"
                  size={30}
                  stroke="1.7px"
                />
              </IconContainer>
              <TextContainer>
                <StyledTitle setColor="error10" variant="body3">
                  Processamento finalizado.
                </StyledTitle>

                <StyledText
                  setColor="error10"
                  variant="body4"
                  children={
                    'Encontramos um erro no formato da planilha importada. Verifique o relatório de erro para identificar o problema'
                  }
                />
                <ButtonsArea>
                  <StyledLinkButton
                    variant="secondary"
                    onClick={() => {
                      navigate(
                        getWorksheetUrl(
                          operation,
                          EmployeesWorksheetSteps.importStatus,
                        ),
                        {
                          state: {
                            status: importStatus,
                          },
                        },
                      );
                    }}
                  >
                    Confira os erros
                  </StyledLinkButton>
                </ButtonsArea>
              </TextContainer>
            </Container>
            <StyledIcon
              setColor={importStatus}
              name="IconX"
              size={18}
              onClick={() => {
                setOpenWarningModal(true);
              }}
            />
          </MainContainer>
        );
      default:
        return <></>;
    }
  }, [importProcess, importStatus, successRowsQt, failedRowsQt]);

  return (
    <>
      {page}
      <WarningModal
        setOpen={openWarningModal}
        onClose={() => setOpenWarningModal(false)}
        onCancel={onCancel}
      />
    </>
  );
};
