import { TextField, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledTextField = styled(TextField)<{
  compoundField: boolean;
}>`
  ${({ compoundField }) =>
    compoundField
      ? `
          display: flex;
          flex-direction: column;
          width: 100%;`
      : ``}
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;
export const CheckboxLabel = styled(Typography)``;
