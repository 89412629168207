import { IconButton } from '@flash-tecnologia/hros-web-ui-v2';
import { Link } from 'react-router-dom';
import { PermissionProfilesType } from 'src/pages/PageTable/types';

import { MenuAddColab } from './components/MenuAddColab';
import { HeaderButtonsWrapper } from './styled';
import { MenuOptions } from './components/MenuOptions';

export const HeaderButtons = ({ menuDisabled }: PermissionProfilesType) => {
  return (
    <HeaderButtonsWrapper>
      <Link to="/orgchart">
        <IconButton variant="line" size="large" icon="IconHierarchy" />
      </Link>
      {/* 
          Anchor to GED - GED page in design
           <IconButton variant="line" size="large" icon="IconFolder" />
        */}
      <MenuAddColab disabled={menuDisabled} />
      <MenuOptions />
    </HeaderButtonsWrapper>
  );
};
