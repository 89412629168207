import { useMutation } from '@apollo/client';
import { RESEND_INVITE } from 'src/api';
import dispatchToast from 'src/utils/dispatchToast';
import { useResendInviteTimer } from './useResendInviteTimer';
import { ErrorBoundary } from '@Utils/ErrorBoundary';

export const useResendInvite = (employeeId: string) => {
  const { timer, resetTimer } = useResendInviteTimer();

  const [resendInviteMutation, loading] = useMutation<
    void,
    { employeeId: string }
  >(RESEND_INVITE, {
    onCompleted: () => {
      dispatchToast({
        type: 'success',
        content: 'Convite reenviado com sucesso',
      });
      resetTimer();
    },
    onError: (error) => {
      ErrorBoundary.captureException(error);
      dispatchToast({
        type: 'error',
        content: 'Erro ao reenviar convite',
      });
    },
  });

  const resendInvite = () => {
    if (timer > 0) {
      dispatchToast({
        type: 'warning',
        content: `Aguarde 1 minuto para reenviar`,
        description: 'Evite spam no e-mail da pessoa.',
      });
    } else {
      resendInviteMutation({ variables: { employeeId } });
    }
  };

  return {
    resetTimer,
    resendInvite,
    loading,
    timer,
  };
};
