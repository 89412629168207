import { useMutation } from '@apollo/client';
import { CANCEL_INVITE, GET_PERSON_MANAGE_EMPLOYEE } from 'src/api';
import dispatchToast from '@Utils/dispatchToast';
import { ErrorBoundary } from '@Utils/ErrorBoundary';

export const useCancelInvite = (onClose: () => void) => {
  const [cancelInvite, { loading }] = useMutation<void, { employeeId: string }>(
    CANCEL_INVITE,
    {
      onCompleted: () => {
        dispatchToast({
          type: 'success',
          content: 'Agendamento cancelado com sucesso!',
        });
        onClose();
      },
      onError: (error) => {
        ErrorBoundary.captureException(error);
        dispatchToast({
          type: 'error',
          content: 'Erro ao cancelar agendamento',
          description:
            'Nós não conseguimos cancelar este agendamento, tente novamente mais tarde.',
        });
      },
      refetchQueries: [GET_PERSON_MANAGE_EMPLOYEE],
      awaitRefetchQueries: true,
    },
  );
  return { cancelInvite, loading };
};
