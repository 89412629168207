import { LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import { Link } from 'react-router-dom';
import { StyledBreadcrumbs, StyledIcon } from './styled';

export const Breadcrumbs = () => {
  const breadcrumbs = [
    <Link to="/employees">
      <LinkButton variant="secondary" key={'Pessoas'}>
        Pessoas
      </LinkButton>
    </Link>,
    <LinkButton variant="secondary" key={'Minha conta'}>
      Minha conta
    </LinkButton>,
  ];

  return (
    <StyledBreadcrumbs
      separator={<StyledIcon name="IconChevronRight" fill="none" />}
      breadcrumbs={breadcrumbs}
    />
  );
};
