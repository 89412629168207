import { GetPageTableEmployeesResponse } from '@/api';
import { GET_EMPLOYEES } from '@/api/queries/get-employees';
import { escapeSpecialCharacters } from '@Components/ModalAddEmployee/components/Form/utils';
import { useApolloClient } from '@apollo/react-hooks';

type UseSearchEmployeeInput = {
  companyId: string;
  query: string;
};

export const useSearchEmployee = (limit = 1) => {
  const client = useApolloClient();

  const searchEmployee = async ({
    query,
    companyId,
  }: UseSearchEmployeeInput): Promise<GetPageTableEmployeesResponse> => {
    const { data } = await client.query({
      query: GET_EMPLOYEES,
      variables: {
        input: {
          companyId: companyId,
          page: 1,
          limit,
          query: escapeSpecialCharacters(query),
        },
      },
    });

    return data;
  };

  const checkEmployeeExistence = async ({
    query,
    companyId,
  }: UseSearchEmployeeInput) => {
    const { employees } = await searchEmployee({ query, companyId });

    return employees?.records.length > 0;
  };

  return { searchEmployee, checkEmployeeExistence };
};
