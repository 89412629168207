import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { TableHeaderContainer, TagStyled } from './styled';

export const TableHeader = ({
  totalCount,
  loading,
}: {
  totalCount: number;
  loading: boolean;
}) => {
  return (
    <TableHeaderContainer>
      <Typography variant="headline8">Pessoas cadastradas</Typography>
      {totalCount && (
        <TagStyled disabled={true} variant={'gray'} loading={loading}>
          {`${totalCount} itens`}
        </TagStyled>
      )}
    </TableHeaderContainer>
  );
};
