import { useState } from 'react';
import {
  ACTIVATE_EMPLOYEE,
  GET_PAGE_TABLE_EMPLOYEES,
  GET_PERSON_MANAGE_EMPLOYEE,
  useDeactivateEmployee,
  useDeleteEmployee,
} from '../../api';
import { useMutation } from '@apollo/client';
import dispatchToast from '../../utils/dispatchToast';
import { useNavigate } from 'react-router-dom';

export const useEmployeePageHeader = () => {
  const [deactivateModalIsOpen, setDeactivateModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const navigate = useNavigate();

  const { deactivateEmployee, loading: isDeactivating } = useDeactivateEmployee(
    {
      onCompleted: () => {
        dispatchToast({
          type: 'success',
          content: 'Pessoa desativada com sucesso!',
        });
        setDeactivateModalIsOpen(false);
      },
      onError: () =>
        dispatchToast({
          type: 'error',
          content:
            'Ocorreu um erro ao desativar essa pessoa. Por favor, tente novamente mais tarde.',
        }),
      refetchQueries: [GET_PERSON_MANAGE_EMPLOYEE],
    },
  );

  const { deleteEmployee, loading: isDeleting } = useDeleteEmployee({
    onCompleted: () => {
      dispatchToast({
        type: 'success',
        content: 'Pessoa deletada com sucesso!',
      });
      navigate(-1);
      setDeactivateModalIsOpen(false);
    },
    onError: (e) =>
      dispatchToast({
        type: 'error',
        content:
          e.message ||
          'Ocorreu um erro ao deletar essa pessoa. Por favor, tente novamente mais tarde.',
      }),
    refetchQueries: [GET_PERSON_MANAGE_EMPLOYEE, GET_PAGE_TABLE_EMPLOYEES],
  });

  const [activateEmployee] = useMutation(ACTIVATE_EMPLOYEE, {
    onCompleted: () => {
      dispatchToast({
        type: 'success',
        content: 'Pessoa ativada com sucesso!',
      });
      setDeactivateModalIsOpen(false);
    },
    onError: () =>
      dispatchToast({
        type: 'error',
        content:
          'Ocorreu um erro ao ativada essa pessoa. Por favor, tente novamente mais tarde.',
      }),
    refetchQueries: [GET_PERSON_MANAGE_EMPLOYEE],
    awaitRefetchQueries: true,
  });

  return {
    deactivateModalIsOpen,
    deleteModalIsOpen,
    isDeactivating,
    isDeleting,
    setDeactivateModalIsOpen,
    setDeleteModalIsOpen,
    deactivateEmployee,
    deleteEmployee,
    activateEmployee,
  };
};
