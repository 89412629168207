import { useQuery } from '@apollo/client';
import dispatchToast from '../../../utils/dispatchToast';
import { GET_EMPLOYEES_REPORT_NUMBERS } from 'src/api';

export const useGetEmployeesReportNumbers = (companyId: string) => {
  const { data, loading } = useQuery(GET_EMPLOYEES_REPORT_NUMBERS, {
    variables: { companyId },
    onError: () => {
      dispatchToast({
        type: 'error',
        content: 'Ocorreu um erro ao buscar dados de colaboradores.',
      });
    },
    fetchPolicy: 'cache-first',
  });
  return {
    loading,
    reportNumbers: data?.getEmployeesReportNumbers,
  };
};
