import styled from 'styled-components';
import { Button, Modal, Typography } from '@flash-tecnologia/hros-web-ui-v2';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 24px 24px 24px 112px;
  gap: 148px;

  border-width: 1px;
  border-color: rgb(235, 230, 233);
  border-image: initial;
  border-style: none none solid;
`;

export const HeaderTextWrapper = styled.div`
  margin-top: 20px;
`;

export const HeaderTitle = styled(Typography)``;

export const HeaderDescription = styled(Typography)``;

export const StyledModal = styled(Modal)`
  && {
    .modal-content-area {
      padding: 24px;
    }
  }
`;

export const FooterContainer = styled.div`
  border: 1px solid var(--color-neutral-light2);
  border-style: solid hidden hidden hidden;

  padding: 16px 112px;

  gap: 113px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const FooterCancelButton = styled.button`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;

  display: flex;
  align-items: flex-end;

  text-decoration: underline;

  text-underline-offset: 10px;

  color: var(--color-neutral-50);

  margin: 16px 0;

  gap: 4px;
`;

export const SubmitButton = styled(Button)``;

export const ContentContainer = styled.div`
  padding: 16px 88px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContentTextWrapper = styled.div``;

export const ContentTitle = styled(Typography)``;

export const ContentDescription = styled(Typography)``;
