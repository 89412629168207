import { ButtonWrapper, Subtitle } from './styled';
import { ModalAddEmployeeSuccessWithInvitationLinkInterface } from './types';
import { ModalSuccess } from '@Components/ModalSuccess';
import { CopyLink } from './components/CopyLink';
import { env } from '@/lib/env';

export const ModalAddEmployeeSuccessWithInvitationLink = ({
  isOpen,
  invitationCode,
  title,
  description,
  showInvitationCode,
  onClose,
  onSubmit,
}: ModalAddEmployeeSuccessWithInvitationLinkInterface) => {
  return (
    <ModalSuccess
      isOpen={isOpen}
      title={title}
      onClose={() => onClose()}
      onSubmit={() => onSubmit()}
      content={
        <>
          <Subtitle>{description}</Subtitle>
          {showInvitationCode && (
            <ButtonWrapper>
              <CopyLink
                link={`${env.HROS_URL}/authentication/first-access?invitationToken=${invitationCode}`}
              />
            </ButtonWrapper>
          )}
        </>
      }
      submitText="Concluir"
    />
  );
};
