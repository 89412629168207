import styled from 'styled-components';

export const Card = styled.div`
  width: 100%;
  max-width: 981px;

  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  padding: 40px;
  border-radius: 10px;

  @media screen and (max-width: 420px) {
    flex-direction: column;
    padding: 40px 15px;
  }
`;

export const ConfigContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 16px;
  gap: 40px;

  @media screen and (max-width: 511px) {
    flex-direction: column;
    gap: 0px;
  }
`;

export const RadioContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  padding: 16px 24px 16px 24px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const RadioText = styled.div`
  flex-direction: column;
  gap: 2px;
`;
