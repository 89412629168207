import { GET_PAGE_TABLE_EMPLOYEES, RESEND_MASS_INVITATIONS } from '@/api';
import { useSession } from '@/common/user';
import { useMutation } from '@apollo/client';
import { getFromLS, setInLS } from '@flash-tecnologia/hros-web-utility';
import { ErrorBoundary } from '@utils/ErrorBoundary';
import { setEventTracking } from '@utils/analytics';
import dispatchToast from '@utils/dispatchToast';
import dayjs from 'dayjs';

export const useResendMassInvitations = () => {
  const { userId: employeeId, companyId } = useSession();

  const [resendInviteMutation, { loading }] = useMutation<
    {
      resendMassInvitationsCompany: {
        estimatedShippingTime: string;
      };
    },
    { companyId: string }
  >(RESEND_MASS_INVITATIONS, {
    onCompleted: async (data) => {
      const { estimatedShippingTime } = data.resendMassInvitationsCompany;

      setInLS({
        key: 'RESEND_INVITATIONS_AVAILABLE_IN',
        value: estimatedShippingTime,
      });

      dispatchToast({
        type: 'success',
        content: 'Reenvio em massa realizado com sucesso!',
      });
    },
    onError: (error) => {
      ErrorBoundary.captureException(error);
      dispatchToast({
        type: 'error',
        content:
          'Ocorreu um erro ao realizar reenvio em massa, por favor tente novamente mais tarde.',
      });
    },
    refetchQueries: [GET_PAGE_TABLE_EMPLOYEES],
  });

  const resendMassInvitations = async () => {
    setEventTracking('backbone_resend_mass_invitations_confirm_clicked', {
      employeeId,
    });

    const localAvailableIn = await getFromLS('RESEND_INVITATIONS_AVAILABLE_IN');

    if (localAvailableIn) {
      const now = dayjs();
      const resendAvailableIn = dayjs(localAvailableIn);

      if (now.isBefore(resendAvailableIn)) {
        dispatchToast({
          type: 'warning',
          content: `Re-envio em massa disponível em ${resendAvailableIn.format(
            'HH:mm',
          )}hs`,
          description: 'Evite spam.',
        });
        return;
      }
    }

    await resendInviteMutation({ variables: { companyId } });
  };

  return { resendMassInvitations, loading };
};
