import styled from 'styled-components';

export const FooterContainer = styled.div`
  border: 1px solid var(--color-neutral-light2);
  border-style: solid hidden hidden hidden;

  padding: 16px 112px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const FooterCancelButton = styled.button`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;

  display: flex;
  align-items: flex-end;

  text-decoration: underline;

  text-underline-offset: 10px;

  color: var(--color-primary);

  margin: 16px 0;
`;
