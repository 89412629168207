import gql from 'graphql-tag';

export const GET_PERSON_MANAGE_EMPLOYEE = gql`
  query GetPersonManageEmployee($employeeId: ID!) {
    employee(employeeId: $employeeId) {
      id
      name
      documentNumber
      phoneNumber
      email
      corporateEmail
      externalId
      status
      companyId
      groups {
        id
        name
      }
      invitationDate
      lastInvitation {
        email
        status
        phoneNumber
        id
      }
      reportsTo {
        name
        _id
      }
      profilePicture {
        source
      }
      hiringDate
      socialName
      gender
      birthDate
      isForeign
      color
      mothersName
      maritalStatus {
        value
        component
        options {
          label
          value
        }
      }
      customFields {
        id
        title
        editable
        fields {
          key
          name
          type
          sectionId
          component
          value
          options {
            label
            value
          }
        }
      }
      address {
        zipCode
        city
        number
        street
        district
        complement
        state
      }
      banking {
        key
        name
        component
        value
        type
        sectionId
        options {
          label
          value
        }
      }
      dependents {
        id
        title
        editable
        fields {
          key
          name
          value
          component
          required
        }
      }
    }
  }
`;
