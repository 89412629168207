import {
  FooterCancelButton,
  FooterConfirmButton,
  FooterContainer,
} from './styled';
import { FooterInterface } from './types';

export const Footer = ({ onClose, loading, onSubmit }: FooterInterface) => {
  return (
    <FooterContainer>
      <FooterCancelButton onClick={onClose}>Voltar e editar</FooterCancelButton>
      <FooterConfirmButton
        disabled={loading}
        onClick={() => onSubmit()}
        size={'small'}
        disableRipple
        disableTouchRipple
        type="submit"
        loading={loading}
      >
        Enviar convite mesmo assim
      </FooterConfirmButton>
    </FooterContainer>
  );
};
