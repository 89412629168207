import styled from 'styled-components';
import {
  Button,
  Icons,
  LinkButton,
  Modal,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  padding-right: 20px;
  margin-bottom: -12px;
`;
const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 112px;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[90]};

  @media screen and (max-width: 550px) {
    padding: 40px;
  }

  @media screen and (max-width: 400px) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 20px 40px;
  }
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 15px;
`;

const StyledModal = styled(Modal)`
  && {
    &.modal-custom-theme .modal-container {
      width: 100%;
      max-width: 646px;
    }
    &.modal-custom-theme .modal-content-area {
      padding: 0px 112px 40px 112px;

      @media screen and (max-width: 550px) {
        padding: 0px 40px 40px 40px;
      }
    }
  }
`;

const StyledText = styled(Typography)<{ setColor: string }>`
  && {
    text-align: center;
    color: ${({ setColor, theme }) => {
      switch (setColor) {
        case 'neutral50':
          return theme.colors.neutral[50];
        case 'neutral30':
          return theme.colors.neutral[30];
      }
    }};
  }
`;
const StyledTitle = styled(Typography)<{ setColor: string }>`
  && {
    text-align: center;
    color: ${({ setColor, theme }) => {
      switch (setColor) {
        case 'neutral10':
          return theme.colors.neutral[10];
        case 'error40':
          return theme.colors.feedback.error[40];
      }
    }};
    font-weight: 700;
  }
`;
const StyledButton = styled(Button)`
  && {
    width: 226px;

    &.button-custom-theme-primary.MuiPaper-root.MuiButtonBase-root {
      background-color: ${({ theme }) => theme.colors.feedback.error[90]};
      color: ${({ theme }) => theme.colors.feedback.error[40]};

      &.MuiPaper-root.MuiButtonBase-root {
        :hover {
          box-shadow: 0px 0px 0px white;
          background-color: ${({ theme }) => theme.colors.feedback.error[90]};
        }
      }
    }
  }
`;
const StyledLinkButton = styled(LinkButton)``;
const StyledIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.feedback.error[40]};
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;

  width: 80px;
  min-width: 80px;
  height: 80px;

  background: ${({ theme }) => theme.colors.feedback.error[90]};
  border-radius: 200px;
`;

export {
  StyledModal,
  HeaderContainer,
  FooterContainer,
  ContentContainer,
  StyledText,
  StyledTitle,
  StyledButton,
  StyledLinkButton,
  StyledIcon,
  IconContainer,
};
