interface dataReaderProps {
  droppedFiles: any;
  separator: string;
}

export const TXTReader = ({ droppedFiles, separator }: dataReaderProps) => {
  if (droppedFiles.length === 0) return;

  return new Promise<any[]>((resolve) => {
    const reader = new FileReader() as any;
    reader.readAsText(droppedFiles[0].file);
    const arr: any = {};
    reader.onload = (): any => {
      const text = reader.result;
      const splitedText = text.split(/\r?\n/);
      const result = splitedText.map((e: any, index: number) => {
        if (e === '') return (arr[index] = 'emptyCell');
        arr[index] = e.split(separator || ';');
        return arr[index];
      });

      resolve(result);
    };
  });
};
