import { SimpleFieldDataType } from '@Components/Sections/types';
import { StatusTag } from '@Components/StatusTag';
import {
  Icons,
  LinkButton,
  Skeleton,
  dayjs,
  PDFViewer,
} from '@flash-tecnologia/hros-web-ui-v2';
import { FieldOption } from 'src/common/types';
import {
  FieldContainer,
  FieldName,
  FieldValue,
  FieldValueWrapper,
  LockIcon,
  StyledTag,
  ObjectValueWrapper,
} from './styled';
import { StatusEnum } from 'src/common/types/StatusEnum';

export const FieldSimple = ({
  name,
  value,
  locked,
  required,
  statusTag,
  tag,
  tagVariant,
  link,
  component,
  options,
  selectOptions,
  loading,
}: SimpleFieldDataType) => {
  const renderValue = () => {
    if (loading) {
      return <Skeleton variant="text" width={'100px'} />;
    } else if (tag) {
      return (
        <StyledTag variant={tagVariant as any}>{value as string}</StyledTag>
      );
    } else if (statusTag) {
      return <StatusTag status={value as StatusEnum} />;
    } else if (!!value && typeof value === 'object' && Array.isArray(value)) {
      return (
        <ObjectValueWrapper>
          {(value as FieldOption[]).map((value) => {
            return (
              <StyledTag variant={tagVariant as any} key={value?.label}>
                {value?.label}
              </StyledTag>
            );
          })}
        </ObjectValueWrapper>
      );
    } else {
      switch (component) {
        case 'link':
          return (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={link?.toString()}
            >
              <LinkButton variant={'primary'}>
                {value as string}
                <Icons name="IconExternalLink" fill="transparent" />
              </LinkButton>
            </a>
          );

        case 'select':
          const option = options?.find((opt) => {
            return (opt || { value: '' }).value == value;
          });
          return <>{option?.label}</>;

        case 'selectOptions':
        case 'selectOptionsSearchable':
          const selectOption = selectOptions?.find((opt) => {
            return opt.value._id == value;
          });
          return <>{selectOption?.label}</>;

        case 'checkbox':
          return <>{value ? 'Sim' : 'Não'}</>;

        case 'datePicker':
          return (
            <>
              {value && dayjs(value as any).isValid()
                ? dayjs(value as any).format('DD/MM/YYYY')
                : value}
            </>
          );

        case 'pdf':
          return (
            <PDFViewer
              height={'400px'}
              src={value as string}
              options={{
                showToolbar: true,
                fit: 'height',
                page: 1,
              }}
            />
          );

        default:
          return <>{value}</>;
      }
    }
  };

  return (
    <FieldContainer>
      {name && (
        <FieldName variant="caption">
          {name}
          {!required && ' (opcional)'}
        </FieldName>
      )}
      <FieldValueWrapper>
        <FieldValue variant="body3">
          {!!value || value === 0 || value?.toString() === 'false'
            ? renderValue()
            : '-'}
        </FieldValue>
        {locked && <LockIcon name="IconLock" fill="transparent" size={16} />}
      </FieldValueWrapper>
    </FieldContainer>
  );
};
