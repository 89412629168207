import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 24px 24px 40px 112px;

  border: 1px solid var(--color-neutral-light2);
  border-style: hidden hidden solid hidden;
`;

export const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 535px;
  margin: 24px auto 0 0;
`;

export const Title = styled(Typography)`
  color: var(--color-neutral-dark1);
`;

export const Subtitle = styled(Typography)`
  color: var(--color-neutral-50);
`;
