import {
  Checkbox,
  DatePicker,
  Icons,
  MultiSelect,
  SelectField,
  Tooltip,
  dayjs,
} from '@flash-tecnologia/hros-web-ui-v2';
import { FieldOption } from 'src/common/types';
import { StyledTextField, CheckboxWrapper, CheckboxLabel } from './styled';
import { FieldComponentInterface } from './types';

export const FieldComponent = ({
  compoundField,
  name,
  value,
  component,
  disabled,
  link,
  options,
  tooltip,
  onChange,
  error = false,
  helperText = '',
  onBlur,
  mask,
  selectOptions,
}: FieldComponentInterface) => {
  let maskProps: any = {};

  if (mask) {
    switch (mask) {
      case 'phone':
        maskProps = {
          imaskProps: {
            mask: '+00 (00) 00000-0000',
            definitions: {
              '#': /[1-9]/,
            },
          },
        };
        break;
      default:
        break;
    }
  }

  const commonProps = {
    compoundField: compoundField,
    label: name,
    value: value,
    disabled: disabled,
    error,
    helperText,
  };

  switch (component) {
    case 'shortText':
      return (
        <StyledTextField
          {...commonProps}
          {...maskProps}
          onChange={(e) =>
            onChange(mask ? e.target.value.replace(/\D/g, '') : e.target.value)
          }
        />
      );

    case 'longText':
      return (
        <StyledTextField
          {...commonProps}
          {...maskProps}
          onChange={(e) =>
            onChange(mask ? e.target.value.replace(/\D/g, '') : e.target.value)
          }
        />
      );

    case 'number':
      return (
        <StyledTextField
          type={'number'}
          {...commonProps}
          onChange={(e) => onChange(e.target.value)}
        />
      );

    case 'datePicker':
      return (
        <DatePicker
          onDateChange={(value) => {
            if (!dayjs(value).isValid()) return;
            const date = value ? dayjs(value).toDate().toISOString() : '';

            onChange(date);
          }}
          disabled={disabled}
          label={name}
          value={value as string}
          error={error}
          helperText={helperText}
        />
      );

    case 'checkbox':
      return (
        <CheckboxWrapper>
          <Checkbox
            checked={value as boolean}
            onChange={() => onChange(!value)}
          />
          <CheckboxLabel variant="body3">{name}</CheckboxLabel>
          {tooltip && (
            <Tooltip title={tooltip} arrow>
              <div>
                <Icons name="IconInfoCircle" fill="transparent" size={16} />
              </div>
            </Tooltip>
          )}
        </CheckboxWrapper>
      );

    case 'link':
      return (
        <StyledTextField
          {...commonProps}
          value={link}
          onChange={(e) => onChange(e.target.value)}
        />
      );

    case 'select':
      return (
        <SelectField
          options={options || undefined}
          {...commonProps}
          onSelectChange={(_, value: any) => onChange(value.value)}
        />
      );
    case 'selectOptions':
      const selectValue = (value as any)?._id || value;

      const selectOption =
        selectOptions?.find((opt) => {
          return opt.value._id == selectValue;
        }) || null;

      return (
        <SelectField
          options={selectOptions || undefined}
          {...commonProps}
          value={selectOption}
          onSelectChange={(_, value: any) => onChange(value.value)}
        />
      );
    case 'selectOptionsSearchable':
      const selectSearchableValue = (value as any)?._id || value;

      const selectSearchableOption =
        selectOptions?.find((opt) => {
          return opt?.value?._id == selectSearchableValue;
        }) || null;

      return (
        <SelectField
          options={selectOptions || undefined}
          {...commonProps}
          value={selectSearchableOption}
          onSelectChange={(_, value: any) =>
            onChange(value?.value || { _id: '', name: '' })
          }
          searchable={true}
        />
      );
    case 'multiSelect':
      const arrayValue = Array.isArray(value) ? value : [];
      return (
        <MultiSelect
          isOptionEqualToValue={(option, value) => option.label === value.label}
          label={name}
          options={options || []}
          renderInput={null!}
          onSelectChange={(_, value) => {
            onChange(value);
          }}
          error={error}
          value={arrayValue as FieldOption[]}
          onBlur={() => onBlur()}
          helperText={helperText}
        />
      );

    default:
      return <></>;
  }
};
