import { LinkButton } from '@flash-tecnologia/hros-web-ui-v2';

import { StyledText, StyledTitle } from '../styled';

import {
  StyledModal,
  MainContainer,
  FooterContainer,
  StyledButton,
  IconContainer,
  StyledIcon,
} from './styled';

interface WarningModalProps {
  setOpen: any;
  onClose: any;
  onCancel: any;
}

export const WarningModal = ({
  setOpen,
  onClose,
  onCancel,
}: WarningModalProps) => {
  const Footer = (
    <FooterContainer>
      <LinkButton
        variant="secondary"
        style={{ alignSelf: 'center' }}
        onClick={onClose}
      >
        Cancelar
      </LinkButton>
      <StyledButton
        variant="primary"
        size="large"
        style={{ alignSelf: 'center' }}
        onClick={onCancel}
        variantType="info"
      >
        Continuar
      </StyledButton>
    </FooterContainer>
  );

  return (
    <StyledModal footer={Footer} open={setOpen} onClose={onClose}>
      <MainContainer>
        <IconContainer>
          <StyledIcon
            name="IconAlertTriangle"
            fill="none"
            size={52}
            stroke={'1.2px'}
          />
        </IconContainer>
        <StyledTitle
          setColor="error40"
          variant="body1"
          style={{ textAlign: 'center', marginBottom: '40px' }}
        >
          ATENÇÃO
        </StyledTitle>

        <StyledText
          setColor="neutral50"
          variant="body2"
          style={{ textAlign: 'center', marginBottom: '15px' }}
        >
          Caso o card de notificação seja fechado, você perderá acesso ao status
          do processamento e ao relatório de erro
        </StyledText>

        <StyledText
          setColor="neutral50"
          variant="body2"
          style={{ textAlign: 'center' }}
        >
          Deseja continuar?
        </StyledText>
      </MainContainer>
    </StyledModal>
  );
};
