import { Breadcrumbs, Icons } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledIcon = styled(Icons)`
  && {
    color: ${({ theme }) => theme.colors.neutral[50]};
  }
`;

export const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin: 40px 0;
`;
