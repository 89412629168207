import { Accordion } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const TabContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

export const StyledAccordion = styled(Accordion)`
  &.MuiPaper-root {
    &::before {
      background-color: transparent;
    }
  }
`;
