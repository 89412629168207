import { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { ErrorBoundary } from '@utils/ErrorBoundary';
import dispatchToast from '@utils/dispatchToast';
import { ImportStatus } from '../types';
import { GET_IMPORT } from '@/api/queries/get-import';
import { getFromLS } from '@flash-tecnologia/hros-web-utility';

interface IUseGetImport {
  pollInterval?: number;
}

interface Import {
  importId: string;
  action: string;
  fileId: string;
  fileName: string;
  status: ImportStatus;
  processedRows: number;
  totalRows: number;
  errors: {
    row: number;
    message: string;
  }[];
}

export const useGetImport = ({ pollInterval }: IUseGetImport) => {
  const TEN_SECONDS = 10 * 1000;
  const [importProcess, setImportProcess] = useState<Import | null>(null);
  const [interval] = useState(pollInterval ? pollInterval : TEN_SECONDS);
  const [successRowsQt, setSuccessRowsQt] = useState(0);
  const [failedRowsQt, setFailedRowsQt] = useState(0);
  const [importStatus, setImportStatus] = useState<ImportStatus>(
    ImportStatus.WAITING,
  );
  const keyStorage = getFromLS('importId') || {};
  const { loading, refetch } = useQuery(GET_IMPORT, {
    skip:
      Object.keys(keyStorage).length === 0 ||
      [
        ImportStatus.SUCCESS,
        ImportStatus.ERRORED,
        ImportStatus.ABORTED,
        ImportStatus.SUCCESS_WITH_ERRORS,
      ].includes(importStatus),
    variables: { importId: keyStorage.importId },
    pollInterval: interval,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data?.getImport) {
        setImportProcess(data.getImport);
        if (
          data?.getImport.status === ImportStatus.SUCCESS &&
          data.getImport.errors.length
        ) {
          setImportStatus(ImportStatus.SUCCESS_WITH_ERRORS);
        } else {
          setImportStatus(data?.getImport.status);
        }
        setFailedRowsQt(data.getImport.errors.length);
        setSuccessRowsQt(
          Math.max(
            data.getImport.processedRows - data.getImport.errors.length,
            0,
          ),
        );
      }
    },
    onError: (error) => {
      setImportStatus(ImportStatus.ERRORED);
      ErrorBoundary.captureException(error);
      dispatchToast({
        type: 'error',
        content: 'Ocorreu um erro ao buscar informações de processamento.',
      });
    },
  });
  return {
    importProcess,
    importStatus,
    successRowsQt,
    failedRowsQt,
    setImportStatus,
    isLoading: loading,
    getImportRefetch: refetch,
  };
};
