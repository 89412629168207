import { Icons } from '@flash-tecnologia/hros-web-ui-v2';
import { FooterConfirmButton, FooterContainer } from './styled';
import { FooterInterface } from './types';

export const Footer = ({ onSubmit, confirmText }: FooterInterface) => {
  return (
    <FooterContainer>
      <FooterConfirmButton
        onClick={() => onSubmit()}
        size={'small'}
        disableRipple
        disableTouchRipple
        type="submit"
      >
        {confirmText}
        <Icons name="IconCheck" fill="transparent" />
      </FooterConfirmButton>
    </FooterContainer>
  );
};
