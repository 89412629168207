import gql from 'graphql-tag';

export const GET_PERSON_MANAGE_ADITIONAL_DATA = gql`
  query GetPersonManageAditionalData(
    $input: GetEmployeesV2Input
    $companyId: ID!
  ) {
    employees(input: $input) {
      records {
        id
        name
      }
    }
    groups(companyId: $companyId) {
      id
      name
    }
  }
`;
