import { DefaultFieldType } from './types';
import { phoneMask } from './utils';

export const defaultFields: DefaultFieldType[] = [
  {
    key: 'name',
    label: 'Nome completo',
    type: 'text',
    value: '',
    required: true,
    disabled: false,
    error: false,
    helperText: 'Campo obrigatório',
    maxLength: 40,
  },
  {
    key: 'nationalDocumentNumber',
    label: 'CPF',
    type: 'text',
    value: '',
    required: true,
    disabled: false,
    error: false,
    helperText: 'Campo obrigatório',
    hidden: false,
    maxLength: 14,
  },
  {
    key: 'email',
    label: 'E-mail',
    type: 'text',
    value: '',
    required: true,
    disabled: false,
    error: false,
    helperText: 'Campo obrigatório',
    adornment: true,
    bottomTip:
      'Enviaremos o convite de primeiro acesso à plataforma e app da Flash para esse e-mail. Caso não possua o e-mail da pessoa, marque a opção correspondente e preencha o celular para que o convite seja enviado por SMS.',
    adornmentText: 'Não sei o e-mail',
    maxLength: 50,
  },
  {
    key: 'phone',
    label: 'Celular',
    type: 'text',
    value: '',
    required: false,
    disabled: false,
    error: false,
    helperText: 'Campo obrigatório',
    mask: (value: string) => phoneMask(value),
    bottomTip:
      'Enviaremos o convite de primeiro acesso à plataforma e app da Flash via SMS para esse número.',
    maxLength: 17,
    placeholder: '+55 99 99999 9999',
  },
];
