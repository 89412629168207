import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  GenerateXLSXFile,
  PageContainer,
} from '@flash-tecnologia/hros-web-ui-v2';

import { AbortImportProcess } from '../../modals';

import {
  MainContainer,
  LeftColumn,
  RightColumn,
  Card,
  IconContainer,
  StyledIcon,
  TextContainer,
  InfoContainer,
  ButtomContainer,
  StyledButton,
} from './styled';

import { StyledText, StyledTitle } from 'src/common/styles/general-styles';
import { EmployeesWorksheetSteps, ImportStatus, ImportType } from '../../types';
import { getWorksheetUrl } from '../../helpers/getWorksheetUrl';
import { worksheetComponentInfo } from '../../componentsText';
import { useGetImport } from '../../hooks/useGetImport';
import { useApolloClient } from '@apollo/react-hooks';
import { useAbortImport } from '../../hooks/useAbortImport';

interface IImportStatusProps {
  operation: ImportType;
}

export const ImportFeedback = ({ operation }: IImportStatusProps) => {
  const navigate = useNavigate();

  const state = useLocation() as any;
  const currentState = useMemo(() => state.state, [state]);
  const [openModal, setOpenModal] = useState(false);
  const [endFlux, setEndFlux] = useState(false);
  const client = useApolloClient();
  const {
    importProcess,
    importStatus,
    successRowsQt,
    failedRowsQt,
    getImportRefetch,
  } = useGetImport({});

  const { abortImportProcess } = useAbortImport();

  const handleAbortImport = async (importId: string) => {
    await abortImportProcess(importId);
    setEndFlux(true);
  };

  useEffect(() => {
    if (endFlux) navigate('/employees', { state: { openCard: false } });
  }, [endFlux]);

  useEffect(() => {
    if (currentState?.refresh) {
      getImportRefetch();
    }
  }, [currentState]);

  let mainCard = <></>;

  switch (importStatus) {
    case ImportStatus.PROCESSING:
      mainCard = (
        <Card>
          <TextContainer>
            <IconContainer setColor="secondary95">
              <StyledIcon
                name="IconRefresh"
                fill="none"
                setColor="primary"
                size={32}
                loading={true}
              />
            </IconContainer>
            <StyledTitle
              setColor="secondary50"
              variant="body1"
              children={`${worksheetComponentInfo[operation].processmentStatusPage.mainCard.status.title}`}
            />
            <StyledText
              setColor="neutral50"
              variant="body3"
              children={`${worksheetComponentInfo[operation].processmentStatusPage.mainCard.status.description}`}
            />
          </TextContainer>
          <InfoContainer>
            <StyledText
              setColor="neutral20"
              variant="body3"
              children={importProcess?.fileName}
              style={{ fontWeight: 'bolder' }}
            />
            <StyledText
              setColor="neutral50"
              variant="body3"
              children={
                importProcess &&
                importProcess?.totalRows > 0 &&
                importProcess.processedRows > 0
                  ? `${importProcess?.processedRows || '0'} de ${
                      importProcess?.totalRows
                    } ${
                      worksheetComponentInfo[operation].processmentStatusPage
                        .mainCard.processing.plural
                    }`
                  : null
              }
            />
          </InfoContainer>
          <ButtomContainer>
            <StyledButton
              style={{ alignSelf: 'center' }}
              variant="secondary"
              size="large"
              onClick={() => {
                setOpenModal(true);
              }}
            >
              <StyledIcon name="IconX" />
              Interromper importação
            </StyledButton>

            <StyledButton
              style={{ alignSelf: 'center' }}
              variant="primary"
              size="large"
              onClick={() => {
                navigate('/employees', {
                  state: { openCard: true },
                });
              }}
            >
              <StyledIcon name="IconArrowLeft" />
              Voltar para a lista de pessoas
            </StyledButton>
          </ButtomContainer>
        </Card>
      );
      break;
    case ImportStatus.SUCCESS_WITH_ERRORS:
      mainCard = (
        <Card>
          <TextContainer>
            <IconContainer setColor="error90">
              <StyledIcon
                name="IconAlertTriangle"
                fill="none"
                setColor="error50"
                size={32}
              />
            </IconContainer>
            <StyledTitle
              setColor="neutral30"
              variant="body1"
              children="Processamento finalizado"
            />
            <StyledText
              setColor="neutral50"
              variant="body3"
              children={
                successRowsQt === 1
                  ? `${successRowsQt} ${worksheetComponentInfo[operation].processmentStatusPage.mainCard.success.singular}`
                  : `${successRowsQt} ${worksheetComponentInfo[operation].processmentStatusPage.mainCard.success.plural}`
              }
            />
            <StyledText
              setColor="neutral50"
              variant="body3"
              children={
                failedRowsQt === 1
                  ? `${failedRowsQt} ${worksheetComponentInfo[operation].processmentStatusPage.mainCard.error.singular}`
                  : `${failedRowsQt} ${worksheetComponentInfo[operation].processmentStatusPage.mainCard.error.plural}`
              }
            />
          </TextContainer>
          <InfoContainer>
            <StyledText
              setColor="neutral20"
              variant="body3"
              children={`${worksheetComponentInfo[operation].processmentStatusPage.mainCard.infoContainer.error}`}
              style={{ fontWeight: 'bolder' }}
            />
            <StyledText
              setColor="neutral50"
              variant="body3"
              children={`${worksheetComponentInfo[operation].processmentStatusPage.mainCard.infoContainer.fileError}`}
            />
          </InfoContainer>
          <GenerateXLSXFile
            fileName="Lista-de-erros.txt"
            icon="IconDownload"
            docIconSize={38}
            style={{ marginBottom: '64px', width: '300px' }}
            data={importProcess!.errors.map(({ row, message }) => {
              return `- Linha: ${row}: ${message}`;
            })}
          />
          <ButtomContainer>
            <StyledButton
              style={{ alignSelf: 'center' }}
              variant="primary"
              size="large"
              onClick={async () => {
                await client.clearStore();
                navigate('/employees', {
                  state: { openCard: true },
                });
              }}
            >
              <StyledIcon name="IconArrowLeft" />
              Voltar para a lista de pessoas
            </StyledButton>
          </ButtomContainer>
        </Card>
      );
      break;
    case ImportStatus.ERRORED:
      mainCard = (
        <Card>
          <TextContainer>
            <IconContainer setColor="error90">
              <StyledIcon
                name="IconAlertTriangle"
                fill="none"
                setColor="error50"
                size={32}
              />
            </IconContainer>
            <StyledTitle
              setColor="neutral30"
              variant="body1"
              children="Processamento interrompido"
            />
            <StyledText
              setColor="neutral50"
              variant="body3"
              children={
                'O processamento foi interrompido devido a um erro estrutural na planilha'
              }
            />
          </TextContainer>
          <InfoContainer>
            <StyledText
              setColor="neutral20"
              variant="body3"
              children={'A planilha contem erro em seu formato.'}
              style={{ fontWeight: 'bolder' }}
            />
            <StyledText
              setColor="neutral50"
              variant="body3"
              children={`${worksheetComponentInfo[operation].processmentStatusPage.mainCard.infoContainer.fileError}`}
            />
          </InfoContainer>
          <GenerateXLSXFile
            fileName="Lista-de-erros.txt"
            icon="IconDownload"
            docIconSize={38}
            style={{ marginBottom: '64px', width: '300px' }}
            data={importProcess!.errors.map(({ row, message }) => {
              return `- Linha: ${row}: ${message}`;
            })}
          />
          <ButtomContainer>
            <StyledButton
              style={{ alignSelf: 'center' }}
              variant="primary"
              size="large"
              onClick={async () => {
                await handleAbortImport(importProcess?.importId!);
              }}
            >
              <StyledIcon name="IconArrowLeft" />
              Voltar para a lista de pessoas
            </StyledButton>
          </ButtomContainer>
        </Card>
      );
      break;
    case ImportStatus.SUCCESS:
      mainCard = (
        <Card>
          <TextContainer>
            <IconContainer setColor="success90">
              <StyledIcon
                name="IconCheck"
                fill="none"
                setColor="success40"
                size={32}
              />
            </IconContainer>
            <StyledTitle
              setColor="neutral30"
              variant="body1"
              children={`${worksheetComponentInfo[operation].processmentStatusPage.mainCard.infoContainer.successTitle}`}
            />
            <StyledText
              setColor="neutral50"
              variant="body3"
              children={`${successRowsQt} ${worksheetComponentInfo[operation].processmentStatusPage.mainCard.infoContainer.successDescription}`}
            />
          </TextContainer>
          <InfoContainer>
            <StyledText
              setColor="neutral50"
              variant="body3"
              children={importProcess?.fileName}
              style={{ fontWeight: 'bolder' }}
            />

            <StyledText
              setColor="neutral50"
              variant="body3"
              children={
                importProcess!.totalRows
                  ? `${successRowsQt || 0} de ${
                      importProcess?.totalRows || 0
                    } ${
                      worksheetComponentInfo[operation].processmentStatusPage
                        .mainCard.success.plural
                    }`
                  : ''
              }
            />
          </InfoContainer>

          <ButtomContainer>
            <StyledButton
              style={{ alignSelf: 'center' }}
              variant="primary"
              size="large"
              onClick={async () => {
                await client.clearStore();
                navigate('/employees', {
                  state: { openCard: true },
                });
              }}
            >
              <StyledIcon name="IconArrowLeft" />
              Voltar para a lista de pessoas
            </StyledButton>
          </ButtomContainer>
        </Card>
      );
      break;
    case ImportStatus.ABORTED:
      mainCard = (
        <Card>
          <TextContainer>
            <IconContainer setColor="error90">
              <StyledIcon
                name="IconAlertTriangle"
                fill="none"
                setColor="error50"
                size={32}
              />
            </IconContainer>
            <StyledTitle
              setColor="neutral30"
              variant="body1"
              children="Processamento interrompido"
            />
            <StyledText
              setColor="neutral50"
              variant="body3"
              children={
                successRowsQt === 1
                  ? `${successRowsQt} ${worksheetComponentInfo[operation].processmentStatusPage.mainCard.success.singular}`
                  : `${successRowsQt} ${worksheetComponentInfo[operation].processmentStatusPage.mainCard.success.plural}`
              }
            />
            <StyledText
              setColor="neutral50"
              variant="body3"
              children={
                failedRowsQt === 1
                  ? `${failedRowsQt} ${worksheetComponentInfo[operation].processmentStatusPage.mainCard.error.singular}`
                  : `${failedRowsQt} ${worksheetComponentInfo[operation].processmentStatusPage.mainCard.error.plural}`
              }
            />
          </TextContainer>
          <ButtomContainer>
            <StyledButton
              style={{ alignSelf: 'center' }}
              variant="secondary"
              size="large"
              onClick={() => {
                navigate(
                  getWorksheetUrl(
                    operation,
                    EmployeesWorksheetSteps.uploadFile,
                  ),
                );
              }}
            >
              <StyledIcon name="IconX" />
              Nova importação
            </StyledButton>

            <StyledButton
              style={{ alignSelf: 'center' }}
              variant="primary"
              size="large"
              onClick={() =>
                navigate('/employees', { state: { openCard: false } })
              }
            >
              <StyledIcon name="IconArrowLeft" />
              Voltar para a lista de pessoas
            </StyledButton>
          </ButtomContainer>
        </Card>
      );
      break;
    case ImportStatus.WAITING:
    default:
      mainCard = (
        <Card>
          <TextContainer>
            <IconContainer setColor="secondary95">
              <StyledIcon
                name="IconRefresh"
                fill="none"
                setColor="primary"
                size={32}
                loading={true}
              />
            </IconContainer>
            <StyledTitle
              setColor="secondary50"
              variant="body1"
              children={`${worksheetComponentInfo[operation].processmentStatusPage.mainCard.status.title}`}
            />
            <StyledText
              setColor="neutral50"
              variant="body3"
              children={`${worksheetComponentInfo[operation].processmentStatusPage.mainCard.status.description}`}
            />
          </TextContainer>
        </Card>
      );
  }

  return (
    <PageContainer>
      <MainContainer>
        <LeftColumn>
          <StyledTitle
            setColor="secondary50"
            variant="body1"
            children={`${worksheetComponentInfo[operation].processmentStatusPage.mainContainer.title}`}
          />
          <StyledText
            setColor="neutral50"
            variant="body3"
            children={`${worksheetComponentInfo[operation].processmentStatusPage.mainContainer.description}`}
          />
        </LeftColumn>
        <RightColumn>{mainCard}</RightColumn>
        <AbortImportProcess
          operation={operation}
          setOpen={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
          onInterrupt={() => {}}
          importId={importProcess?.importId!}
        />
      </MainContainer>
    </PageContainer>
  );
};
