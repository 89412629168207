import styled from 'styled-components';
import { Icons } from '@flash-tecnologia/hros-web-ui-v2';

export const Subtitle = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--color-neutral-50);
`;

export const AlterIcon = styled(Icons)`
  && {
    color: var(--color-primary);
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
