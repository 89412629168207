import styled from 'styled-components';

export const InstructionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  @media screen and (max-width: 420px) {
    flex-direction: row;
    padding: 40px 15px;
  }
`;

export const FieldWarning = styled.div`
  display: flex;
  gap: 24px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.feedback.info[50]};
  padding: 16px;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.feedback.info[90]};
`;
