import { useState } from 'react';

export const useEmployeeAlreadyExistsModal = () => {
  const [fallback, setFallBack] = useState<() => void>();
  const [existingUserModalConfig, setExistingUserModalConfig] = useState({
    isOpen: false,
    title: '',
    description: '',
  });

  const createEmployeeModalForExistingUser = (
    conditions: Record<string, boolean>,
    fallback: () => void,
  ) => {
    setFallBack(() => fallback);

    const buildTitle = (userExistingFields: string) => {
      return `Já existe uma pessoa cadastrada com esse ${userExistingFields}`;
    };

    const buildDescription = (userExistingFields: string) => {
      return `O convite de primeiro acesso será enviado para o ${userExistingFields} informado. Certifique-se de que a pessoa correta conseguirá acessá-lo para criar seu cadastro no app e plataforma da Flash.`;
    };

    if (!conditions.email && !conditions.phone) {
      fallback();
    }

    if (conditions.email && conditions.phone) {
      return setExistingUserModalConfig({
        isOpen: true,
        title: buildTitle('e-mail e celular'),
        description: buildDescription('e-mail'),
      });
    }

    if (conditions.email) {
      return setExistingUserModalConfig({
        isOpen: true,
        title: buildTitle('e-mail'),
        description: buildDescription('e-mail'),
      });
    }

    if (conditions.phone) {
      return setExistingUserModalConfig({
        isOpen: true,
        title: buildTitle('celular'),
        description: buildDescription('celular'),
      });
    }
  };

  const closeExistingUserModal = () => {
    setExistingUserModalConfig((prevModalState) => ({
      ...prevModalState,
      isOpen: false,
    }));
  };
  return {
    fallback,
    employeeAlreadyExistsModal: {
      config: existingUserModalConfig,
      open: createEmployeeModalForExistingUser,
      close: closeExistingUserModal,
    },
  };
};
