import { StyledText, StyledTitle } from 'src/common/styles/general-styles';
import { FieldWarning, InstructionsContainer } from './styled';
import { worksheetComponentInfo } from '../../../../componentsText';
import { ImportType } from '@/pages/PageEmployeesWorksheet/types';

interface IGeneralInstructionsProps {
  operation: ImportType;
}
export const GeneralInstructions = ({
  operation,
}: IGeneralInstructionsProps) => {
  return (
    <InstructionsContainer>
      <StyledTitle
        setColor="secondary50"
        variant="headline7"
        children={worksheetComponentInfo[operation].uploadFile.title}
      />
      <StyledText
        setColor="neutral50"
        variant="body3"
        children={worksheetComponentInfo[operation].uploadFile.description[0]}
      />
      <StyledText
        setColor="neutral50"
        variant="body3"
        children={worksheetComponentInfo[operation].uploadFile.description[1]}
      />
      <FieldWarning>
        <StyledTitle
          setColor="info10"
          variant="body4"
          children={
            worksheetComponentInfo[operation].uploadFile.warningMessage.title
          }
        />
        <StyledText
          setColor="info10"
          variant="caption"
          children={
            worksheetComponentInfo[operation].uploadFile.warningMessage
              .description[0]
          }
        />
        <StyledText
          setColor="info10"
          variant="caption"
          children={
            worksheetComponentInfo[operation].uploadFile.warningMessage
              .description[1]
          }
        />
      </FieldWarning>
    </InstructionsContainer>
  );
};
