import {
  segmentEventTracking,
  segmentPageTracking,
} from '@flash-tecnologia/hros-web-utility';

export const setPageTracking = (
  name: string,
  params?: Record<string, string>,
) => {
  segmentPageTracking({
    name,
    params,
    module: 'employee-management',
    businessUnit: 'platform',
  });
};

export const setEventTracking = (
  name: string,
  params?: Record<string, string>,
) => {
  segmentEventTracking({
    name,
    params,
    module: 'employee-management',
    businessUnit: 'platform',
  });
};
