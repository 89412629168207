import gql from 'graphql-tag';

export const GET_PAGE_TABLE_EMPLOYEES = gql`
  query GetPageTableEmployees($input: GetEmployeesV2Input) {
    employees(input: $input) {
      records {
        id
        name
        documentNumber
        phoneNumber
        email
        status
        companyId
        groups {
          id
          name
        }
      }
      recordsMetadata {
        totalCount
        totalPages
        currentPage
        nextPage
        prevPage
      }
    }
  }
`;
