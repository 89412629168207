import { Pagination, SelectField } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100%;
  height: 48px;
`;

export const TablePagination = ({
  totalPages,
  pageNumber,
  pageSize,
  onPageNumberChange,
  onPageSizeChange,
}: TablePaginationInput) => {
  const pageSizeOptions = [
    {
      label: '10 itens',
      value: 10,
    },
    {
      label: '25 itens',
      value: 25,
    },
    {
      label: '50 itens',
      value: 50,
    },
    {
      label: '100 itens',
      value: 100,
    },
    {
      label: '250 itens',
      value: 250,
    },
  ];

  return (
    <PaginationContainer>
      <SelectField
        className="data-grid-custom-select"
        disabled={!totalPages}
        value={pageSize}
        onSelectChange={(_, { value }) => onPageSizeChange(value)}
        options={pageSizeOptions}
      />
      <Pagination
        count={totalPages}
        defaultPage={1}
        page={pageNumber}
        onChange={(_, value) => onPageNumberChange(value)}
        siblingCount={1}
      />
    </PaginationContainer>
  );
};

type TablePaginationInput = {
  totalPages: number;
  pageNumber: number;
  pageSize: number;
  onPageNumberChange: (value: any) => void;
  onPageSizeChange: (value: any) => void;
};
