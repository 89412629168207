import { IconButton } from '@flash-tecnologia/hros-web-ui-v2';

import {
  StyledModal,
  MainContainer,
  HeaderContainer,
  Card,
  Imagem,
  TextContainer,
  FooterContainer,
  StyledButton,
} from './styled';

import { StyledText, StyledTitle } from 'src/common/styles/general-styles';

import SeparatorHelp from '../../../../assets/separatorHelp1.png';

interface SeparatorHelperModalProps {
  setOpen: boolean;
  onClose: () => void;
}

export const SeparatorHelperModal = ({
  setOpen,
  onClose,
}: SeparatorHelperModalProps) => {
  const Header = (
    <HeaderContainer>
      <TextContainer>
        <StyledTitle
          variant="headline6"
          setColor="neutral10"
          children={`Como identificar o tipo de separador?`}
        />

        <StyledText
          setColor="neutral50"
          variant="body3"
          children={`Veja no exemplo abaixo como você pode identificar o tipo de separador do seu arquivo:`}
        />
      </TextContainer>
      <IconButton
        style={{ top: '-20px' }}
        variant="line"
        size="medium"
        icon={'IconX'}
        onClick={onClose}
      />
    </HeaderContainer>
  );

  const Footer = (
    <FooterContainer>
      <StyledButton
        variant="primary"
        size="large"
        style={{ alignSelf: 'center' }}
        onClick={onClose}
      >
        Voltar
      </StyledButton>
    </FooterContainer>
  );

  return (
    <StyledModal
      header={Header}
      footer={Footer}
      open={setOpen}
      onClose={onClose}
    >
      <MainContainer>
        <Card>
          <StyledTitle
            variant="body2"
            setColor="neutral30"
            children={`Arquivo em formato de texto`}
          />

          <StyledText
            setColor="neutral50"
            variant="body3"
            children={`Para identificar o tipo de separador, seu arquivo deve estar em formato de texto. Caso seu arquivo esteja no formato de tabela, tente abri-la com algum software de texto (como o Word no windows ou o Editor de Texto no Mac). Você identificará o separador como na imagem abaixo:`}
          />
          <Imagem src={SeparatorHelp} />
        </Card>
      </MainContainer>
    </StyledModal>
  );
};
