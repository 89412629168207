import gql from 'graphql-tag';

export const GET_EMPLOYEE_LAST_INVITE = gql`
  query GetEmployeeLastInvite($employeeId: ID!) {
    employee(employeeId: $employeeId) {
      id
      lastInvitation {
        id
      }
    }
  }
`;
