import { useState, Fragment, useRef } from 'react';

import { SubsectionComponentType } from '../../types';
import { Fields } from '../Fields';

import { useSectionsManager } from '@Hooks';
import { submitSessions } from '@Utils/sectionsSubmission';

import {
  ButtonsWrapper,
  Description,
  FieldsContainer,
  StyledDivider,
  StyledTag,
  SubsectionContainer,
  SubsectionHeader,
  TextsWrapper,
  Title,
  TitleWrapper,
  IconButtonStyled,
} from './styled';

export const Subsections = ({
  subsections,
  employeeId,
  toggleEmptyForm,
}: SubsectionComponentType) => {
  const fieldsRef = useRef<any>(null);

  const { api, loading } = useSectionsManager();

  const [editing, setEditing] = useState({
    isEditing: false,
    subsectionId: '',
  });

  return (
    <>
      {subsections.map(
        (
          {
            updateAPI,
            title,
            titleTag,
            description,
            editable,
            id,
            fields,
            deleteAPI,
          },
          index,
        ) => {
          const isEditing = editing.isEditing && editing.subsectionId === id;

          return (
            <Fragment key={id}>
              <SubsectionContainer>
                <SubsectionHeader>
                  <TextsWrapper>
                    <TitleWrapper>
                      <Title variant="headline8">{title}</Title>
                      {titleTag && (
                        <StyledTag variant="primary">{titleTag}</StyledTag>
                      )}
                    </TitleWrapper>
                    <Description variant="body4">{description}</Description>
                  </TextsWrapper>
                  {editable && (
                    <ButtonsWrapper>
                      <IconButtonStyled
                        size="small"
                        variant="line"
                        editing={isEditing}
                        icon={isEditing ? 'IconCheck' : 'IconPencil'}
                        disabled={loading}
                        onClick={() => {
                          isEditing && fieldsRef?.current?.handleSubmit();

                          !isEditing &&
                            setEditing({
                              isEditing: true,
                              subsectionId: id,
                            });
                        }}
                      />
                      <IconButtonStyled
                        variant="line"
                        size="small"
                        icon="IconTrash"
                        onClick={async () => {
                          if (id === 'empty-dependent-id') {
                            toggleEmptyForm && toggleEmptyForm(index);
                          } else {
                            await submitSessions({
                              type: deleteAPI! as any,
                              api,
                              id,
                              employeeId: employeeId,
                              fields,
                              modifiedFields: {},
                            }).then((r) => {
                              // !r?.errors &&
                              //   toggleEmptyForm &&
                              //   toggleEmptyForm(index);
                            });
                          }
                        }}
                      />
                      {isEditing && (
                        <IconButtonStyled
                          variant="line"
                          size="small"
                          icon="IconX"
                          onClick={() => {
                            fieldsRef?.current?.resetForm();
                            setEditing({
                              isEditing: false,
                              subsectionId: id,
                            });
                          }}
                        />
                      )}
                    </ButtonsWrapper>
                  )}
                </SubsectionHeader>
                <FieldsContainer>
                  {fields && (
                    <Fields
                      ref={fieldsRef}
                      fields={fields}
                      isEditing={isEditing}
                      loading={loading}
                      onSubmit={async ({
                        fields,
                        initialValues,
                        modifiedFields,
                      }) => {
                        if (Object.keys(modifiedFields).length) {
                          await submitSessions({
                            type: updateAPI! as any,
                            api,
                            id,
                            employeeId: employeeId,
                            fields,
                            modifiedFields,
                            initialValues,
                          });
                        }

                        setEditing({
                          isEditing: false,
                          subsectionId: id,
                        });
                      }}
                    />
                  )}
                </FieldsContainer>
              </SubsectionContainer>
              {index < subsections.length - 1 && <StyledDivider />}
            </Fragment>
          );
        },
      )}
    </>
  );
};
