import { SimpleFieldDataType } from '@Components/Sections/types';
import {
  FieldContainer,
  FieldName,
  FieldValue,
  FieldValueWrapper,
  LockIcon,
} from './styled';

export const FieldCompound = ({
  name,
  value,
  locked,
  required,
  tagVariant,
  link,
  component,
  options,
}: SimpleFieldDataType) => {
  const renderValue = () => {
    switch (component) {
      case 'checkbox':
        return <>{value ? 'Sim' : 'Não'}</>;

      default:
        return <>{value}</>;
    }
  };

  return (
    <FieldContainer>
      <FieldName variant="caption">{name}</FieldName>
      <FieldValueWrapper>
        <FieldValue variant="body3">
          {value === undefined ? '-' : renderValue()}
        </FieldValue>
        {locked && <LockIcon name="IconLock" fill="transparent" size={16} />}
      </FieldValueWrapper>
    </FieldContainer>
  );
};
