import { GET_EMPLOYEE_LAST_INVITE } from '@/api';
import { useLazyQuery } from '@apollo/client';
import { useState } from 'react';

interface GetEmployeeLastInviteResponse {
  employee: {
    id: string;
    lastInvitation: {
      id: string;
    };
  };
}

export const useFetchEmployeeLastInvite = () => {
  const [fetch, { loading, data }] = useLazyQuery<
    GetEmployeeLastInviteResponse,
    { employeeId: string }
  >(GET_EMPLOYEE_LAST_INVITE, {
    fetchPolicy: 'cache-first',
  });

  const getLastInvitationId = async (employeeId: string) => {
    await fetch({
      variables: {
        employeeId,
      },
    });
  };

  const invitationCode = data?.employee?.lastInvitation?.id;

  return {
    loading,
    invitationCode,
    getLastInvitationId,
  };
};
