import styled from 'styled-components';

import { Skeleton } from '@flash-tecnologia/hros-web-ui-v2';

export const SkeletonHeaderContainer = styled.div`
  margin: 44px 0;

  display: flex;
  flex-direction: row;

  gap: 24px;
`;

export const SkeletonProfielPicture = styled(Skeleton)`
  width: 88px;
  height: 88px;
`;

export const SkeletonTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
`;

export const SkeletonName = styled(Skeleton)`
  width: 50vw;
  height: 50px;
`;

export const SkeletonText = styled(Skeleton)`
  width: 33vw;
  margin-bottom: 16px;
`;

export const SkeletonTabsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

export const SkeletonSecondaryTabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SkeletonSecondaryTab = styled(Skeleton)`
  width: 200px;
  height: 52px;
  margin-bottom: 12px;
`;

export const SkeletonSectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SkeletonSections = styled(Skeleton)`
  width: 100%;
  height: 120px;
  margin-bottom: 24px;
`;
