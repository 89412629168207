import { useNavigate } from 'react-router-dom';

import {
  IconButton,
  IconTypes,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';

import {
  Container,
  Wrapper,
  IconWrapper,
  StyledIcon,
  TextsWrapper,
  Title,
  Description,
} from './styled';

export interface RedirectCardInterface {
  title: string;
  description: string;
  redirectTo: any;
}

export const RedirectCard = ({
  title,
  description,
  redirectTo,
}: RedirectCardInterface) => {
  const navigate = useNavigate();

  return (
    <Container onClick={() => navigate(redirectTo)}>
      <Wrapper>
        <TextsWrapper>
          <Title variant="headline8">{title}</Title>
          <Description variant="body4">{description}</Description>
        </TextsWrapper>
      </Wrapper>
      <div>
        <IconButton size="small" icon="IconArrowRight" variant="filled" />
      </div>
    </Container>
  );
};
