import styled from 'styled-components';

import {
  Icons,
  LinkButton,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { StatusEnum } from 'src/common/types/StatusEnum';

const MainContainer = styled.div<{ status: StatusEnum }>`
  display: flex;
  align-items: flex-start;
  align-items: center;

  padding: 24px;
  gap: 20px;

  width: 100%;
  border: 1px solid
    ${({ status, theme }) => {
      switch (status) {
        case StatusEnum.INVITATION_EXPIRED:
          return theme.colors.feedback.error[70];
        case StatusEnum.INVITATION_SENT:
          return theme.colors.feedback.success[70];
        case StatusEnum.INVITATION_SCHEDULED:
          return theme.colors.feedback.success[70];
        case StatusEnum.INVITATION_PENDING:
          return theme.colors.feedback.info[70];
      }
    }};
  border-radius: 8px;
`;

const IconContainer = styled.div<{ status: StatusEnum }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;

  width: 48px;
  min-width: 48px;
  height: 48px;

  background: ${({ status, theme }) => {
    switch (status) {
      case StatusEnum.INVITATION_EXPIRED:
        return theme.colors.feedback.error[90];
      case StatusEnum.INVITATION_SENT:
        return theme.colors.feedback.success[90];
      case StatusEnum.INVITATION_SCHEDULED:
        return theme.colors.feedback.success[90];
      case StatusEnum.INVITATION_PENDING:
        return theme.colors.feedback.info[90];
    }
  }};
  border-radius: 200px;
`;

const TextContainer = styled.div``;
const ButtonsArea = styled.div`
  display: flex;
  gap: 15px;
  padding-top: 6px;
`;

const StyledIcon = styled(Icons)<{ status: StatusEnum }>`
  color: ${({ status, theme }) => {
    switch (status) {
      case StatusEnum.INVITATION_EXPIRED:
        return theme.colors.feedback.error[50];
      case StatusEnum.INVITATION_SENT:
        return theme.colors.feedback.success[50];
      case StatusEnum.INVITATION_SCHEDULED:
        return theme.colors.feedback.success[50];
      case StatusEnum.INVITATION_PENDING:
        return theme.colors.feedback.info[50];
    }
  }};
`;

const StyledText = styled(Typography)``;
const StyledTitle = styled(Typography)``;
const StyledLinkButton = styled(LinkButton)``;

export {
  MainContainer,
  IconContainer,
  StyledIcon,
  TextContainer,
  ButtonsArea,
  StyledText,
  StyledTitle,
  StyledLinkButton,
};
