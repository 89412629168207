import { useMemo, useState } from 'react';

import { ITableGrid } from './types';

import { Skeleton, Typography } from '@flash-tecnologia/hros-web-ui-v2';

import {
  EmailIcon,
  EmailText,
  EmailWrapper,
  FirstColumnCell,
  FirstColumnCellData,
  StyledDataGrid,
} from './styled';
import { GenericProfilePicture } from '@Components/GenericProfilePicture';
import { EmployeeGroups } from './components/EmployeeGroups';
import { EmployeeOptions } from './components/EmployeeOptions';
import { StatusTag } from '@Components/StatusTag';
import { useSession } from '@/common/user';

export const TableGrid = ({ data, loading, pageSize }: ITableGrid) => {
  const [selectedEmployees, setSelectedEmployees] = useState(new Set());
  // currently not used in any feature, we were using all employee ids to set this,
  // but it can be done in a better way with specific mutations
  const [allSelected, setAllSelected] = useState(false);
  const { authUser, userId } = useSession();

  const handleSingleSelect = (employeeId: string) => {
    setSelectedEmployees((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(employeeId)) {
        newSet.delete(employeeId);
      } else {
        newSet.add(employeeId);
      }
      return newSet;
    });
  };

  const handleTotalSelect = () => {
    setAllSelected((prev) => !prev);
    setSelectedEmployees(new Set());
  };

  const columns = useMemo(
    () => [
      // {
      //   Header: (
      //     <Checkbox
      //       onChange={() => handleTotalSelect()}
      //       checked={allSelected}
      //     />
      //   ),
      //   accessor: "id",
      //   disableSortBy: true,
      //   Cell: ({ value }) => (
      //     <Checkbox
      //       checked={selectedEmployees.has(value) || allSelected}
      //       onChange={() => handleSingleSelect(value)}
      //     />
      //   ),
      // },
      {
        Header: 'Nome',
        accessor: 'name',
        Cell: (data: any) => {
          const email = data?.cell?.row?.original?.email;
          return (
            <FirstColumnCell>
              <GenericProfilePicture
                size={40}
                name={data?.cell?.row?.original?.name}
                source={data?.cell?.row?.original?.profilePicture?.source}
              />
              <FirstColumnCellData>
                <Typography variant="body4">{data.value}</Typography>
                {!!email && (
                  <EmailWrapper>
                    <EmailIcon name="IconMail" size={16} fill="transparent" />
                    <EmailText variant="caption">{email}</EmailText>
                  </EmailWrapper>
                )}
              </FirstColumnCellData>
            </FirstColumnCell>
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }: any) => <StatusTag status={value} />,
      },
      {
        Header: 'CPF',
        accessor: 'documentNumber',
      },
      {
        Header: 'Telefone',
        accessor: 'phoneNumber',
      },
      {
        Header: 'Grupo',
        accessor: 'groups',
        Cell: ({ value }: any) => value && <EmployeeGroups groups={value} />,
        disableSortBy: true,
      },
      {
        Header: 'Ações',
        Cell: (data: any) => (
          <EmployeeOptions
            userId={userId}
            employeeId={data?.cell?.row?.original?.id}
            status={data?.cell?.row?.original?.status}
            authUser={authUser}
            documentNumber={data?.cell.row?.original.documentNumber}
          />
        ),
        sticky: 'right',
        disableSortBy: true,
      },
    ],
    [selectedEmployees, allSelected, data],
  );

  return loading ? (
    <Skeleton
      variant="rectangular"
      style={{
        marginBottom: '0px',
        borderRadius: '20px',
        minHeight: '500px',
        maxHeight: '828px',
        display: 'flex',
        flexGrow: 1,
      }}
    />
  ) : (
    <StyledDataGrid
      hasPagination={false}
      columns={columns}
      data={(data as any) || []}
      initialState={{ pageSize: Number(pageSize) }}
    />
  );
};
