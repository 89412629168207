import { useState, useRef } from 'react';
import { useSectionsManager } from '@Hooks';
import { submitSessions } from '@Utils/sectionsSubmission';
import { Subsections } from './components/Subsections';
import { EmptySection } from './components/EmptySection';
import { Fields } from './components/Fields';
import { SectionsComponentType, ActionType } from './types';

import {
  FieldsContainer,
  SectionsFieldsContainer,
  StyledAccordion,
  IconButtonStyled,
  StyledAddButton,
} from './styled';
import { RedirectCard } from './components/RedirectCard';

export const Sections = ({ sections, employeeId }: SectionsComponentType) => {
  const fieldsRef = useRef<any>(null);

  const { api, loading } = useSectionsManager();

  const [editing, setEditing] = useState({
    isEditing: false,
    sectionKey: undefined,
  });

  const [emptyStateForm, setEmptyStateForm] = useState(false);

  const renderSections = (sections: any[]) => {
    return sections?.map(
      (
        {
          title,
          description,
          key,
          fields,
          editable,
          subsections,
          emptyState,
          limitHeight,
          updateAPI,
          addSectionButton,
          newSectionForm,
          redirect,
          redirectTo,
        },
        index,
      ) => {
        const isEditing = editing.isEditing && editing.sectionKey === key;
        const isNotEmpty = !!fields?.length || !!subsections?.length;

        const actionsOptions: ActionType[] = [
          {
            children: (
              <IconButtonStyled
                key={`${index}-${title}-${description}-${key}`}
                size="small"
                variant="line"
                editing={isEditing}
                icon={isEditing ? 'IconCheck' : 'IconPencil'}
                disabled={loading}
                onClick={(e) => {
                  isEditing && fieldsRef?.current?.handleSubmit();

                  !isEditing &&
                    setEditing({
                      isEditing: true,
                      sectionKey: key,
                    });

                  e.stopPropagation();
                }}
              />
            ),
            onClick: () => {},
          },
        ];

        const renderEmptyState = () => {
          if (emptyStateForm)
            return (
              <Subsections
                subsections={newSectionForm.subsections}
                employeeId={employeeId}
                toggleEmptyForm={() => setEmptyStateForm(false)}
              />
            );
          else
            return (
              <EmptySection
                {...emptyState}
                setEmptyStateForm={setEmptyStateForm}
              />
            );
        };

        if (isEditing)
          actionsOptions.push({
            children: (
              <IconButtonStyled
                key={`${key}-${title}-${description}-${index}`}
                size="small"
                variant="line"
                icon={'IconX'}
                onClick={(e) => {
                  fieldsRef?.current?.resetForm();

                  setEditing({
                    isEditing: !isEditing,
                    sectionKey: key,
                  });
                  e.stopPropagation();
                }}
              />
            ),
            onClick: () => {},
          });

        if (!editable) actionsOptions.length = 0;

        if (redirect) {
          return (
            <RedirectCard
              key={`${key}-${description}-${index}`}
              title={title}
              description={description}
              redirectTo={redirectTo(employeeId)}
            />
          );
        }

        return (
          <StyledAccordion
            variant="default"
            key={`${key}-${title}-${index}-${description}`}
            title={title}
            description={description}
            actions={actionsOptions}
            disabled={false}
          >
            {!!addSectionButton && (
              <StyledAddButton
                size="large"
                variant="secondary"
                onClick={() => {
                  subsections.unshift(newSectionForm.subsections[0]);
                  setEditing({
                    isEditing: false,
                    sectionKey: undefined,
                  });
                }}
              >
                {addSectionButton}
              </StyledAddButton>
            )}
            <FieldsContainer
              key={`${key}-${index}-0`}
              limitHeight={limitHeight}
            >
              {isNotEmpty ? (
                <>
                  {fields && (
                    <Fields
                      key={`${key}-${index}`}
                      ref={fieldsRef}
                      fields={fields}
                      isEditing={isEditing}
                      loading={loading}
                      onSubmit={async ({
                        fields,
                        initialValues,
                        modifiedFields,
                      }) => {
                        if (Object.keys(modifiedFields).length) {
                          await submitSessions({
                            type: updateAPI,
                            api,
                            id: '',
                            employeeId: employeeId,
                            fields,
                            modifiedFields,
                            initialValues,
                          });
                        }

                        setEditing({
                          isEditing: false,
                          sectionKey: key,
                        });
                      }}
                    />
                  )}
                  {subsections && (
                    <Subsections
                      key={`${key}-${index}`}
                      subsections={subsections}
                      employeeId={employeeId}
                      toggleEmptyForm={(index) => {
                        if (isNotEmpty) {
                          delete subsections[index];
                          setEditing({
                            isEditing: false,
                            sectionKey: undefined,
                          });
                        } else setEmptyStateForm(false);
                      }}
                    />
                  )}
                </>
              ) : (
                renderEmptyState()
              )}
            </FieldsContainer>
          </StyledAccordion>
        );
      },
    );
  };

  return (
    <SectionsFieldsContainer>
      {renderSections(sections)}
    </SectionsFieldsContainer>
  );
};
