import * as yup from 'yup';

export const getYupFieldType = (fieldType: string) => {
  switch (fieldType) {
    case 'number':
      return yup
        .number()
        .required()
        .typeError('Favor preencher um número válido')
        .required('Favor preencher, campo obrigatório!');
    case 'date':
      return yup
        .date()
        .required()
        .typeError('Favor preencher uma data válida')
        .required('Favor preencher, campo obrigatório!');
    case 'boolean':
      return yup
        .boolean()
        .required()
        .typeError('Favor preencher um valor válido')
        .required('Favor preencher, campo obrigatório!');
    case 'array':
      return yup
        .array()
        .required()
        .typeError('Favor preencher um valor válido')
        .required('Favor preencher, campo obrigatório!');
    case 'object':
      return yup
        .object()
        .required()
        .typeError('Favor preencher um valor válido')
        .required('Favor preencher, campo obrigatório!');
    case 'text':
    default:
      return yup
        .string()
        .required()
        .typeError('Favor preencher um valor válido')
        .required('Favor preencher, campo obrigatório!');
  }
};
