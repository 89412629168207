import { useCallback, useMemo, useState } from 'react';
import { Icons, Menu } from '@flash-tecnologia/hros-web-ui-v2';
import { ModalAddEmployee } from '@Components/ModalAddEmployee';
import { AddColabButton, Option } from './styled';
import { useNavigate } from 'react-router-dom';
import dispatchToast from '@Utils/dispatchToast';
import dayjs, { Dayjs } from 'dayjs';
import { PermissionProfilesType } from 'src/pages/PageTable/types';
import { useSession } from 'src/common/user';
import { getWorksheetUrl } from '@/pages/PageEmployeesWorksheet/helpers/getWorksheetUrl';
import {
  EmployeesWorksheetSteps,
  ImportType,
} from '@/pages/PageEmployeesWorksheet/types';
import { useSearchEmployee } from '@/pages/PageTable/hooks/useSearchEmployee';
import { useCreateEmployee } from '@/pages/PageTable/hooks/useCreateEmployee';
import { useEmployeeAlreadyExistsModal } from '@/pages/PageTable/hooks/useEmployeeAlreadyExistsModal';
import { ModalAddEmployeeSuccessWithInvitationLink } from '@Components/ModalAddEmployeeSuccessWithInvitationLink';
import { ModalWarning } from '@Components/ModalWarning';
import { useRetryQuery } from '@/pages/PageTable/hooks/useRetryQuery';
import { GET_EMPLOYEE_LAST_INVITE } from '@/api';
import { useSuccessCreateEmployeeModal } from '@/pages/PageTable/hooks/useSuccessCreateEmployeeModal';
const formId = 'menu-add-colab-register-employee';

export const MenuAddColab = ({ disabled }: PermissionProfilesType) => {
  const navigate = useNavigate();
  const { companyId } = useSession();
  const { checkEmployeeExistence } = useSearchEmployee();
  const { employeeAlreadyExistsModal, fallback } =
    useEmployeeAlreadyExistsModal();
  const { successCreateEmployeeModal, setScheduleInvite, isScheduleInvite } =
    useSuccessCreateEmployeeModal();
  const [isModalAddEmployeeOpen, setIsModalAddEmployeeOpen] = useState(false);
  const [lockedEmployeeCreation, setLockedEmployeeCreation] = useState(false);

  const handleSuccessCreateEmployee = useCallback(() => {
    successCreateEmployeeModal.open();
    setIsModalAddEmployeeOpen(false);
    setLockedEmployeeCreation(false);
  }, []);

  const onCompletedCreateEmployee = useCallback((data: any) => {
    if (!isScheduleInvite) {
      return getLastInvitationIdPolling.query({
        employeeId: data.createEmployee.id,
      });
    }

    handleSuccessCreateEmployee();
  }, []);

  const onCloseEmployeeAlreadyExistsModal = useCallback(() => {
    employeeAlreadyExistsModal.close();
    setLockedEmployeeCreation(false);
  }, []);

  const { createEmployee, loading } = useCreateEmployee({
    options: {
      onCompleted: onCompletedCreateEmployee,
      onError: () => setLockedEmployeeCreation(false),
    },
  });

  const getLastInvitationIdPolling = useRetryQuery<
    { employeeId: string },
    { employee: { lastInvitation: { id: string } } }
  >({
    maxAttempts: 6,
    document: GET_EMPLOYEE_LAST_INVITE,
    interval: 2000,
    stopCondition: (data) => {
      return !!data?.employee?.lastInvitation;
    },
    onCompleted: handleSuccessCreateEmployee,
  });

  const loadingCreateEmployee = useMemo(
    () => loading || getLastInvitationIdPolling.loading,
    [loading, getLastInvitationIdPolling],
  );

  const options = [
    {
      key: 'one-employee',
      onClick: () => setIsModalAddEmployeeOpen(true),
      children: (
        <Option>
          <Icons name="IconUser" fill="transparent" />
          Apenas uma pessoa
        </Option>
      ),
    },
    {
      key: 'multiple-employees',
      onClick: () =>
        navigate(
          getWorksheetUrl(
            ImportType.insert,
            EmployeesWorksheetSteps.uploadFile,
          ),
        ),
      children: (
        <Option>
          <Icons name="IconUsers" fill="transparent" />
          Várias pessoas
        </Option>
      ),
    },
  ];

  const onBlur = async () => {
    return {
      error: false,
      helperText: '',
    };
  };

  const onError = useCallback((err: string[]) => {
    if (Object.keys(err)?.length) {
      dispatchToast({
        type: 'error',
        content: `É necessário preencher os campos de ${err
          .join(', ')
          .toLowerCase()}`,
      });
    }
  }, []);

  const createContacts = (result: Record<string, string>) => {
    const contacts = [];

    if (result.email) {
      contacts.push({ type: 'email', value: result.email.trim() });
    }

    if (result.phone) {
      contacts.push({ type: 'phone', value: result.phone.trim() });
    }

    return contacts;
  };

  const employeeAlreadyExistsByContact = async (
    contacts: { type: string; value: string }[],
  ) => {
    const contactsAlreadyExists = {
      email: false,
      phone: false,
    } as any;

    await Promise.all(
      contacts.map(async ({ type, value }: { type: string; value: string }) => {
        contactsAlreadyExists[type] = await checkEmployeeExistence({
          query: value,
          companyId,
        });
      }),
    );

    return contactsAlreadyExists;
  };

  const onSubmit = async (
    result: any,
    permissionProfile: string,
    inviteDate: Dayjs | null,
    inviteTime: Dayjs | null,
    scheduleInvite: boolean,
  ) => {
    setLockedEmployeeCreation(true);

    const inviteSchedule = dayjs(
      new Date(
        inviteDate?.year()!,
        inviteDate?.month()!,
        inviteDate?.date(),
        inviteTime?.hour(),
        inviteTime?.minute(),
      ),
    );

    const contacts = createContacts(result);
    const contactsAlreadyExists =
      await employeeAlreadyExistsByContact(contacts);

    setScheduleInvite({
      isScheduleInvite: scheduleInvite,
      date: inviteSchedule,
    });

    employeeAlreadyExistsModal.open(contactsAlreadyExists, async () => {
      employeeAlreadyExistsModal.close();
      createEmployee({
        variables: {
          input: {
            name: result.name,
            documentNumber:
              result.nationalDocumentNumber ?? result.foreignDocumentNumber,
            contacts: contacts,
            scheduleInvite: scheduleInvite,
            inviteSchedule: inviteSchedule,
            companyId: companyId,
          },
        },
      });
    });
  };

  return (
    <>
      <Menu
        disabled={disabled}
        type={'default'}
        options={options}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <AddColabButton disabled={disabled} variant="primary" size="large">
          Convidar membros da equipe
          <Icons name="IconUserPlus" fill="transparent" />
        </AddColabButton>
      </Menu>
      <ModalAddEmployee
        isOpen={isModalAddEmployeeOpen}
        onClose={() => setIsModalAddEmployeeOpen(false)}
        headerProps={{ onClose: () => setIsModalAddEmployeeOpen(false) }}
        footerProps={{
          onClose: () => setIsModalAddEmployeeOpen(false),
          formId: formId,
          loading: loadingCreateEmployee,
          locked: lockedEmployeeCreation,
        }}
        formProps={{
          formId: formId,
          onError: onError,
          blur: {
            affectedFields: [
              'email',
              'phone',
              'nationalDocumentNumber',
              'foreignDocumentNumber',
            ],
            onBlur: onBlur,
          },
          onSubmit: (
            result,
            permissionProfile,
            inviteDate,
            inviteTime,
            scheduleInvite,
          ) =>
            onSubmit(
              result,
              permissionProfile,
              inviteDate,
              inviteTime,
              scheduleInvite,
            ),
        }}
      />

      <ModalWarning
        isOpen={employeeAlreadyExistsModal.config.isOpen}
        loading={getLastInvitationIdPolling.loading}
        title={employeeAlreadyExistsModal.config.title}
        description={employeeAlreadyExistsModal.config.description}
        onClose={onCloseEmployeeAlreadyExistsModal}
        onSubmit={fallback!}
      />
      <ModalAddEmployeeSuccessWithInvitationLink
        isOpen={successCreateEmployeeModal.config.isOpen}
        title={successCreateEmployeeModal.config.title}
        description={successCreateEmployeeModal.config.description}
        showInvitationCode={
          successCreateEmployeeModal.config.showInvitationCode
        }
        onClose={() => successCreateEmployeeModal.close()}
        onSubmit={() => successCreateEmployeeModal.close()}
        invitationCode={
          getLastInvitationIdPolling.data?.employee?.lastInvitation?.id!
        }
      />
    </>
  );
};
