import readXlsxFile from 'read-excel-file';
interface dataReaderProps {
  droppedFiles: any;
}

export const XLSXReader = ({
  droppedFiles,
}: dataReaderProps): Promise<any[]> => {
  return new Promise<any[]>(async (resolve) => {
    if (droppedFiles === undefined) return [];
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(droppedFiles[0]?.file);

    const data = await readXlsxFile(droppedFiles[0]?.file);

    resolve(data);
  });
};
