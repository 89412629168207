import { FieldComponent } from '@Components/FieldComponent';
import { EditingFieldSimpleComponentType } from '@Components/Sections/types';

import { FieldContainer } from './styled';

export const FieldSimpleEdit = ({
  onChange,
  value,
  onBlur,
  ...field
}: EditingFieldSimpleComponentType) => {
  return (
    <FieldContainer>
      <FieldComponent
        compoundField={false}
        disabled={!!field.locked}
        value={value}
        {...field}
        onChange={(value) => onChange(value)}
        onBlur={() => onBlur()}
      />
    </FieldContainer>
  );
};
