import { useMemo, useCallback, forwardRef, useImperativeHandle } from 'react';
import { useFormik } from 'formik';
import _ from 'lodash';
import * as yup from 'yup';

import { getObjDiffs } from '@Utils/objDiffs';
import { getYupFieldType } from '@Utils/getYupFieldType';

import {
  FieldsDataType,
  SimpleFieldDataType,
  FieldsSubmissionType,
} from '@Components/Sections/types';

import { FieldCompound } from './components/FieldCompound';
import { FieldCompoundEdit } from './components/FieldCompoundEdit';
import { FieldSimple } from './components/FieldSimple';
import { FieldSimpleEdit } from './components/FieldSimpleEdit';
import {
  CompoundFieldContainer,
  EditingCompoundFieldContainer,
} from './styled';

export const Fields = forwardRef(
  (
    {
      fields,
      isEditing,
      onSubmit,
      loading,
    }: {
      fields: FieldsDataType[];
      isEditing: boolean;
      loading?: boolean;
      onSubmit?: ({
        initialValues,
        modifiedFields,
        fields,
      }: FieldsSubmissionType) => void;
    },
    ref,
  ) => {
    const flattenCompoundFields = useCallback((field: FieldsDataType): any => {
      if (!field?.compoundFields || !field?.compoundFields?.length) {
        return field;
      }
      return [_.flatMapDeep(field?.compoundFields, flattenCompoundFields)];
    }, []);

    const flatFields = useMemo(
      () => _.flatMapDeep(fields, flattenCompoundFields),
      [fields],
    ) as any[];

    const schemaObject = useMemo(
      () =>
        yup
          .object()
          .shape(
            Object.fromEntries(
              flatFields
                .filter((ft) => ft.required)
                .map((field) => [field.key, getYupFieldType(field.type)]),
            ),
          ),
      [flatFields],
    );

    const flattedInitialValues = useMemo(
      () =>
        _.reduce(
          flatFields,
          (result: any, field) => {
            result[field?.key] = field?.value;
            return result;
          },
          {},
        ),
      [fields],
    );

    const formik = useFormik({
      initialValues: flattedInitialValues,
      validationSchema: schemaObject,
      enableReinitialize: true,
      onSubmit: (values) => {
        const changes = getObjDiffs(values, flattedInitialValues);

        onSubmit?.call(null, {
          initialValues: flattedInitialValues,
          fields,
          modifiedFields: changes,
        });
      },
    });

    useImperativeHandle(ref, () => ({
      handleSubmit: () => formik.handleSubmit(),
      resetForm: () => formik.resetForm(),
    }));

    const renderFieldCompoundEdit = (compoundField: any) => (
      <FieldCompoundEdit
        {...compoundField}
        value={formik.values[compoundField.key] || undefined}
        error={
          formik.touched[compoundField?.key] &&
          Boolean(formik.errors[compoundField?.key])
        }
        helperText={
          formik.touched[compoundField?.key] &&
          formik.errors[compoundField?.key]
        }
        onChange={(value) => {
          formik.handleChange({
            target: { id: compoundField.key, value: value },
          });
        }}
      />
    );

    return (
      <>
        {isEditing &&
          fields.map((field) => {
            if (field?.compound) {
              return (
                <EditingCompoundFieldContainer key={field.key}>
                  {field.compoundFields.map(
                    ({
                      conditionalRender,
                      key,
                      name,
                      locked,
                      mask,
                      pattern,
                      component,
                      required,
                      tooltip,
                    }) => {
                      if (conditionalRender) {
                        if (
                          formik.values[conditionalRender.key] ===
                          conditionalRender.value
                        ) {
                          return renderFieldCompoundEdit({
                            key,
                            name,
                            locked,
                            mask,
                            pattern,
                            component,
                            required,
                            tooltip,
                          });
                        }
                      } else {
                        return renderFieldCompoundEdit({
                          key,
                          name,
                          locked,
                          mask,
                          pattern,
                          component,
                          required,
                          tooltip,
                        });
                      }
                    },
                  )}
                </EditingCompoundFieldContainer>
              );
            }
            return (
              <FieldSimpleEdit
                key={field.key}
                name={field.name}
                locked={field.locked}
                mask={field.mask}
                pattern={field.pattern}
                component={field?.component}
                required={field.required}
                options={field.options}
                selectOptions={field.selectOptions}
                value={formik.values[field?.key]}
                error={
                  formik.touched[field?.key] &&
                  Boolean(formik.errors[field?.key])
                }
                helperText={
                  (formik.touched[field?.key] &&
                    formik.errors[field?.key]) as string
                }
                onBlur={() => {
                  formik.handleBlur({ target: { id: field.key } });
                }}
                onChange={(value) => {
                  formik.handleChange({
                    target: { id: field.key, value: value },
                  });
                }}
              />
            );
          })}
        {!isEditing &&
          fields.map((field) => {
            if (field?.compound) {
              return (
                <CompoundFieldContainer key={field.key}>
                  {field.compoundFields.map((compoundField) => {
                    return (
                      <FieldCompound
                        {...compoundField}
                        key={compoundField.key}
                        value={formik.values[compoundField.key] || undefined}
                      />
                    );
                  })}
                </CompoundFieldContainer>
              );
            }
            return (
              <FieldSimple
                loading={loading}
                {...(field as SimpleFieldDataType)}
                value={formik.values[field?.key]}
              />
            );
          })}
      </>
    );
  },
);
