import { FooterCancelButton, FooterContainer, SubmitButton } from './styled';

export const Footer = ({
  onClose,
  onSubmit,
  submitText,
  loading,
  disabled,
}: Input) => {
  return (
    <FooterContainer>
      <FooterCancelButton onClick={onClose}>Cancelar</FooterCancelButton>
      <SubmitButton
        variant={'primary'}
        variantType={'error'}
        buttonType={'primary'}
        size={'small'}
        style={{ margin: 'auto 0', padding: '19px 52px' }}
        onClick={onSubmit}
        loading={loading}
        disabled={disabled}
        disableRipple
        disableTouchRipple
      >
        {submitText}
      </SubmitButton>
    </FooterContainer>
  );
};

type Input = {
  onClose: () => void;
  onSubmit: () => void;
  submitText: string;
  loading: boolean;
  disabled: boolean;
};
