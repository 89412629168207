import { SecondaryTabCard } from '@Components/SecondaryTabCard';
import { TabListInterface } from '../../types';
import { TabListContainer } from './styled';

export const TabList = ({
  tabs,
  selectedTab,
  setSelectedTab,
}: TabListInterface) => {
  return (
    <TabListContainer>
      {tabs?.map(({ key, title }, index) => {
        const isSelected = key === selectedTab?.key;

        return (
          <SecondaryTabCard
            key={`${key}-${title}`}
            id={key}
            title={title}
            isSelected={isSelected}
            setSelectedSecondaryTab={() => setSelectedTab(index)}
          />
        );
      })}
    </TabListContainer>
  );
};
