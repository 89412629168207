import { Modal as FModal } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Modal = styled(FModal)`
  && {
    &.modal-custom-theme .modal-content-area {
      padding: 34px 130px 40px 112px;
    }
  }
`;
