import { FieldComponent } from '@Components/FieldComponent';
import { EditingCompoundFieldComponentType } from '@Components/Sections/types';

export const FieldCompoundEdit = ({
  onChange,
  ...field
}: EditingCompoundFieldComponentType) => {
  return (
    <FieldComponent
      {...field}
      key={field.key}
      compoundField={true}
      disabled={!!field.locked}
      onChange={(value) => onChange(value)}
    />
  );
};
