import { TabType } from './types';

export const tabs: TabType[] = [
  {
    title: 'Acessos e senhas',
    key: 'accessPasswords',
    sections: [
      {
        title: 'Chaves de acesso',
        description: 'Gerencie seus dados de acesso à plataforma.',
        editable: false,
        fields: [],
      },
      {
        title: 'Senha de acesso',
        description: 'Senha de acesso à plataforma Flash e ao aplicativo.',
        editable: true,
        fields: [],
      },
    ],
  },
  {
    title: 'Termos e políticas',
    key: 'termsPolicies',
    sections: [
      {
        title: 'Termos de uso',
        description:
          'Leia com atenção os termos de uso e lembre-se de dar o aceite para garantir uma melhor utilização da nossa plataforma.',
        editable: false,
        fields: [],
      },
      {
        title: 'Políticas de privacidade',
        description:
          'Confira as políticas de privacidade e uso de dados e lembre-se de dar o aceite para garantir uma melhor experiência.',
        editable: false,
        fields: [],
      },
    ],
  },
];
