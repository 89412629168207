import { IconButton } from '@flash-tecnologia/hros-web-ui-v2';

import {
  StyledModal,
  MainContainer,
  HeaderContainer,
  Card,
  Imagem,
  Line,
  TextContainer,
  FooterContainer,
  StyledButton,
} from './styled';

import { StyledText, StyledTitle } from 'src/common/styles/general-styles';

import HeaderHelp1 from '../../../../assets/headerHelp1.png';
import HeaderHelp2 from '../../../../assets/headerHelp2.png';
import HeaderHelp3 from '../../../../assets/headerHelp3.png';
import HeaderHelp4 from '../../../../assets/headerHelp4.png';

interface HeaderHelperModal {
  setOpen: boolean;
  onClose: () => void;
}

export const HeaderHelperModal = ({ setOpen, onClose }: HeaderHelperModal) => {
  const Header = (
    <HeaderContainer>
      <TextContainer>
        <StyledTitle variant="headline6" setColor="neutral10">
          Como identificar se o arquivo possui cabeçalho?
        </StyledTitle>

        <StyledText setColor="neutral50" variant="body3">
          Veja nos exemplos abaixo como você pode identificar se o seu arquivo
          possui cabeçalho:
        </StyledText>
      </TextContainer>
      <IconButton
        style={{ top: '-20px' }}
        variant="line"
        size="medium"
        icon={'IconX'}
        onClick={onClose}
      />
    </HeaderContainer>
  );

  const Footer = (
    <FooterContainer>
      <StyledButton
        variant="primary"
        size="large"
        style={{ alignSelf: 'center' }}
        onClick={onClose}
      >
        Voltar
      </StyledButton>
    </FooterContainer>
  );

  return (
    <StyledModal
      header={Header}
      footer={Footer}
      open={setOpen}
      onClose={onClose}
    >
      <MainContainer>
        <Card>
          <StyledTitle variant="body2" setColor="neutral30">
            Arquivo em formato de tabela
          </StyledTitle>
          <Imagem src={HeaderHelp1} />
          <Line />
          <Imagem src={HeaderHelp2} />
        </Card>
        <Card>
          <StyledTitle variant="body2" setColor="neutral30">
            Arquivo em formato de texto
          </StyledTitle>
          <Imagem src={HeaderHelp3} />
          <Line />
          <Imagem src={HeaderHelp4} />
        </Card>
      </MainContainer>
    </StyledModal>
  );
};
