import { Icons, Tag, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FieldName = styled(Typography)`
  color: var(--color-neutral-50);
`;

export const FieldValueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

export const FieldValue = styled(Typography)`
  color: var(--color-neutral-20);
`;

export const LockIcon = styled(Icons)`
  color: var(--color-neutral-70);
`;

export const StyledTag = styled(Tag)`
  padding: 4px 12px;
`;

export const ObjectValueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;

  max-width: 642px;
  flex-wrap: wrap;
`;
