import {
  GET_PAGE_TABLE_EMPLOYEES,
  useDeactivateEmployee,
  useDeleteEmployee,
} from '@/api';
import { ErrorBoundary } from '@utils/ErrorBoundary';
import dispatchToast from '@utils/dispatchToast';
import { useState } from 'react';

export const useEmployeeOptions = () => {
  const [deactivateModalIsOpen, setDeactivateModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const { deactivateEmployee, loading: isDeactivating } = useDeactivateEmployee(
    {
      onCompleted: () => {
        dispatchToast({
          type: 'success',
          content: 'Pessoa desativada com sucesso!',
        });
        setDeactivateModalIsOpen(false);
      },
      onError: (error) => {
        ErrorBoundary.captureException(error);
        dispatchToast({
          type: 'error',
          content:
            'Ocorreu um erro ao desativar essa pessoa. Por favor, tente novamente mais tarde.',
        });
      },
      refetchQueries: [GET_PAGE_TABLE_EMPLOYEES],
    },
  );

  const { deleteEmployee, loading: isDeleting } = useDeleteEmployee({
    onCompleted: () => {
      dispatchToast({
        type: 'success',
        content: 'Pessoa deletada com sucesso!',
      });
      setDeactivateModalIsOpen(false);
    },
    onError: (error) => {
      ErrorBoundary.captureException(error);
      dispatchToast({
        type: 'error',
        content:
          error.message ||
          'Ocorreu um erro ao deletar essa pessoa. Por favor, tente novamente mais tarde.',
      });
    },
    refetchQueries: [GET_PAGE_TABLE_EMPLOYEES],
  });

  return {
    deactivateModalIsOpen,
    setDeactivateModalIsOpen,
    deleteModalIsOpen,
    setDeleteModalIsOpen,
    deactivateEmployee,
    isDeactivating,
    deleteEmployee,
    isDeleting,
  };
};
