import {
  ButtonContainer,
  FlexContainer,
  StyledButton,
  StyledIcon,
  StyledLinked,
} from './styled';

interface ButtomAreaProps {
  onForward?: React.MouseEventHandler<HTMLButtonElement>;
  onBackwards?: React.MouseEventHandler<HTMLButtonElement>;
  onCancel?: React.MouseEventHandler<HTMLButtonElement>;
  variant?: string;
  disabled?: boolean;
  loading?: boolean;
}

export const ButtonArea = ({
  onForward,
  onBackwards,
  onCancel,
  variant,
  disabled,
  loading,
}: ButtomAreaProps) => {
  return (
    <ButtonContainer>
      <StyledLinked
        variant="primary"
        style={{ alignSelf: 'center' }}
        onClick={onCancel}
      >
        Cancelar
      </StyledLinked>
      {variant !== 'firstPage' ? (
        <FlexContainer>
          <StyledButton variant="secondary" size="large" onClick={onBackwards}>
            <StyledIcon name="IconArrowLeft" /> Voltar
          </StyledButton>

          <StyledButton
            disabled={disabled}
            variant="primary"
            size="large"
            onClick={onForward}
            loading={loading}
          >
            Continuar <StyledIcon name="IconArrowRight" />
          </StyledButton>
        </FlexContainer>
      ) : (
        <StyledButton
          disabled={disabled}
          variant="primary"
          size="large"
          onClick={onForward}
          loading={loading}
        >
          Continuar <StyledIcon name="IconArrowRight" />
        </StyledButton>
      )}
    </ButtonContainer>
  );
};
