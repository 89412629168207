import { Card, Title } from './styled';

interface SecondaryTabCardInterface {
  title: string;
  id: string;
  isSelected: boolean;
  setSelectedSecondaryTab: (key: string) => void;
}

export const SecondaryTabCard = ({
  id,
  isSelected,
  title,
  setSelectedSecondaryTab,
}: SecondaryTabCardInterface) => {
  return (
    <Card
      key={id}
      selected={isSelected}
      onClick={() => setSelectedSecondaryTab(id)}
    >
      <Title variant="body3" tag="span" selected={isSelected}>
        {title}
      </Title>
    </Card>
  );
};
