import { useMemo } from 'react';
import { IconButton, Icons, Menu } from '@flash-tecnologia/hros-web-ui-v2';
import { TOption } from './types';

import { Option } from './styled';
import { getWorksheetUrl } from '@/pages/PageEmployeesWorksheet/helpers/getWorksheetUrl';
import {
  EmployeesWorksheetSteps,
  ImportType,
} from '@/pages/PageEmployeesWorksheet/types';
import { useNavigate } from 'react-router-dom';

export const MenuOptions = () => {
  const navigate = useNavigate();
  const options: TOption[] = useMemo(() => {
    const opt: TOption[] = [
      {
        key: 'edit',
        onClick: () =>
          navigate(
            getWorksheetUrl(
              ImportType.update,
              EmployeesWorksheetSteps.uploadFile,
            ),
          ),
        children: (
          <Option>
            <Icons name="IconTable" fill="transparent" />
            Editar via planilha
          </Option>
        ),
      },
    ];
    return opt;
  }, []);

  return (
    <Menu
      type={'select'}
      options={options}
      disableAutoFocusItem={true}
      anchorOrigin={{ vertical: 80, horizontal: -260 }}
    >
      <IconButton variant="line" size="large" icon="IconDotsVertical" />
    </Menu>
  );
};
