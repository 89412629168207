import { CreateImportInput } from './createImportPayloadBuilder';

export const generateFileDictionary = (
  values: Record<string, any>,
  hasHeader: boolean,
): CreateImportInput['fileDictionary'] => {
  return {
    documentNumber: hasHeader
      ? values.documentField.value
      : values.documentField.index,
    registrationNumber: hasHeader
      ? values.registrationNumberField.value
      : values.registrationNumberField.index,
    ...(values.nameField && {
      name: hasHeader ? values.nameField.value : values.nameField.index,
    }),
    ...(values.phoneField && {
      phone: hasHeader ? values.phoneField.value : values.phoneField.index,
    }),
    ...(values.emailField && {
      email: hasHeader ? values.emailField.value : values.emailField.index,
    }),
    ...(values.roleField && {
      role: hasHeader ? values.roleField.value : values.roleField.index,
    }),
    ...(values.departmentField && {
      department: hasHeader
        ? values.departmentField.value
        : values.departmentField.index,
    }),
    ...(values.groupField && {
      group: hasHeader ? values.groupField.value : values.groupField.index,
    }),
    ...(values.managerField && {
      managerDocumentNumber: hasHeader
        ? values.managerField.value
        : values.managerField.index,
    }),
    ...(values.pisField && {
      pis: hasHeader ? values.pisField.value : values.pisField.index,
    }),
    ...(values.startDateField && {
      startDate: hasHeader
        ? values.startDateField.value
        : values.startDateField.index,
    }),
  };
};
