import {
  MonitoringErrorBoundary,
  MonitoringManager,
} from '@flash-tecnologia/hros-web-utility';
import { MonitoringLevel } from '@flash-tecnologia/hros-web-utility/dist/monitoring/MonitoringLevel';

const projectDsn =
  'https://ba03b01736fb4fdc9f83c09cd233d2d5@o266934.ingest.sentry.io/4505603201236992';

export class ErrorBoundary extends MonitoringErrorBoundary {
  project = projectDsn;

  constructor(props: any) {
    super(props);
  }

  static captureException(
    error: Error,
    severity?: MonitoringLevel,
    extras?: Record<string, any>,
  ) {
    MonitoringManager.captureException(projectDsn, error, severity, extras);
  }

  componentDidCatch(error: any) {
    MonitoringManager.captureException(this.project, error);
  }
}
