import { useFormik } from 'formik';
import { useScheduleInvite } from '../../hooks/useScheduleInvite';
import { useSendInvite } from '../../hooks/useSendInvite';
import dayjs from 'dayjs';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';

const validationSchema = yup.object({
  date: yup.string().required('Por favor, selecione a hora do envio'),
});

export const useEditSchedule = (
  onClose: () => void,
  resetTimer: () => void,
) => {
  const { id } = useParams();

  const { scheduleInvite, loading: scheduleInviteLoading } =
    useScheduleInvite();

  const { sendInvite, loading: sendInviteLoading } = useSendInvite(resetTimer);

  const formik = useFormik<{
    type: 'SEND_INVITE' | 'SCHEDULE_INVITE';
    date: Date;
  }>({
    initialValues: {
      type: 'SEND_INVITE',
      date: dayjs().add(1, 'day').toDate(),
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (values.type == 'SEND_INVITE') {
        await sendInvite({
          variables: {
            employeeId: id!,
          },
        });
      }
      if (values.type == 'SCHEDULE_INVITE') {
        await scheduleInvite({
          variables: {
            input: {
              employeeId: id!,
              newDate: values.date,
            },
          },
        });
      }
      onClose();
    },
  });

  const loading = scheduleInviteLoading || sendInviteLoading;

  return {
    formik,
    loading,
  };
};
