import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PageContainer } from '@flash-tecnologia/hros-web-ui-v2';
import { EmployeePageBreadcrumbs } from '@Components/EmployeePageBreadcrumbs';
import { EmployeePageHeader } from '@Components/EmployeePageHeader';
import { EmployeePageSkeleton } from '@Components/EmployeePageSkeleton';
import { dataModel } from './dataModel';
import { InviteCard } from './components/InviteCard';
import { StatusEnum } from '../../common/types/StatusEnum';
import { useSession } from '../../common/user';
import { TabList } from './components/TabList';
import { Content } from './styled';
import { Sections } from '@Components/Sections';
import { useGetProfile } from './hooks/useGetProfile';
import { useFetchCompanyEmployees } from './hooks/useFetchPersonManage';
import { ModalDeleteProfilePicture } from './components/ModalDeleteProfilePicture';
import { ModalEditProfilePicture } from './components/ModalEditProfilePicture';

export const PagePersonManage = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [modalEditProfilePicture, setModalEditProfilePicture] = useState(false);
  const [modalDeleteProfilePicture, setModalDeleteProfilePicture] =
    useState(false);
  const { id: employeeIdParam } = useParams() as { id: string };
  const { employeeId: loggedInEmployeeId, companyId } = useSession();
  const employeeId =
    employeeIdParam === 'me' ? loggedInEmployeeId : employeeIdParam;
  const { loading, employee } = useGetProfile(employeeId);
  const { companyEmployees, loading: loadingEmployeesList } =
    useFetchCompanyEmployees(companyId);

  const shouldShowInviteCard =
    employee?.status !== StatusEnum.ACTIVE &&
    employee?.status !== StatusEnum.INACTIVE;

  const tabs = useMemo(
    () => dataModel(employee, companyEmployees),
    [employee, companyEmployees],
  );

  return (
    <PageContainer>
      <EmployeePageBreadcrumbs name={employee?.name || ''} />
      {loading || loadingEmployeesList || !employee ? (
        <EmployeePageSkeleton />
      ) : (
        <>
          <EmployeePageHeader
            name={employee.name}
            documentNumber={employee.documentNumber}
            profilePicture={employee.profilePicture}
            status={employee.status}
            startDate={employee.startDate}
            manager={employee.manager}
            showOptions={employeeId !== loggedInEmployeeId}
            showDocumentManagement
            employeeId={employeeId}
            invitationCode={employee.invitationCode}
            profilePictureEdit={true}
            profilePictureEditCallback={() => setModalEditProfilePicture(true)}
            profilePictureDeleteCallback={() =>
              setModalDeleteProfilePicture(true)
            }
          />
          {shouldShowInviteCard && employee.invitationDate && (
            <InviteCard
              status={employee.status}
              invitationDate={new Date(employee.invitationDate)}
              name={employee.name}
              employeeId={employee.id}
            />
          )}
          <ModalEditProfilePicture
            isOpen={modalEditProfilePicture}
            onClose={() => setModalEditProfilePicture(false)}
            employeeId={employeeId}
          />
          <ModalDeleteProfilePicture
            isOpen={modalDeleteProfilePicture}
            onClose={() => setModalDeleteProfilePicture(false)}
            name={employee?.name}
            source={employee?.profilePicture || ''}
            employeeId={employeeId}
          />
          <Content>
            <TabList
              tabs={tabs}
              selectedTab={tabs[selectedTabIndex]}
              setSelectedTab={setSelectedTabIndex}
            />
            <Sections
              sections={tabs[selectedTabIndex]?.sections}
              employeeId={employeeId}
            />
          </Content>
        </>
      )}
    </PageContainer>
  );
};
