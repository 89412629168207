import styled from 'styled-components';
import { IconButton, Icons, Stepper } from '@flash-tecnologia/hros-web-ui-v2';

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 27px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[90]};
`;

const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const LeftContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`;

const StyledIcon = styled(Icons)`
  && {
    color: ${({ theme }) => theme.colors.neutral[50]};
  }
`;

const StyledIconButton = styled(IconButton)`
  && {
    color: ${({ theme }) => theme.colors.neutral[50]};
  }
`;

const StepperContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 43px 0px 0px 26px;
  margin-bottom: 40px;
`;

const StyledStepper = styled(Stepper)``;

export {
  MainContainer,
  NavigationContainer,
  LeftContainer,
  StyledIcon,
  StyledIconButton,
  StepperContainer,
  StyledStepper,
};
