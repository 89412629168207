import { nameInitials } from '@Utils/nameInitials';

import { IGenericProfilePicture } from './types';

import {
  Container,
  ImageWrapper,
  NameWrapper,
  NameInitials,
  ProfilePictureHover,
  EditCircle,
  EditButtonsWrapper,
} from './styled';
import { Icons } from '@flash-tecnologia/hros-web-ui-v2';

export const GenericProfilePicture = ({
  name,
  size,
  source,
  enableEdit,
  editCallback,
  deleteCallback,
}: IGenericProfilePicture) => {
  return (
    <Container>
      {enableEdit && (
        <ProfilePictureHover size={size}>
          <EditButtonsWrapper>
            <EditCircle size={size * (1 / 3)} onClick={editCallback}>
              <Icons name="IconPencil" fill="transparent" size={size * 0.25} />
            </EditCircle>
            {!!source && (
              <EditCircle size={size * (1 / 3)} onClick={deleteCallback}>
                <Icons name="IconTrash" fill="transparent" size={size * 0.25} />
              </EditCircle>
            )}
          </EditButtonsWrapper>
        </ProfilePictureHover>
      )}
      {!!source ? (
        <ImageWrapper size={size}>
          <img src={source} alt="profile" />
        </ImageWrapper>
      ) : (
        <NameWrapper size={size}>
          <NameInitials size={size}>
            {!!name ? nameInitials(name) : ''}
          </NameInitials>
        </NameWrapper>
      )}
    </Container>
  );
};
