import dispatchToast from '@Utils/dispatchToast';
import { trpc } from '@/api/client';

export const useSectionsManager = () => {
  const utils = trpc.useContext();
  const successToast = () => {
    dispatchToast({
      type: 'success',
      content: 'Edição salva com sucesso!',
    });
  };

  const errorToast = () => {
    dispatchToast({
      type: 'error',
      content: 'Edições não salvas',
      description:
        'Não conseguimos salvar suas edições. Por favor, tente novamente.',
    });
  };

  const {
    mutate: updateEmployeeBasicData,
    isLoading: updateEmployeeBasicDataLoading,
  } = trpc.updateEmployeeBasicData.useMutation({
    onSuccess: () => {
      utils.getEmployeeProfile.invalidate();
      successToast();
    },
    onError: errorToast,
  });

  const {
    mutate: updateEmployeeBankingData,
    isLoading: updateEmployeeBankingDataLoading,
  } = trpc.updateEmployeeBankingData.useMutation({
    onSuccess: successToast,
    onError: errorToast,
  });

  const {
    mutate: updateEmployeeAddressData,
    isLoading: updateEmployeeAddressDataLoading,
  } = trpc.updateEmployeeAddressData.useMutation({
    onSuccess: successToast,
    onError: errorToast,
  });

  const {
    mutate: updateEmployeeCustomFields,
    isLoading: updateEmployeeCustomFieldsLoading,
  } = trpc.updateEmployeeCustomFields.useMutation({
    onSuccess: successToast,
    onError: errorToast,
  });

  const {
    mutate: addEmployeeDependent,
    isLoading: addEmployeeDependentLoading,
  } = trpc.addEmployeeDependent.useMutation({
    onSuccess: successToast,
    onError: errorToast,
  });

  const {
    mutate: deleteEmployeeDependent,
    isLoading: deleteEmployeeDependentLoading,
  } = trpc.deleteEmployeeDependent.useMutation({
    onSuccess: () => {
      utils.getEmployeeProfile.invalidate();
      successToast();
    },
    onError: errorToast,
  });

  const {
    mutate: updateEmployeeDependent,
    isLoading: updateEmployeeDependentLoading,
  } = trpc.updateEmployeeDependent.useMutation({
    onSuccess: successToast,
    onError: errorToast,
  });

  const {
    mutate: updateEmployeeComplementaryData,
    isLoading: updateEmployeeComplementaryDataLoading,
  } = trpc.updateEmployeeComplementaryData.useMutation({
    onSuccess: successToast,
    onError: errorToast,
  });

  return {
    api: {
      updateEmployeeBasicData,
      updateEmployeeBankingData,
      updateEmployeeAddressData,
      updateEmployeeCustomFields,
      updateEmployeeComplementaryData,
      addEmployeeDependent,
      updateEmployeeDependent,
      deleteEmployeeDependent,
    },
    loading:
      updateEmployeeBasicDataLoading ||
      updateEmployeeBankingDataLoading ||
      updateEmployeeAddressDataLoading ||
      updateEmployeeCustomFieldsLoading ||
      addEmployeeDependentLoading ||
      deleteEmployeeDependentLoading ||
      updateEmployeeComplementaryDataLoading ||
      updateEmployeeDependentLoading,
  };
};
