import { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { START_IMPORT } from '@/api';
import dispatchToast from '@utils/dispatchToast';

export const useStartImport = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [startImport] = useMutation(START_IMPORT, {
    onCompleted: async () => {
      setIsLoading(false);
    },
    onError: () => {
      dispatchToast({
        type: 'error',
        content: 'Erro ao importar sua planilha',
      });
      setIsLoading(false);
    },
  });

  const startProccess = async (importId: string) => {
    setIsLoading(true);
    await startImport({ variables: { importId } });
  };

  return {
    isLoading,
    startProccess,
  };
};
