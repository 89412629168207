import { SecondaryTabCard } from '@Components/SecondaryTabCard';
import { TabListInterface } from '../../types';
import { TabListContainer } from './styled';

export const TabList = ({
  tabs,
  selectedTab,
  setSelectedTab,
}: TabListInterface) => {
  return (
    <TabListContainer>
      {tabs?.map(({ key, title }) => {
        const isSelected = key === selectedTab.key;

        return (
          <SecondaryTabCard
            key={key}
            id={key}
            title={title}
            isSelected={isSelected}
            setSelectedSecondaryTab={(key) =>
              setSelectedTab(tabs.find((tab) => tab.key === key)!)
            }
          />
        );
      })}
    </TabListContainer>
  );
};
