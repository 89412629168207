import { StyledText, StyledTitle } from 'src/common/styles/general-styles';
import { worksheetComponentInfo } from '../../../../componentsText';
import { ImportType } from '@/pages/PageEmployeesWorksheet/types';
import { Card, ConfigContainer, RadioContainer, RadioText } from './styled';
import { Radio } from '@flash-tecnologia/hros-web-ui-v2';

interface IImportType {
  operation: ImportType;
  formik: any;
}
export const ChooseImportType = ({ operation, formik }: IImportType) => {
  return (
    <Card data-onboarding="employee-management-import-type">
      <StyledTitle
        setColor="neutral30"
        variant="headline8"
        children={
          worksheetComponentInfo[operation].uploadFile.chooseImportType.title
        }
      />
      <StyledText
        setColor="neutral50"
        variant="body3"
        children={
          worksheetComponentInfo[operation].uploadFile.chooseImportType
            .description
        }
      />
      <ConfigContainer>
        <RadioContainer>
          <Radio
            size="small"
            name="simpleImport"
            checked={formik.values.simpleImport}
            value={formik.values.simpleImport}
            onChange={() => {
              formik.setFieldValue('simpleImport', true);
              formik.setFieldValue('completeImport', false);
            }}
          />
          <RadioText>
            <StyledText
              setColor={
                Boolean(formik.errors.simpleImport) ? 'feedback50' : 'neutral30'
              }
              variant="body3"
              children={
                worksheetComponentInfo[operation].uploadFile.chooseImportType
                  .simpleImport
              }
              style={{ fontWeight: '600' }}
            />

            <StyledText
              setColor={
                Boolean(formik.errors.simpleImport) ? 'feedback50' : 'neutral50'
              }
              variant="body4"
              children={
                worksheetComponentInfo[operation].uploadFile.chooseImportType
                  .simpleImportDescription
              }
              style={{ fontWeight: '400' }}
            />
          </RadioText>
        </RadioContainer>
        <RadioContainer>
          <Radio
            size="small"
            name="completeImport"
            checked={formik.values.completeImport}
            value={formik.values.completeImport}
            onChange={() => {
              formik.setFieldValue('simpleImport', false);
              formik.setFieldValue('completeImport', true);
            }}
          />
          <RadioText>
            <StyledText
              setColor={
                Boolean(formik.errors.completeImport)
                  ? 'feedback50'
                  : 'neutral30'
              }
              variant="body3"
              children={
                worksheetComponentInfo[operation].uploadFile.chooseImportType
                  .completeImport
              }
              style={{ fontWeight: '600' }}
            />

            <StyledText
              setColor={
                Boolean(formik.errors.completeImport)
                  ? 'feedback50'
                  : 'neutral50'
              }
              variant="body4"
              children={
                worksheetComponentInfo[operation].uploadFile.chooseImportType
                  .completeImportDescription
              }
              style={{ fontWeight: '400' }}
            />
          </RadioText>
        </RadioContainer>
      </ConfigContainer>
    </Card>
  );
};
