import { Button } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Option = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const AddColabButton = styled(Button)`
  height: 64px;
`;
