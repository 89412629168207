import gql from 'graphql-tag';

export const RESCHEDULE_MASS_INVITATIONS = gql`
  mutation RescheduleMassInvitations(
    $companyId: ID!
    $rescheduleDate: DateTime!
  ) {
    rescheduleMassInvitationsCompany(
      companyId: $companyId
      rescheduleDate: $rescheduleDate
    )
  }
`;
