import styled from 'styled-components';
import {
  Button,
  DatePicker,
  Icons,
  LinkButton,
  Modal,
  Radio,
  TimePicker,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 44px 22px 40px 112px;

  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[90]};

  @media screen and (max-width: 550px) {
    padding: 44px 22px 40px 40px;
  }
`;
const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 535px;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 112px;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[90]};

  @media screen and (max-width: 550px) {
    padding: 40px;
  }
  @media screen and (max-width: 400px) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 20px 40px;
  }
`;
const ContentContainer = styled.div``;
const RadiosContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 35px;
  padding-top: 19px;
`;
const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const StyledModal = styled(Modal)`
  && {
    &.modal-custom-theme .modal-container {
      width: 100%;
      max-width: 870px;
    }
    &.modal-custom-theme .modal-content-area {
      padding: 40px 112px;

      @media screen and (max-width: 550px) {
        padding: 40px;
      }
    }
  }
`;
const StyledRadio = styled(Radio)`
  && {
    margin: -9px;
  }
`;
const StyledText = styled(Typography)<{ setColor: string }>`
  && {
    color: ${({ setColor, theme }) => {
      switch (setColor) {
        case 'neutral50':
          return theme.colors.neutral[50];
        case 'neutral30':
          return theme.colors.neutral[30];
      }
    }};
  }
`;
const StyledTitle = styled(Typography)<{ setColor: string }>`
  && {
    color: ${({ setColor, theme }) => {
      switch (setColor) {
        case 'neutral10':
          return theme.colors.neutral[10];
        case 'neutral30':
          return theme.colors.neutral[30];
      }
    }};
    font-weight: 700;
  }
`;
const StyledButton = styled(Button)`
  && {
    width: 229px;
  }
`;
const StyledLinkButton = styled(LinkButton)``;

const DateContainer = styled.div`
  display: flex;
  justify-content: start;
  gap: 20px;
  margin-top: 30px;

  @media screen and (max-width: 650px) {
    flex-direction: column;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  && {
    &.date-picker-container {
      width: 250px;
    }
  }
`;
const StyledTimePicker = styled(TimePicker)`
  width: 100%;
  max-width: 250px;
`;

export {
  StyledModal,
  HeaderContainer,
  FooterContainer,
  ContentContainer,
  RadiosContainer,
  StyledText,
  StyledTitle,
  FlexContainer,
  StyledButton,
  StyledLinkButton,
  ColumnContainer,
  StyledRadio,
  DateContainer,
  StyledDatePicker,
  StyledTimePicker,
};
