import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Breadcrumbs,
  LinkButton,
  PageContainer,
} from '@flash-tecnologia/hros-web-ui-v2';

import { StyledTitle } from 'src/common/styles/general-styles';

import {
  LeftContainer,
  StyledIcon,
  MainContainer,
  StyledIconButton,
  StepperContainer,
  StyledStepper,
  NavigationContainer,
} from './styled';
import { worksheetComponentInfo } from '../../componentsText';
import { ImportType } from '../../types';

interface HeaderProps {
  handleStep: number;
  operation: ImportType;
}

export const Header = ({ handleStep, operation }: HeaderProps) => {
  const navigate = useNavigate();
  const refToTop = useRef<HTMLInputElement>(null);

  useEffect(() => {
    refToTop.current?.scrollIntoView({ behavior: 'smooth' });
  });

  return (
    <>
      <MainContainer ref={refToTop}>
        <LeftContainer>
          <NavigationContainer>
            <StyledIconButton
              variant="line"
              size="small"
              icon="IconArrowLeft"
              onClick={() => navigate(-1)}
            />

            <Breadcrumbs
              separator={<StyledIcon name="IconChevronRight" fill="none" />}
              breadcrumbs={[
                <LinkButton
                  variant="secondary"
                  onClick={() => navigate('/employees')}
                >
                  {worksheetComponentInfo[operation].header.breadCrumbs.first}
                </LinkButton>,
                <LinkButton variant="secondary">
                  {worksheetComponentInfo[operation].header.breadCrumbs.second}
                </LinkButton>,
              ]}
            />
          </NavigationContainer>
          <StyledStepper
            steps={worksheetComponentInfo[operation].header.stepper}
            style={{ overflow: 'auto' }}
            activeStep={handleStep}
          />
        </LeftContainer>
        {/* <StyledIcon name="IconHelp" fill="none" /> */}
      </MainContainer>
      <PageContainer>
        <StepperContainer>
          <StyledTitle
            variant="headline6"
            children={worksheetComponentInfo[operation].header.title}
          />
        </StepperContainer>
      </PageContainer>
    </>
  );
};
