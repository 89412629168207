import { useQuery } from '@apollo/client';
import dispatchToast from '../../../utils/dispatchToast';
import { GET_EMPLOYEE_PERMISSIONS } from 'src/api';
import { useSession } from '@/common/user';

export const useGetEmployeePermissions = (receivedEmployeeId: string) => {
  const { userId } = useSession();
  const employeeId = receivedEmployeeId ? receivedEmployeeId : userId;
  const { data, loading } = useQuery(GET_EMPLOYEE_PERMISSIONS, {
    variables: { employeeId },
    onError: () => {
      dispatchToast({
        type: 'error',
        content: 'Ocorreu um erro ao buscar dados do colaborador.',
      });
    },
    fetchPolicy: 'cache-first',
  });
  return {
    loading,
    isAdmin: data?.getEmployeePermissions?.isAdmin,
    companies: data?.getEmployeePermissions?.companies,
  };
};
