export enum EmployeesWorksheetSteps {
  uploadFile = 'upload-file',
  importStatus = 'process-status',
  permissions = 'permissions',
  dataAssociation = 'data-association',
  textFileAttributes = 'text-file-attributes',
  excelFileAttributes = 'excel-file-attributes',
}

export enum ImportType {
  insert = 'insert',
  update = 'update',
}

export enum ImportStatus {
  WAITING = 'WAITING',
  PROCESSING = 'PROCESSING',
  ERRORED = 'ERRORED',
  SUCCESS = 'SUCCESS',
  SUCCESS_WITH_ERRORS = 'SUCCESS_WITH_ERRORS',
  ABORTED = 'ABORTED',
}

export type ImportSheetPayload = {
  companyId: string;
  operation?: string;
  employeeId: string;
  isDefaultConfig: boolean;
  fileType: string;
  hasHeader: boolean;
  separator: string;
  cellColumn: string;
  cellLine: number;
  fileUrlKey: string;
  scheduleInvite: boolean;
  scheduleDate?: Date;
  nameField: string;
  documentField: string;
  phoneField: string;
  emailField: string;
  registrationNumberField: string;
  numberOfRows: number;
};

export type CancelWorksheetPayload = {
  processmentId: string;
};
