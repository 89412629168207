import { Navigate, useParams } from 'react-router-dom';

/**
 * @deprecated This function will be removed in future versions.
 */
export const EmployeeRedirect = () => {
  const { id } = useParams();

  return <Navigate to={`/employees/${id}/profile`} />;
};
