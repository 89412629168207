import { useQuery } from '@apollo/client';
import dispatchToast from '@Utils/dispatchToast';
import { StatusEnum } from '../../../common/types/StatusEnum';
import {
  GET_PERSON_MANAGE_ADITIONAL_DATA,
  GetEmployeesV2Input,
  GetPersonManageAditionalDataResponse,
} from '../../../api';
import { ErrorBoundary } from '@Utils/ErrorBoundary';

export const useFetchCompanyEmployees = (companyId: string) => {
  const { data: aditionalData, loading: isEmployeesLoading } = useQuery<
    GetPersonManageAditionalDataResponse,
    GetEmployeesV2Input & { companyId: string }
  >(GET_PERSON_MANAGE_ADITIONAL_DATA, {
    variables: {
      companyId,
      input: {
        companyId,
        sortBy: 'name',
        order: 'asc',
        status: StatusEnum.ACTIVE,
      },
    },
    errorPolicy: 'ignore',
    onError: (error) => {
      ErrorBoundary.captureException(error);
      dispatchToast({
        type: 'error',
        content: 'Ocorreu um erro ao buscar os dados do colaborador',
      });
    },
  });

  return {
    companyEmployees: aditionalData?.employees.records,
    loading: isEmployeesLoading,
  };
};
