import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { PermissionProfilesType } from '../../types';

import { HeaderButtons } from './components/HeaderButtons';

import { HeaderContainer, Subtitle, HeaderText } from './styled';

export const Header = ({ headerMenuDisabled }: PermissionProfilesType) => {
  return (
    <HeaderContainer>
      <HeaderText>
        <Typography variant="headline6">Equipe</Typography>
        <Subtitle>
          Gerencie os dados de cadastro da sua equipe, envie convites de acesso
          ao app e plataforma da Flash para colaboradores, administradores e
          muito mais.
        </Subtitle>
      </HeaderText>
      <HeaderButtons menuDisabled={headerMenuDisabled} />
    </HeaderContainer>
  );
};
