import gql from 'graphql-tag';

export const GET_EMPLOYEE_PERMISSIONS = gql`
  query GetEmployeePermissions($employeeId: ID!) {
    getEmployeePermissions(employeeId: $employeeId) {
      isAdmin
      companies {
        _id
        permissions
      }
    }
  }
`;
