interface ObjectLiteral {
  [key: string]: any;
}

export const getObjDiffs = (
  newObj: ObjectLiteral,
  objToCompare: ObjectLiteral,
) => {
  let diff: ObjectLiteral = {};

  Object.keys(objToCompare).forEach((key) => {
    if (newObj.hasOwnProperty(key) && objToCompare[key] != newObj[key]) {
      diff[key] = newObj[key];
    }
  });

  return diff;
};
