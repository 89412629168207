import { CREATE_EMPLOYEE } from '@/api';
import { useMutation } from '@apollo/react-hooks';
import { ErrorBoundary } from '@utils/ErrorBoundary';
import dispatchToast from '@utils/dispatchToast';

type UseCreateEmployeeProps = {
  options?: {
    onCompleted?: (data: any) => void;
    onError?: () => void;
  };
};

export const useCreateEmployee = ({ options }: UseCreateEmployeeProps) => {
  const [createEmployee, { loading, data }] = useMutation(CREATE_EMPLOYEE, {
    onCompleted: (data) => {
      if (options?.onCompleted) {
        options.onCompleted(data);
      }
    },
    onError: (error) => {
      const graphqlErrors: any = error?.graphQLErrors?.[0];
      const errorCode = graphqlErrors?.errorCode as string;
      const message = graphqlErrors?.message as string;
      if (
        errorCode === 'EMPLOYEE_WITH_DOCUMENT_ALREADY_EXISTS_ERROR' ||
        errorCode === 'EMPLOYEE_WITH_CONTACT_ALREADY_EXISTS_ERROR'
      ) {
        dispatchToast({
          type: 'error',
          content: message,
        });
      } else {
        ErrorBoundary.captureException(error);
        dispatchToast({
          type: 'error',
          content:
            'Não conseguimos criar essa pessoa, tente novamente mais tarde.',
        });
      }

      if (options?.onError) {
        options.onError();
      }
    },
    refetchQueries: ['GetEmployees'],
    awaitRefetchQueries: true,
  });

  return { createEmployee, loading, data };
};
