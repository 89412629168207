import { Button, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  height: 400px;
  background: var(--color-neutral-95);
  border: 1px solid var(--color-neutral-90);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
`;

export const Description = styled(Typography)`
  color: var(--color-neutral-30);
`;

export const StyledButton = styled(Button)`
  margin: 0 auto;
`;
