import styled from 'styled-components';

export const HeaderContainer = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  @media screen and (min-width: 768px) {
    gap: 0;
    flex-direction: row;
  }
`;

export const HeaderText = styled.div`
  flex-direction: column;
  justify-content: flex-start;
  max-width: 60%;
`;

export const Subtitle = styled.span`
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: var(--color-neutral-50);
`;
