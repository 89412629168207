import styled from 'styled-components';

export const Option = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 280px;
  border-radius: 4px;
  gap: 8px;
`;
