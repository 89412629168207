import styled from 'styled-components';
import {
  Modal,
  Typography,
  TextField,
  Icons,
} from '@flash-tecnologia/hros-web-ui-v2';

export const StyledModal = styled(Modal)`
  && {
    .modal-content-area {
      padding: 24px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 430px;
  text-align: center;
  margin: 20px 48px 20px 88px;
`;

export const Title = styled.span`
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;

  color: var(--color-neutral-dark1);
`;

export const SuccessWrapper = styled.div`
  margin: 0 auto;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-secondary-95);
  border-radius: 50%;
`;

export const AlterIcon = styled(Icons)`
  && {
    color: var(--color-primary);
  }
`;
