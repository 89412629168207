import { TabContentType } from '../../types';
import { TabContentContainer, StyledAccordion } from './styled';

export const TabContent = ({ sections }: TabContentType) => {
  return (
    <TabContentContainer>
      {sections.map((section) => {
        return (
          <StyledAccordion
            variant="default"
            key={section.title}
            title={section.title}
            description={section.description}
            actions={
              section.editable
                ? [{ icon: 'IconPencil', onClick: () => {} }]
                : []
            }
          >
            <div>hello</div>
          </StyledAccordion>
        );
      })}
    </TabContentContainer>
  );
};
