import { Icons } from '@flash-tecnologia/hros-web-ui-v2';
import {
  Button,
  CopyIconWrapper,
  LabelWrapper,
  LinkIconWrapper,
} from './styled';
import { CopyLinkInput } from './types';
import { useState } from 'react';

export const CopyLink = ({ link }: CopyLinkInput) => {
  const [wasCopied, setWasCopied] = useState(false);

  const copyToClipBoard = (text: string) => {
    navigator.clipboard.writeText(text);
    setWasCopied(true);
  };

  return (
    <Button
      type="submit"
      onClick={() => copyToClipBoard(link)}
      $wasCopied={wasCopied}
    >
      <LabelWrapper>
        <LinkIconWrapper $wasCopied={wasCopied}>
          <Icons name="IconLink" fill="transparent" size={16} />
        </LinkIconWrapper>
        {wasCopied ? 'Link de convite copiado' : 'Copiar link de convite'}
      </LabelWrapper>
      <CopyIconWrapper $wasCopied={wasCopied}>
        <Icons
          name={wasCopied ? 'IconCheck' : 'IconCopy'}
          fill="transparent"
          size={16}
        />
      </CopyIconWrapper>
    </Button>
  );
};
