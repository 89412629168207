import { GET_PAGE_TABLE_EMPLOYEES, RESCHEDULE_MASS_INVITATIONS } from '@/api';
import { useSession } from '@/common/user';
import { useMutation } from '@apollo/client';
import { ErrorBoundary } from '@utils/ErrorBoundary';
import dispatchToast from '@utils/dispatchToast';

export const useRescheduleMassInvitations = () => {
  const { companyId } = useSession();

  const [reascheduleInviteMutation, { loading }] = useMutation<
    null,
    { companyId: string; rescheduleDate: Date }
  >(RESCHEDULE_MASS_INVITATIONS, {
    onCompleted: async () => {
      dispatchToast({
        type: 'success',
        content: 'Reagendamento em massa realizado com sucesso!',
      });
    },
    onError: (error) => {
      ErrorBoundary.captureException(error);
      dispatchToast({
        type: 'error',
        content:
          'Ocorreu um erro ao realizar o reagendamento em massa, por favor tente novamente mais tarde.',
      });
    },
    refetchQueries: [GET_PAGE_TABLE_EMPLOYEES],
  });

  const rescheduleMassInvitations = async (rescheduleDate: Date) => {
    await reascheduleInviteMutation({
      variables: {
        companyId,
        rescheduleDate,
      },
    });
  };

  return { rescheduleMassInvitations, loading };
};
