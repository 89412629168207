type EType = 'error' | 'success' | 'warning';

interface dispatchToastParams {
  description?: string;
  content: string;
  type: EType;
  title?: string;
}

export default function dispatchToast(detail: dispatchToastParams) {
  dispatchEvent(new CustomEvent('showToast', { detail }));
}
