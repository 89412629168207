import { CreateInvite } from '@Components/CreateInvite';
import { CustomFieldsContainer } from './styled';

import { CustomFieldsInterface } from './types';

export const CustomFields = ({
  setCustomFields,
  createInviteProps,
}: CustomFieldsInterface) => {
  return (
    <CustomFieldsContainer>
      <CreateInvite
        {...createInviteProps}
        title="Defina quando o convite será enviado"
        subtitle="Escolha em que momento a pessoa receberá o convite para criar sua conta."
        setInviteMode={(scheduleInvite) =>
          setCustomFields((prev: any) => {
            return {
              ...prev,
              scheduleInvite: scheduleInvite,
            };
          })
        }
        setDate={(date) =>
          setCustomFields((prev: any) => {
            return {
              ...prev,
              inviteDate: date,
            };
          })
        }
        setTime={(time) =>
          setCustomFields((prev: any) => {
            return {
              ...prev,
              inviteTime: time,
            };
          })
        }
      />
    </CustomFieldsContainer>
  );
};
