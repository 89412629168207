import { CUSTOM_ACCESS_MENU_QUERY } from '@/api/queries/custom-access-menu-query';
import { useSession } from '@/common/user';
import { useQuery } from '@apollo/client';
import { ErrorBoundary } from '@utils/ErrorBoundary';
import { useEffect } from 'react';

export const useFetchCompanySpecialRedemptionCode = () => {
  const { companyId } = useSession();

  const { data, loading, refetch } = useQuery<
    { company: { specialRedemptionCode: string } },
    { companyId: string }
  >(CUSTOM_ACCESS_MENU_QUERY, {
    variables: { companyId },
    errorPolicy: 'ignore',
    onError: (error) => {
      ErrorBoundary.captureException(error);
    },
  });

  useEffect(() => {
    refetch();
  }, [companyId, refetch]);

  return {
    specialRedemptionCode: data?.company?.specialRedemptionCode,
    loading,
  };
};
