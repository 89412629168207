import { useCallback, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import dispatchToast from '../../../utils/dispatchToast';
import { FilterStatusEnum, StatusEnum } from '../../../common/types/StatusEnum';
import {
  GET_PAGE_TABLE_EMPLOYEES,
  GetEmployeesV2Input,
  GetPageTableEmployeesResponse,
} from '../../../api';

export const useFetchEmployees = () => {
  const [fetch, { loading, data, previousData }] = useLazyQuery<
    GetPageTableEmployeesResponse,
    GetEmployeesV2Input
  >(GET_PAGE_TABLE_EMPLOYEES, {
    onError: () => {
      dispatchToast({
        type: 'error',
        content: 'Ocorreu um erro ao buscar os colaboradores.',
      });
    },
    fetchPolicy: 'cache-first',
  });

  const fetchEmployees = useCallback(
    (params: {
      companyId: string;
      pageNumber: number;
      pageSize: number;
      status?: StatusEnum | FilterStatusEnum;
      search: string;
    }) => {
      fetch({
        variables: {
          input: {
            companyId: params.companyId,
            page: params.pageNumber,
            limit: params.pageSize,
            sortBy: 'name',
            order: 'asc',
            ...(params.status && { status: params.status }),
            ...(params.search && { query: params.search }),
          },
        },
      });
    },
    [],
  );

  return {
    fetchEmployees,
    employees: data?.employees?.records || previousData?.employees?.records,
    recordsMetadata:
      data?.employees?.recordsMetadata ||
      previousData?.employees?.recordsMetadata,
    loading,
  };
};
