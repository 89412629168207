import styled from 'styled-components';
import { Dropzone } from '@flash-tecnologia/hros-web-ui-v2';

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 0 26px 80px 26px;

  @media screen and (max-width: 1050px) {
    flex-direction: column;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  max-width: 311px;
  @media screen and (max-width: 420px) {
    max-width: 100%;
  }
`;

const RightColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const Card = styled.div`
  width: 100%;
  max-width: 981px;

  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  padding: 40px;
  border-radius: 10px;

  @media screen and (max-width: 420px) {
    flex-direction: column;
    padding: 40px 15px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
`;

const ContentContainer = styled.div`
  display: flex;

  gap: 24px;
`;

const StyledDropZone = styled(Dropzone)`
  && {
    &.dropzone-main-container .dropzone-file-item {
      @media screen and (max-width: 420px) {
        padding: 20px 10px;
        width: 100%;
      }
      &.dropzone-file-item .dropzone-file-item-image {
        width: 100%;
        max-width: 50px;
      }
    }
  }
`;

export {
  MainContainer,
  LeftColumn,
  RightColumn,
  Card,
  TextContainer,
  ContentContainer,
  StyledDropZone,
};
