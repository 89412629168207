import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
  margin: 44px 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

export const NameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

export const TextsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const DetailTextsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

export const DetailReportsTo = styled.span`
  color: var(--color-primary);
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

export const Name = styled(Typography)``;

export const Option = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const DetailText = styled(Typography)`
  color: var(--color-neutral-40);
`;
