import styled from 'styled-components';

export const TabListContainer = styled.div`
  gap: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;
