import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled(Typography)`
  color: var(--color-neutral-20);
  margin-bottom: 4px;
`;

export const Subtitle = styled(Typography)`
  color: var(--color-neutral-50);
`;
