import { useMutation } from '@apollo/client';
import { GET_PERSON_MANAGE_EMPLOYEE, SCHEDULE_INVITE } from 'src/api';
import dispatchToast from '@Utils/dispatchToast';
import { ErrorBoundary } from '@Utils/ErrorBoundary';

export const useScheduleInvite = () => {
  const [scheduleInvite, { loading }] = useMutation<
    void,
    { input: { employeeId: string; newDate: Date } }
  >(SCHEDULE_INVITE, {
    onCompleted: () => {
      dispatchToast({
        type: 'success',
        content: 'Agendamento editado com sucesso!',
      });
    },
    onError: (error) => {
      ErrorBoundary.captureException(error);
      dispatchToast({
        type: 'error',
        content: 'Erro ao editar agendamento',
        description:
          'Nós não conseguimos editar este agendamento, tente novamente mais tarde.',
      });
    },
    refetchQueries: [GET_PERSON_MANAGE_EMPLOYEE],
    awaitRefetchQueries: true,
  });

  return {
    scheduleInvite,
    loading,
  };
};
