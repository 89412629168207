import styled from 'styled-components';
import {
  Icons,
  LinkButton,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { ImportStatus } from '../../types';

const MainContainer = styled.div<{ setColor: string }>`
  display: flex;
  justify-content: space-between;

  padding: 24px;
  gap: 20px;
  margin-top: 40px;
  width: 100%;
  border: 1px solid
    ${({ setColor, theme }) => {
      switch (setColor) {
        case ImportStatus.ERRORED:
        case ImportStatus.SUCCESS_WITH_ERRORS:
          return theme.colors.feedback.error[70];
        case ImportStatus.WAITING:
        case ImportStatus.PROCESSING:
          return theme.colors.feedback.info[70];
        case ImportStatus.SUCCESS:
          return theme.colors.feedback.success[70];
      }
    }};
  border-radius: 8px;
`;

const IconContainer = styled.div<{ setColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;

  width: 48px;
  min-width: 48px;
  height: 48px;

  background: ${({ setColor, theme }) => {
    switch (setColor) {
      case 'success with errors':
      case 'structural error':
        return theme.colors.feedback.error[90];
      case 'queued':
      case 'processing':
        return theme.colors.feedback.info[90];
      case 'success':
        return theme.colors.feedback.success[90];
    }
  }};
  border-radius: 200px;
`;

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  align-items: center;

  gap: 30px;
`;

const TextContainer = styled.div`
  max-width: 600px;
`;

const ButtonsArea = styled.div`
  display: flex;
  gap: 15px;
  padding-top: 6px;
`;

const StyledIcon = styled(Icons)<{ setColor: string }>`
  color: ${({ setColor, theme }) => {
    switch (setColor) {
      case 'success with errors':
      case 'structural error':
        return theme.colors.feedback.error[50];
      case 'queued':
      case 'processing':
        return theme.colors.feedback.info[70];
      case 'success':
        return theme.colors.feedback.success[50];
      case 'error40':
        return theme.colors.feedback.error[40];
    }
  }};
`;

const StyledText = styled(Typography)<{ setColor?: string }>`
  && {
    color: ${({ setColor, theme }) => {
      switch (setColor) {
        case 'error10':
          return theme.colors.feedback.error[10];
        case 'queued':
        case 'processing':
          return theme.colors.feedback.info[70];
        case 'success':
          return theme.colors.feedback.success[50];
        case 'error40':
          return theme.colors.feedback.error[40];
      }
    }};
  }
`;

const StyledTitle = styled(Typography)<{ setColor?: string }>`
  && {
    font-weight: 600;
    color: ${({ setColor, theme }) => {
      switch (setColor) {
        case 'error10':
          return theme.colors.feedback.error[10];
        case 'queued':
        case 'processing':
          return theme.colors.feedback.info[70];
        case 'success':
          return theme.colors.feedback.success[50];
        case 'error40':
          return theme.colors.feedback.error[40];
      }
    }};
  }
`;

const StyledLinkButton = styled(LinkButton)``;

export {
  MainContainer,
  IconContainer,
  StyledIcon,
  TextContainer,
  ButtonsArea,
  StyledText,
  StyledTitle,
  StyledLinkButton,
  Container,
};
