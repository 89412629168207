import { MultiSelect, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const CustomFieldsContainer = styled.div`
  margin-top: 24px;
`;

export const StyledMultiSelect = styled(MultiSelect)`
  margin-bottom: 24px;

  .multi-select-custom-theme label {
    padding: 0;
    word-wrap: break-word;
    white-space: break-spaces;
  }

  .multi-select-custom-theme div.MuiFilledInput-root {
    padding: 29px 39px 16px 0;
  }

  .multi-select-custom-theme div.MuiFilledInput-root .MuiFilledInput-input {
    padding: 0;
    height: 0;
  }
`;
