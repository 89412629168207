import styled from 'styled-components';
import {
  Accordion,
  Dropzone,
  Icons,
  Tag,
} from '@flash-tecnologia/hros-web-ui-v2';
import { AccordionSummary } from '@mui/material';

export const Card = styled.div`
  width: 100%;
  max-width: 981px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  padding: 40px;
  border-radius: 10px;

  @media screen and (max-width: 420px) {
    flex-direction: column;
    padding: 40px 15px;
  }
`;

export const FileTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-top: 16px;
  gap: 40px;

  @media screen and (max-width: 511px) {
    flex-direction: column;
    gap: 0px;
  }
`;

export const InstructionsContainer = styled.div`
  padding-top: 40px;
`;

export const RadioContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  padding: 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const RadioText = styled.div`
  display: flex;
  align-items: center;
`;

export const DownloadContainer = styled.div`
  display: flex;
  gap: 15px;

  @media screen and (max-width: 845px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ContainerDonwloadButton = styled.div`
  .download-button-container {
    display: flex;
    align-items: center;
    padding: 16px;
    max-width: 263px;
  }
  .download-button-container .text-container {
  }

  .download-button-container .title-text {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }
  .download-button-container .subtitle-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  .download-button-container .icon-container {
    padding-bottom: 1px;
  }
`;

export const StyledDropZone = styled(Dropzone)`
  && {
    &.dropzone-main-container .dropzone-file-item {
      @media screen and (max-width: 420px) {
        padding: 20px 10px;
        width: 100%;
      }
      &.dropzone-file-item .dropzone-file-item-image {
        width: 100%;
        max-width: 50px;
      }
    }
  }
`;
export const StyledAccordion = styled(Accordion)``;

export const StyledAccordionHeader = styled(AccordionSummary)`
  display: flex;
  flex-direction: column;
`;

export const LeftIconContainer = styled.div`
  background-color: ${(props) => props.theme.colors.secondary[95]};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 10px;
  height: 48px;
  width: 48px;
  border-radius: ${(props) => props.theme.borders.radius.circular};
`;

export const HeaderIcon = styled(Icons)`
  color: ${(props) => props.theme.colors.secondary[50]};
`;

export const AccordionItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 8px;
`;

export const AccordionItemHeader = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 8px;
`;

export const AccordionItemList = styled.ul`
  padding-left: 20px;
`;

export const HeaderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const StyledTag = styled(Tag)`
  padding: 4px 8px 4px 8px;
  gap: 4px;
  font-size: 10px;
  line-height: 12x;
  border-radius: 24px;
  height: 20px;
`;

export const HorizontalLimit = styled.div`
  gap: 4px;
  border: 1px solid ${(props) => props.theme.colors.neutral[90]};
`;
