import { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { CREATE_IMPORT } from '@/api';
import dispatchToast from '@utils/dispatchToast';
import axios from 'axios';
import { useStartImport } from './useStartImport';
import { setInLS } from '@flash-tecnologia/hros-web-utility';

export type CreateImportInput = {
  config: {
    action: string;
    invitationDate?: string;
  };
  fileDictionary: {
    name?: string | number;
    documentNumber: string | number;
    registrationNumber: string | number;
    phone?: string | number;
    email?: string | number;
    role?: string | number;
    department?: string | number;
    group?: string | number;
    managerDocumentNumber?: string | number;
    pis?: string | number;
    startDate?: string | number;
  };
  fileData: File;
  fileName: string;
  fileType: string;
  hasHeader: boolean;
  initialCell: string;
  separator: string;
};

export const useCreateImport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [importId, setImportId] = useState(null);
  const [fileData, setFileData] = useState<File | null>(null);
  const { startProccess } = useStartImport();

  const [createImport] = useMutation(CREATE_IMPORT, {
    onCompleted: async (data) => {
      const { importId, uploadUrl } = data.createImport;
      setInLS({ key: 'importId', value: { importId } });

      const uploadResponse = await axios.put(uploadUrl, fileData, {
        headers: {
          'Content-Type': fileData?.type,
        },
      });
      if (uploadResponse.status === 200) {
        await startProccess(importId);
        setImportId(importId);
        setIsLoading(false);
      } else {
        dispatchToast({
          type: 'error',
          content: 'Erro ao importar sua planilha',
        });
        setIsLoading(false);
      }
    },
    onError: () => {
      dispatchToast({
        type: 'error',
        content: 'Erro ao importar sua planilha',
      });
      setIsLoading(false);
    },
  });

  const createImportSheet = async (payload: CreateImportInput) => {
    setIsLoading(true);

    const { fileData, ...input } = payload;
    setFileData(fileData);
    await createImport({
      variables: {
        input: input,
      },
    });
  };

  return {
    isLoading,
    importId,
    createImportSheet,
  };
};
