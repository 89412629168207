import gql from 'graphql-tag';

export const GET_EMPLOYEES_REPORT_NUMBERS = gql`
  query GetEmployeesReportNumbers($companyId: ID!) {
    getEmployeesReportNumbers(companyId: $companyId) {
      ACTIVE
      INVITATION_EXPIRED
      INVITATION_PENDING
      INVITATION_SCHEDULED
      INVITATION_SENT
    }
  }
`;
