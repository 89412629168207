import { DELETE_EMPLOYEE } from '@/api/mutations';
import { useMutation } from '@apollo/client';

export const useDeleteEmployee = ({
  refetchQueries,
  onCompleted,
  onError,
}: {
  refetchQueries?: any[];
  onCompleted?: () => void;
  onError?: (e: Error) => void;
}) => {
  const [mutate, { loading }] = useMutation(DELETE_EMPLOYEE, {
    onCompleted,
    onError,
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const deleteEmployee = ({
    employeeId,
    companyId,
  }: {
    employeeId: string;
    companyId: string;
  }) =>
    mutate({
      variables: {
        employeeId,
        companyId,
      },
    });

  return {
    loading,
    deleteEmployee,
  };
};
