import styled from 'styled-components';
import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  margin-right: 32px;
`;

export const FieldName = styled(Typography)`
  color: var(--color-neutral-50);
`;

export const FieldValueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

export const FieldValue = styled(Typography)`
  color: var(--color-neutral-20);
`;

export const LockIcon = styled(Icons)`
  color: var(--color-neutral-70);
`;
