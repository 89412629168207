import styled from 'styled-components';
import { Button, Icons, LinkButton } from '@flash-tecnologia/hros-web-ui-v2';

const StyledLinked = styled(LinkButton)`
  align-self: center;
  && {
    align-self: center;
  }
`;
const StyledButton = styled(Button)`
  width: 100%;
  max-width: 200px;

  && {
    width: 100%;
    max-width: 200px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 27px;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[90]};

  @media screen and (max-width: 550px) {
    width: 100%;
    gap: 20px;
  }
`;

const StyledIcon = styled(Icons)<{ setColor?: string }>`
  && {
    ${({ setColor, theme }) => {
      let color = '';
      switch (setColor) {
        case 'neutral50':
          color = theme.colors.neutral[50];
          break;
        case 'primary':
          color = theme.colors.primary;
          break;
      }
      return `color: ${color}`;
    }};
  }
`;

const FlexContainer = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
  max-width: 412px;

  @media screen and (max-width: 550px) {
    flex-direction: column-reverse;
    max-width: 200px;
    justify-content: flex-end;
  }
`;

export {
  StyledLinked,
  StyledButton,
  ButtonContainer,
  StyledIcon,
  FlexContainer,
};
