import {
  Accordion,
  Button,
  IconButton,
} from '@flash-tecnologia/hros-web-ui-v2';
import styled, { css } from 'styled-components';

export const SectionsFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

export const FieldsContainer = styled.form<{ limitHeight: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 32px;

  ${({ limitHeight }) =>
    limitHeight &&
    ` 
    max-height: 745px;
    overflow: hidden;
    overflow-y: scroll;
  `}
`;

export const StyledAddButton = styled(Button)`
  width: 100%;
  max-height: 40px;
  margin-bottom: 36px;
`;

export const StyledAccordion = styled(Accordion)`
  &.MuiPaper-root {
    &::before {
      background-color: transparent;
    }
  }
`;

export const IconButtonStyled = styled(IconButton)<{ editing?: boolean }>`
  && {
    ${({ editing }) =>
      editing &&
      css`
        &.MuiButtonBase-root {
          border-color: ${({ theme }) => theme.colors.feedback.success[40]};

          svg {
            stroke: ${({ theme }) => theme.colors.feedback.success[40]};
          }
        }
      `}
  }
`;
