import { env } from '@/lib/env';
import {
  ApolloLink,
  HttpLink,
  ApolloClient,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getAccessToken } from '@flash-tecnologia/hros-web-utility';

export const makeApolloClient = (): any => {
  const httpLink = new HttpLink({
    uri: `${env.EMPLOYEE_URL}/graphql/employee-management`,
  });

  const contextLink = setContext(async (_, { headers }) => {
    const accessToken = await getAccessToken();

    const context = {
      headers: {
        ...headers,
        ...(accessToken && { Authorization: accessToken }),
        ...(accessToken && { 'x-flash-auth': `Bearer ${accessToken}` }),
      },
    };
    return context;
  });

  const authLink = onError(({ graphQLErrors, operation }) => {});

  const client = new ApolloClient({
    link: ApolloLink.from([contextLink, authLink, httpLink]),
    cache: new InMemoryCache({}),
    resolvers: {},
    connectToDevTools: true,
  });

  return client;
};
