import { PageContainer } from '@flash-tecnologia/hros-web-ui-v2';
import { Breadcrumbs } from './components/Breadcrumbs';
import { Header } from './components/Header';
import { TabList } from './components/TabList';
import { useState } from 'react';
import { tabs } from './tabs';
import { TabContent } from './components/TabContent';
import { Content } from './styled';

export const PageAccount = () => {
  const [selectedTab, setSelectedTab] = useState(tabs?.[0]);

  return (
    <PageContainer>
      <Breadcrumbs />
      <Header />
      <Content>
        <TabList
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={(key) => setSelectedTab(key)}
        />
        <TabContent sections={selectedTab.sections} />
      </Content>
    </PageContainer>
  );
};
