import { gql } from '@apollo/react-hooks';

export const GET_EMPLOYEES = gql`
  query GetPageTableEmployees($input: GetEmployeesV2Input) {
    employees(input: $input) {
      records {
        id
        name
        documentNumber
        phoneNumber
        email
        status
        companyId
        groups {
          id
          name
        }
      }
    }
  }
`;
