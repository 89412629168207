import styled from 'styled-components';

export const CompoundFieldContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const EditingCompoundFieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
`;
