import {
  Checkbox,
  IconButton,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';

import {
  AlertWrapper,
  AlterIcon,
  CheckboxContainer,
  ConfirmInput,
  ConfirmWordWrapper,
  Container,
  Header,
  Subtitle,
  Title,
  StyledModal,
  Warning,
  Content,
  FooterContainer,
  FooterCancelButton,
  SubmitButton,
} from './styles';
import { useState, useMemo } from 'react';
import { deactivateModalText } from '@utils/deactivateModalText';

export interface ModalConfirmActionInterface {
  isOpen: boolean;
  loading: boolean;
  isAdmin: boolean;
  companiesWithPermissions: { _id: string; permissions: string[] }[];
  submitText: string;
  confirmWord?: string;
  onClose: () => void;
  onSubmit: () => void;
}

export const DeactivateConfirmationModal = ({
  isOpen,
  isAdmin,
  companiesWithPermissions,
  loading,
  submitText,
  confirmWord,
  onClose,
  onSubmit,
}: ModalConfirmActionInterface) => {
  const word = confirmWord?.toLocaleUpperCase();
  const [inputWord, setInputWord] = useState('');
  const [deactivateTerm, setDeactivateTerm] = useState(false);

  const modalTextContent = useMemo(() => {
    if (isAdmin || companiesWithPermissions?.length > 1)
      return deactivateModalText.admin.multipleCompanies;
    if (companiesWithPermissions?.length)
      return deactivateModalText.admin.singleCompany;
    return deactivateModalText.employee;
  }, [isAdmin, companiesWithPermissions]);

  return (
    <StyledModal
      open={isOpen}
      onClose={onClose}
      footer={
        <FooterContainer>
          <FooterCancelButton onClick={onClose}>Cancelar</FooterCancelButton>
          <SubmitButton
            variant={'primary'}
            variantType={'error'}
            buttonType={'primary'}
            size={'small'}
            style={{ margin: 'auto 0', padding: '19px 52px' }}
            onClick={onSubmit}
            loading={loading}
            disabled={
              modalTextContent?.checkboxText
                ? inputWord !== word || !deactivateTerm
                : false
            }
            disableRipple
            disableTouchRipple
          >
            {submitText}
          </SubmitButton>
        </FooterContainer>
      }
    >
      <Container>
        <Content>
          <Header>
            <AlertWrapper>
              <AlterIcon name="IconAlertCircle" size={24} fill="transparent" />
            </AlertWrapper>
            <Warning variant="body3">Atenção!</Warning>
          </Header>
          <Title>{modalTextContent.title}</Title>
          <Subtitle>{modalTextContent.description}</Subtitle>
          {modalTextContent?.checkboxText && (
            <CheckboxContainer>
              <Checkbox
                checked={deactivateTerm}
                onChange={() => setDeactivateTerm((prev) => !prev)}
              />
              <Typography variant="body3">
                {modalTextContent?.checkboxText}
              </Typography>
            </CheckboxContainer>
          )}

          {confirmWord && (
            <ConfirmWordWrapper>
              <ConfirmInput
                label={`Digite ${word} e confirme`}
                onChange={(e) => setInputWord(e.target.value)}
              />
            </ConfirmWordWrapper>
          )}
        </Content>
        <IconButton
          icon="IconX"
          onClick={onClose}
          variant="line"
          size="small"
        />
      </Container>
    </StyledModal>
  );
};
