import { useSession } from '@/common/user';
import { useState } from 'react';
import { setEventTracking } from '@utils/analytics';

export const useInvitationCompany = () => {
  const { userId: employeeId } = useSession();

  const [resendMassIsOpen, setResendMassIsOpen] = useState(false);

  const onCloseResendModal = () => {
    setEventTracking('backbone_resend_mass_invitations_canceled', {
      employeeId,
    });
    setResendMassIsOpen(false);
  };

  const onOpenResendModal = () => {
    setEventTracking('backbone_resend_mass_invitations_clicked', {
      employeeId,
    });
    setResendMassIsOpen(true);
  };

  return {
    resendMassIsOpen,
    onCloseResendModal,
    onOpenResendModal,
  };
};
