import { useMutation } from '@apollo/client';
import dispatchToast from '@Utils/dispatchToast';
import { SEND_INVITE } from 'src/api/mutations/send-invite';
import { GET_PERSON_MANAGE_EMPLOYEE } from 'src/api';
import { ErrorBoundary } from '@Utils/ErrorBoundary';

export const useSendInvite = (resetTimer: () => void) => {
  const [sendInvite, { loading }] = useMutation<void, { employeeId: string }>(
    SEND_INVITE,
    {
      onCompleted: () => {
        dispatchToast({
          type: 'success',
          content: 'Convite enviado com sucesso',
        });
        resetTimer();
      },
      onError: (error) => {
        ErrorBoundary.captureException(error);
        dispatchToast({
          type: 'error',
          content: 'Erro ao enviar convite',
        });
      },
      refetchQueries: [GET_PERSON_MANAGE_EMPLOYEE],
      awaitRefetchQueries: true,
    },
  );

  return {
    sendInvite,
    loading,
  };
};
