import { Button } from '@flash-tecnologia/hros-web-ui-v2';
import { FooterCancelButton, FooterContainer } from './styled';

interface FooterProps {
  loading?: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

export const Footer = ({ loading, onClose, onConfirm }: FooterProps) => {
  return (
    <FooterContainer>
      <FooterCancelButton onClick={onClose}>Cancelar</FooterCancelButton>
      <Button
        variant="primary"
        size="large"
        type="submit"
        loading={loading}
        onClick={onConfirm}
      >
        Confirmar
      </Button>
    </FooterContainer>
  );
};
