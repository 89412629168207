import gql from 'graphql-tag';

export const CREATE_IMPORT = gql`
  mutation CreateImport($input: CreateImportInput) {
    createImport(input: $input) {
      importId
      uploadUrl
    }
  }
`;
