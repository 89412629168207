import dispatchToast from '../../../utils/dispatchToast';
import { trpc } from '@/api/client';
import { dayjs } from '@flash-tecnologia/hros-web-ui-v2';

export const useGetProfile = (employeeId: string) => {
  const { data, isLoading } = trpc.getEmployeeProfile.useQuery(
    { employeeId },
    {
      cacheTime: 0,
      onSuccess(data) {
        if (data.startDate) {
          dayjs(data.startDate).isValid()
            ? (data.startDate = dayjs(data.startDate).add(3, 'hour').toString())
            : null;
        }
      },
      onError: () => {
        dispatchToast({
          type: 'error',
          content: 'Ocorreu um erro ao buscar seus dados.',
        });
      },
    },
  );
  return {
    loading: isLoading,
    employee: data,
  };
};
