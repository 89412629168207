import { Icons } from '@flash-tecnologia/hros-web-ui-v2';
import { SectionEmptyState } from '../../types';
import { Container, Description, StyledButton } from './styled';
import EmptyStateImage from '../../../../assets/PersonPageAdmin/section-empty-state.webp';
import dispatchToast from '@Utils/dispatchToast';

export const EmptySection = ({
  description,
  buttonText,
  path,
  emptyForm,
  setEmptyStateForm,
}: SectionEmptyState) => {
  return (
    <Container>
      <img src={EmptyStateImage} alt="empty-state" />
      <Description variant="body3">{description}</Description>
      <StyledButton
        size="large"
        variant="primary"
        onClick={() => {
          if (path) window.location.href = path;
          else if (emptyForm) setEmptyStateForm(true);
          else
            dispatchToast({
              type: 'error',
              content:
                'Pedimos desculpa! Ainda estamos implementando esta funcionalidade.',
            });
        }}
      >
        {buttonText}
        <Icons name="IconPlus" />
      </StyledButton>
    </Container>
  );
};
