import styled from 'styled-components';

import {
  DatePicker,
  TimePicker,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 16px 24px;
`;

export const Title = styled(Typography)`
  && {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.neutral[30]};
  }
`;

export const Row = styled.div<{ gap?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
  gap: ${({ gap }) => gap};
`;

export const StyledDatePicker = styled(DatePicker)`
  && {
    && {
      &.date-picker-container {
        width: 200px;
      }
    }
  }
`;

export const StyledTimePicker = styled(TimePicker)`
  width: 200px;
`;
