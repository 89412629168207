import { GenericProfilePicture } from '@Components/GenericProfilePicture';
import { IconButton, Icons } from '@flash-tecnologia/hros-web-ui-v2';
import dispatchToast from '@Utils/dispatchToast';
import axios from 'axios';
import {
  StyledModal,
  FooterContainer,
  FooterCancelButton,
  SubmitButton,
  ContentContainer,
  ContentWrapper,
  ProfilePictureChange,
  TextsWrapper,
  Title,
  Description,
  OldPic,
  NewPic,
} from './styled';
import { useState } from 'react';
import { getAccessToken } from '@flash-tecnologia/hros-web-utility';
import { env } from '@/lib/env';
import { trpc } from '@/api/client';

type Input = {
  isOpen: boolean;
  onClose: () => void;
  name: string;
  source: string;
  employeeId: string;
};

export const ModalDeleteProfilePicture = ({
  isOpen,
  onClose,
  name,
  source,
  employeeId,
}: Input) => {
  const [loading, setIsLoading] = useState(false);
  const context = trpc.useContext();

  const deleteProfilePicture = async () => {
    try {
      setIsLoading(true);
      const token = await getAccessToken();

      await axios.delete(`${env.GATEWAY_BASE_URL}/profile-pictures`, {
        data: { employeeId },
        headers: {
          Authorization: `Bearer ${token}`,
          'x-flash-auth': `Bearer ${token}`,
        },
      });

      await context.getEmployeeProfile.refetch();

      setIsLoading(false);
      dispatchToast({
        type: 'success',
        content: 'Edição salva com sucesso!',
      });

      onClose();
    } catch (error) {
      dispatchToast({
        content:
          'Sentimos muito, ocorreu um erro ao atualizar sua foto de perfil.',
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <StyledModal
      open={isOpen}
      onClose={onClose}
      footer={
        <FooterContainer>
          <FooterCancelButton onClick={onClose}>
            <Icons name="IconArrowLeft" fill="transparent" size={24} />
            <span>Voltar</span>
          </FooterCancelButton>
          <SubmitButton
            variant={'primary'}
            buttonType={'primary'}
            size={'small'}
            style={{ margin: 'auto 0', padding: '19px 52px' }}
            onClick={() => deleteProfilePicture()}
            loading={loading}
            disableRipple
            disableTouchRipple
          >
            <span>Remover</span>
            <Icons name="IconTrash" fill="transparent" size={32} />
          </SubmitButton>
        </FooterContainer>
      }
    >
      <ContentContainer>
        <ContentWrapper>
          <ProfilePictureChange>
            <OldPic>
              <GenericProfilePicture name={name} size={120} source={source} />
            </OldPic>
            <NewPic>
              <GenericProfilePicture name={name} size={120} />
            </NewPic>
          </ProfilePictureChange>
          <TextsWrapper>
            <Title variant="headline6">Remover foto de perfil?</Title>
            <Description variant="body3">
              Tem certeza que deseja remover sua foto de perfil? Você será
              identificado apenas pelas inicias do seu nome.{' '}
            </Description>
          </TextsWrapper>
        </ContentWrapper>
        <IconButton
          variant="line"
          size="small"
          icon="IconX"
          onClick={onClose}
        />
      </ContentContainer>
    </StyledModal>
  );
};
