import {
  SkeletonHeaderContainer,
  SkeletonName,
  SkeletonProfielPicture,
  SkeletonSecondaryTab,
  SkeletonSecondaryTabsWrapper,
  SkeletonSections,
  SkeletonSectionsWrapper,
  SkeletonTabsWrapper,
  SkeletonText,
  SkeletonTextWrapper,
} from './styled';

export const EmployeePageSkeleton = () => {
  return (
    <>
      <SkeletonHeaderContainer>
        <SkeletonProfielPicture variant="circular" />
        <SkeletonTextWrapper>
          <SkeletonName variant="text" />
          <SkeletonText variant="text" />
        </SkeletonTextWrapper>
      </SkeletonHeaderContainer>
      <SkeletonTabsWrapper>
        <SkeletonSecondaryTabsWrapper>
          <SkeletonSecondaryTab variant="rounded" />
          <SkeletonSecondaryTab variant="rounded" />
          <SkeletonSecondaryTab variant="rounded" />
          <SkeletonSecondaryTab variant="rounded" />
        </SkeletonSecondaryTabsWrapper>
        <SkeletonSectionsWrapper>
          <SkeletonSections variant="rounded" />
          <SkeletonSections variant="rounded" />
          <SkeletonSections variant="rounded" />
          <SkeletonSections variant="rounded" />
        </SkeletonSectionsWrapper>
      </SkeletonTabsWrapper>
    </>
  );
};
