import styled from 'styled-components';
import {
  Modal,
  Button,
  Typography,
  TextField,
  Icons,
} from '@flash-tecnologia/hros-web-ui-v2';

export const StyledModal = styled(Modal)`
  && {
    .modal-content-area {
      padding: 24px 40px; 24px; 40px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 420px;
  text-align: left;
`;

export const Header = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const Warning = styled(Typography)`
  && {
    font-weight: 700;
    color: var(--color-feedback-error-pure);
  }
`;

export const Title = styled.span`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;

  color: var(--color-neutral-dark1);

  margin-bottom: 16px;
`;

export const Subtitle = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: var(--color-neutral-50);
`;

export const AlertWrapper = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-self: flex-start;
  align-items: center;
  justify-content: center;
  background: var(--color-feedback-error-90);
  border-radius: 50%;
`;

export const AlterIcon = styled(Icons)`
  && {
    color: var(--color-feedback-error-40);
  }
`;

export const ConfirmWordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ConfirmDescription = styled(Typography)`
  color: var(--color-neutral-30) !important;
  font-weight: 700 !important;
  margin: 44px 0 16px !important;
`;

export const ConfirmInput = styled(TextField)`
  width: 100%;
`;

export const FooterContainer = styled.div`
  border: 1px solid var(--color-neutral-light2);
  border-style: solid hidden hidden hidden;

  padding: 12px 40px 12px 40px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const FooterCancelButton = styled.button`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;

  display: flex;
  align-items: flex-end;

  text-decoration: underline;

  text-underline-offset: 10px;

  color: var(--color-neutral-50);

  margin: 16px 0;
`;

export const SubmitButton = styled(Button)`
  /* color: var(--color-feedback-error-dark1) !important; */
`;
