export const deactivateModalText = {
  admin: {
    singleCompany: {
      title: 'Essa pessoa não poderá mais gerenciar esta empresa',
      description:
        'Você está tentando desativar uma pessoa que possui permissão de administrador da plataforma. Ao prosseguir, essa pessoa não terá mais permissões nesta empresa',
      checkboxText:
        'Estou ciente de que esta pessoa não terá mais permissões nesta empresa.',
    },
    multipleCompanies: {
      title:
        'Essa pessoa não poderá mais gerenciar nenhuma empresa deste grupo!',
      description:
        'Você está tentando desativar uma pessoa que possui permissão de administrador da plataforma. Ao prosseguir, essa pessoa não terá mais permissões em nenhuma empresa do grupo.',
      checkboxText:
        'Estou ciente de que esta pessoa não terá mais permissões nas empresas do grupo.',
    },
    confirmWord: 'desativar',
  },
  employee: {
    title: 'Tem certeza que deseja desativar esta pessoa?',
    description:
      'Ao desativá-la, esta pessoa perderá o acesso à plataforma e serviços, mas continuará aparecendo na sua lista de pessoas, podendo ser reativada posteriormente.',
    checkboxText: undefined,
  },
};
