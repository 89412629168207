import { Button } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const FooterContainer = styled.div`
  border: 1px solid var(--color-neutral-light2);
  border-style: solid hidden hidden hidden;

  padding: 16px 0px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const FooterConfirmButton = styled(Button)`
  background-color: var(--color-secondary-50);
  padding: 16px 24px;
  border-radius: 12px;
  font-weight: 700;
  color: var(--color-neutral-100);
  display: flex;
  gap: 10px;
`;
