import { Button, Icons } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const HeaderButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
`;
