import styled from 'styled-components';

export const Button = styled.button<{ $wasCopied?: boolean }>`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${({ $wasCopied }) =>
    $wasCopied ? 'var(--color-feedback-success-10)' : 'var(--color-primary)'};
  background-color: ${({ $wasCopied }) =>
    $wasCopied
      ? 'var(--color-feedback-success-90)'
      : 'var(--color-secondary-95)'};
  border: solid 1px
    ${({ $wasCopied }) =>
      $wasCopied ? 'var(--color-feedback-success-40)' : 'var(--color-primary)'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 8px 16px;
  border-radius: 92px;
  cursor: pointer;
  gap: 18px;
`;

export const LabelWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const CopyIconWrapper = styled.span<{ $wasCopied?: boolean }>`
  background-color: ${({ $wasCopied }) =>
    $wasCopied ? 'var(--color-feedback-success-40)' : 'var(--color-primary)'};
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  && {
    color: var(--color-neutral-100);
  }
  border-radius: 100%;
`;

export const LinkIconWrapper = styled.span<{ $wasCopied?: boolean }>`
  && {
    color: ${({ $wasCopied }) =>
      $wasCopied ? 'var(--color-feedback-success-40)' : 'var(--color-primary)'};
  }
`;
