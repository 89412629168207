import { HeaderContainer, Title, Subtitle } from './styled';

export const Header = () => {
  return (
    <HeaderContainer>
      <Title variant="headline6">Minha conta</Title>
      <Subtitle variant="body3">
        Configure dados de acesso à plataforma Flash.
      </Subtitle>
    </HeaderContainer>
  );
};
