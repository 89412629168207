import { ApolloProvider } from '@apollo/react-hooks';
import { ThemeProvider } from '@flash-tecnologia/hros-web-ui-v2';
import { makeApolloClient } from './utils/apollo';
import { QueryClientProvider } from '@tanstack/react-query';
import { trpc, trpcClient, queryClient } from './api/client';
import AppRouter from './routes';
import '../src/i18n';

const apolloClient = makeApolloClient();

export default function Root() {
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <ApolloProvider client={apolloClient}>
          <ThemeProvider>
            <AppRouter />
          </ThemeProvider>
        </ApolloProvider>
      </QueryClientProvider>
    </trpc.Provider>
  );
}
