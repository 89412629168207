import { Form } from './components/Form';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { Modal } from './styled';
import { useState } from 'react';
import { BatchActionTypes } from './BatchActionTypes';
import { Dayjs } from 'dayjs';
import { useRescheduleMassInvitations } from './hooks/useRescheduleMassInvitations';
import { useResendMassInvitations } from './hooks/useResendMassInvitations';
import dispatchToast from '@utils/dispatchToast';

interface BatchActionsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const BatchActionsModal = ({
  isOpen,
  onClose,
}: BatchActionsModalProps) => {
  const [selectedAction, setSelectedAction] = useState<BatchActionTypes>(
    BatchActionTypes.RESEND_INVITES,
  );
  const [selectedDate, setSelectedDate] = useState<Dayjs | undefined>(
    undefined,
  );

  const { resendMassInvitations, loading: isResendMassInvitationsLoading } =
    useResendMassInvitations();
  const {
    rescheduleMassInvitations,
    loading: isRescheduleMassInvitationsLoading,
  } = useRescheduleMassInvitations();

  const handleOnChange = (action: BatchActionTypes) => {
    setSelectedAction(action);
  };

  const handleOnConfirm = async () => {
    if (selectedAction === BatchActionTypes.RESEND_INVITES) {
      await resendMassInvitations();
    } else if (selectedAction === BatchActionTypes.RESCHEDULE_INVITES) {
      if (!selectedDate) {
        dispatchToast({
          type: 'error',
          content: 'Por favor, selecione uma data para continuar.',
        });
        return;
      }
      await rescheduleMassInvitations(selectedDate?.toDate());
    } else {
      dispatchToast({
        type: 'error',
        content: 'Por favor, selecione uma opção para continuar.',
      });
      return;
    }
    onClose();
  };

  return (
    <Modal
      onClose={onClose}
      open={isOpen}
      header={<Header onClose={onClose} />}
      footer={
        <Footer
          loading={
            isResendMassInvitationsLoading || isRescheduleMassInvitationsLoading
          }
          onClose={onClose}
          onConfirm={handleOnConfirm}
        />
      }
    >
      <Form
        selectedAction={selectedAction}
        onChange={handleOnChange}
        selectedDate={selectedDate!}
        setSelectedDate={setSelectedDate}
      />
    </Modal>
  );
};
