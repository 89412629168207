import { ABORT_IMPORT } from '@/api';
import { useMutation } from '@apollo/react-hooks';
import { useState } from 'react';

export const useAbortImport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [abortImport] = useMutation(ABORT_IMPORT, {
    onCompleted: async () => {
      setIsLoading(false);
    },
  });

  const abortImportProcess = async (importId: string) => {
    setIsLoading(true);
    await abortImport({
      variables: { importId },
    });
  };

  return {
    abortImportProcess,
    isLoading,
  };
};
