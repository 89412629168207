import styled from 'styled-components';
import { Button, Icons } from '@flash-tecnologia/hros-web-ui-v2';

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 23px;
  padding: 0 26px 80px 26px;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  max-width: 326px;

  @media screen and (max-width: 1100px) {
    max-width: 550px;
  }
`;

const RightColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  gap: 40px;
`;

const Card = styled.div`
  width: 100%;
  max-width: 981px;

  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  padding: 40px;
  border-radius: 10px;

  @media screen and (max-width: 450px) {
    padding: 15px;
  }
`;

const StyledIcon = styled(Icons)<{ setColor?: string; loading?: boolean }>`
  && {
    ${({ setColor, theme }) => {
      let color = '';
      switch (setColor) {
        case 'neutral50':
          color = theme.colors.neutral[50];
          break;
        case 'primary':
          color = theme.colors.primary;
          break;
        case 'feedback50':
          color = theme.colors.feedback.info[50];
          break;
        case 'success40':
          color = theme.colors.feedback.success[40];
          break;
        case 'error50':
          color = theme.colors.feedback.error[50];
          break;
      }

      return `color: ${color}`;
    }};
    ${({ loading }) => {
      if (loading) {
        return `
        
        animation: loader 2s linear infinite;
        
    @keyframes loader {
      0% {
        rotate: 0deg;
      }
      100% {
        rotate: -360deg;
      }
    }
    `;
      }
    }};
  }
`;

const IconContainer = styled.div<{ setColor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;

  width: 64px;
  min-width: 64px;
  height: 64px;
  border-radius: 200px;
  margin-bottom: 12px;

  background: ${({ setColor, theme }) => {
    switch (setColor) {
      case 'secondary95':
        return theme.colors.secondary[95];
      case 'queued':
      case 'processing':
        return theme.colors.feedback.info[90];
      case 'success90':
        return theme.colors.feedback.success[90];
      case 'error90':
        return theme.colors.feedback.error[90];
    }
  }};
`;

const TextContainer = styled.div`
  padding-bottom: 32px;
`;

const InfoContainer = styled.div`
  padding-bottom: 50px;
`;

const ProgressBarContainer = styled.div`
  padding-bottom: 70px;
`;

const ButtomContainer = styled.div`
  display: flex;
  gap: 24px;

  @media screen and (max-width: 875px) {
    flex-direction: column;
    gap: 15px;
  }
`;

const StyledButton = styled(Button)`
  width: 300px;

  @media screen and (max-width: 875px) {
    width: 100%;
    max-width: 500px;
    &.button-custom-theme-primary.MuiPaper-root.MuiButtonBase-root.size-large {
      padding: 16px 10px;
    }
    &.button-custom-theme-secondary.MuiPaper-root.MuiButtonBase-root.size-large {
      padding: 16px 10px;
    }
  }
`;

export {
  MainContainer,
  LeftColumn,
  RightColumn,
  Card,
  IconContainer,
  StyledIcon,
  TextContainer,
  InfoContainer,
  ProgressBarContainer,
  ButtomContainer,
  StyledButton,
};
