import { Button, Icons, Menu } from '@flash-tecnologia/hros-web-ui-v2';
import dispatchToast from '@utils/dispatchToast';
import * as SC from './styles';
import { useFetchCompanySpecialRedemptionCode } from '@/pages/PageTable/components/Table/components/CustomAccessMenu/useFetchCompanySpecialRedemptionCode';
import { env } from '@/lib/env';

export const CustomAccessMenu = () => {
  const { specialRedemptionCode, loading } =
    useFetchCompanySpecialRedemptionCode();

  if (loading || !specialRedemptionCode) return null;

  return (
    <Menu
      type={'select'}
      disableAutoFocus
      disableAutoFocusItem
      options={[
        {
          children: (
            <SC.OptionContainer>
              <Icons name="IconCopy" fill="transparent" />
              Copiar link de primeiro acesso
            </SC.OptionContainer>
          ),
          onClick: () => {
            navigator.clipboard.writeText(
              `${env.HROS_URL}/authentication/firstAccess/custom/${specialRedemptionCode}`,
            );
            dispatchToast({
              type: 'success',
              content: 'Link copiado com sucesso!',
            });
          },
        },
      ]}
    >
      <Button variant="tertiary" size="small">
        Primeiro acesso
        <Icons name="IconMail" fill="transparent" />
      </Button>
    </Menu>
  );
};
