import { IconButton } from '@flash-tecnologia/hros-web-ui-v2';
import { HeaderContainer, HeaderText, Title, Subtitle } from './styled';
import { HeaderInterface } from './types';

export const Header = ({ onClose }: HeaderInterface) => {
  return (
    <HeaderContainer>
      <HeaderText>
        <Title variant="headline7">Enviar convite de primeiro acesso</Title>
        <Subtitle variant="body4">
          Através desse convite de primeiro acesso, a pessoa irá criar sua conta
          para utilizar o app e plataforma da Flash.
        </Subtitle>
      </HeaderText>
      <IconButton icon="IconX" onClick={onClose} variant="line" size="small" />
    </HeaderContainer>
  );
};
