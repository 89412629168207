import gql from 'graphql-tag';

export const GET_IMPORT = gql`
  query GetImport($importId: String) {
    getImport(importId: $importId) {
      importId
      action
      fileId
      fileName
      status
      processedRows
      totalRows
      errors {
        row
        message
      }
    }
  }
`;
