import { Modal } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  && {
    &.modal-custom-theme .modal-container {
      ${({ theme }) => {
        const md = theme.breakpoints.values.md;
        const xl = theme.breakpoints.values.xl;
        const proportion = (md / xl) * 100;

        return `
          min-width: ${proportion}vw;
          @media screen and (min-width: ${xl}px) {
            min-width: 0;
            width: ${md}px;
          }`;
      }}
    }

    &.modal-custom-theme .modal-content-area {
      padding: 40px 112px 40px 112px;
    }
  }
`;
