import { getFromSS, setInSS } from '@flash-tecnologia/hros-web-utility';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

const TIMER_DURATION = 60;

export const useResendInviteTimer = () => {
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    let interval: NodeJS.Timer;

    const startTimer = async () => {
      const startedData = getFromSS('lastResendTime');
      const now = dayjs();
      const startedDate = startedData ? dayjs(startedData) : now;
      const diffSeconds = Math.floor(
        (now.toDate().getTime() - startedDate.toDate().getTime()) / 1000,
      );

      if (diffSeconds < TIMER_DURATION && diffSeconds > 0) {
        setTimer(TIMER_DURATION - diffSeconds);
      }
      interval = setInterval(() => setTimer((i) => (i > 0 ? i - 1 : 0)), 1000);
    };

    startTimer();

    return () => {
      clearInterval(interval as NodeJS.Timeout);
    };
  }, []);

  const resetTimer = () => {
    setInSS({
      key: 'lastResendTime',
      value: dayjs().toISOString(),
    });
    setTimer(TIMER_DURATION);
  };

  return {
    timer,
    resetTimer,
  };
};
