import { ImportType } from '@/pages/PageEmployeesWorksheet/types';
import { worksheetComponentInfo } from '../../../../componentsText';
import { StyledText, StyledTitle } from '@/common/styles/general-styles';
import {
  Icons,
  GenerateXLSXFile,
  Radio,
} from '@flash-tecnologia/hros-web-ui-v2';

import {
  RadioContainer,
  FileTypeContainer,
  RadioText,
  Card,
  DownloadContainer,
  HeaderIcon,
  ContainerDonwloadButton,
  InstructionsContainer,
  StyledAccordion,
  StyledAccordionHeader,
  AccordionItemContainer,
  LeftIconContainer,
  HeaderTextContainer,
  AccordionItemHeader,
  AccordionItemList,
  StyledTag,
  HorizontalLimit,
} from './styled';
import { fileColumns, fieldInstructions } from './fileInstructions';
import { useMemo } from 'react';

interface IChooseFileType {
  operation: ImportType;
  formik: any;
}
export const ChooseFileType = ({ operation, formik }: IChooseFileType) => {
  const importConfig = useMemo(() => {
    if (formik.values.simpleImport) {
      return {
        instructions: fieldInstructions.fields.slice(0, 5),
        fileConfig: fileColumns.slice(0, 5),
      };
    }
    return {
      instructions: fieldInstructions.fields,
      fileConfig: fileColumns,
    };
  }, [formik.values]);

  return (
    <Card>
      <StyledTitle
        setColor="neutral30"
        variant="headline8"
        children={
          worksheetComponentInfo[operation].uploadFile.chooseFileType.title
        }
      />
      <StyledText
        setColor="neutral50"
        variant="body3"
        children={
          worksheetComponentInfo[operation].uploadFile.chooseFileType
            .description
        }
      />
      <FileTypeContainer>
        <RadioContainer data-onboarding="employee-management-flash-model">
          <RadioText>
            <Radio
              size="small"
              name="defaultModel"
              checked={formik.values.defaultModel}
              value={formik.values.defaultModel}
              onChange={() => {
                formik.setFieldValue('defaultModel', true);
                formik.setFieldValue('userModel', false);
              }}
            />
            <StyledText
              setColor={
                Boolean(formik.errors.defaultModel) ? 'feedback50' : 'neutral30'
              }
              variant="body3"
              children="Modelo da Flash"
              style={{ fontWeight: '600' }}
            />
          </RadioText>
          {formik.values.defaultModel === true && (
            <DownloadContainer>
              <ContainerDonwloadButton>
                <GenerateXLSXFile
                  buttonTitle="Baixar modelo XLSX"
                  fileName="Pessoas.xlsx"
                  icon="IconDownload"
                  docIconSize={38}
                  data={[importConfig.fileConfig]}
                />
              </ContainerDonwloadButton>
              <ContainerDonwloadButton>
                <GenerateXLSXFile
                  buttonTitle="Baixar modelo CSV"
                  fileName="Pessoas.csv"
                  icon="IconDownload"
                  docIconSize={38}
                  data={[importConfig.fileConfig.toString()]}
                />
              </ContainerDonwloadButton>
              <ContainerDonwloadButton>
                <GenerateXLSXFile
                  buttonTitle="Baixar modelo TXT"
                  fileName="Pessoas.txt"
                  icon="IconDownload"
                  docIconSize={38}
                  data={[importConfig.fileConfig.toString()]}
                />
              </ContainerDonwloadButton>
            </DownloadContainer>
          )}
        </RadioContainer>
        <RadioContainer data-onboarding="employee-management-enterprise-model">
          <RadioText>
            <Radio
              size="small"
              name="userModel"
              checked={formik.values.userModel}
              value={formik.values.userModel}
              onChange={() => {
                formik.setFieldValue('defaultModel', false);
                formik.setFieldValue('userModel', true);
              }}
            />
            <StyledText
              setColor={
                Boolean(formik.errors.userModel) ? 'feedback50' : 'neutral30'
              }
              variant="body3"
              children="Modelo próprio da empresa"
              style={{ fontWeight: '600' }}
            />
          </RadioText>
        </RadioContainer>
      </FileTypeContainer>
      <InstructionsContainer>
        <StyledAccordion
          defaultExpanded={true}
          variant="disabled"
          customHeader={
            <StyledAccordionHeader>
              <LeftIconContainer>
                <HeaderIcon name={'IconListDetails'} fill="transparent" />
              </LeftIconContainer>
              <HeaderTextContainer>
                <StyledTitle
                  setColor="neutral30"
                  variant="headline8"
                  children={fieldInstructions.header.title}
                />
                <StyledText
                  setColor="neutral50"
                  variant="caption"
                  children={fieldInstructions.header.description}
                />
              </HeaderTextContainer>
            </StyledAccordionHeader>
          }
          children={importConfig.instructions.map((instruction, index) => (
            <>
              {index !== 0 && <HorizontalLimit />}
              <AccordionItemContainer key={`${instruction.title}-${index}`}>
                <AccordionItemHeader>
                  <Icons name={instruction.icon} fill="transparent" size={16} />
                  <StyledTitle
                    setColor="neutral30"
                    variant="body4"
                    children={instruction.title}
                  />
                  {instruction.tag && (
                    <StyledTag variant="primary">{instruction.tag}</StyledTag>
                  )}
                </AccordionItemHeader>
                <AccordionItemList>
                  {instruction.bullets
                    ? instruction.bullets.map((item, index) => (
                        <li key={`${item}-${index}`}>
                          <StyledText
                            setColor="neutral50"
                            variant="caption"
                            children={item}
                          />
                        </li>
                      ))
                    : null}
                </AccordionItemList>
              </AccordionItemContainer>
            </>
          ))}
        ></StyledAccordion>
      </InstructionsContainer>
    </Card>
  );
};
