import { DocumentNode, useApolloClient } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';

type UseRetryQueryInput<Output> = {
  document: DocumentNode;
  maxAttempts: number;
  interval: number;
  stopCondition: (data: Output) => boolean;
  onCompleted: (data: Output) => void;
};

export const useRetryQuery = <Input, Output>({
  maxAttempts,
  document,
  interval,
  stopCondition,
  onCompleted,
}: UseRetryQueryInput<Output>) => {
  const [attempts, setAttempts] = useState(0);
  const [data, setData] = useState<Output | null>();
  const [loading, setLoading] = useState(false);
  const { query } = useApolloClient();
  const [variables, setVariables] = useState<Input>();
  const [isPolling, setIsPolling] = useState(false);

  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);

  const clearAttempts = () => {
    setAttempts(0);
    intervalIdRef?.current && clearInterval(intervalIdRef.current);
    setLoading(false);
    setIsPolling(false);
  };

  const polling = async () => {
    if (attempts < maxAttempts && isPolling) {
      const { data } = await query({
        query: document,
        variables: variables!,
        fetchPolicy: 'network-only',
      });
      setData(data);
      setAttempts((prevAttempt) => prevAttempt + 1);
    } else {
      if (attempts === maxAttempts && onCompleted) {
        onCompleted(data!);
      }
      clearAttempts();
    }
  };

  useEffect(() => {
    if (isPolling) {
      intervalIdRef.current = setInterval(polling, interval);
    }

    if (stopCondition(data!)) {
      onCompleted(data!);
      clearAttempts();
    }

    return () => clearInterval(intervalIdRef.current!);
  }, [attempts, isPolling]);

  const start = (vars: Input) => {
    setData(null);
    setVariables(vars);
    setIsPolling(true);
    setLoading(true);
  };

  return {
    query: start,
    data,
    loading,
  };
};
