import { Button, Modal, Typography } from '@flash-tecnologia/hros-web-ui-v2';

import styled from 'styled-components';

const StyledModal = styled(Modal)`
  && {
    &.modal-custom-theme .modal-container {
      width: 100%;
      max-width: 872px;
      max-height: 95vh;
    }
    &.modal-custom-theme .modal-content-area {
      padding: 0px;
    }
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 40px 0px 40px 112px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 536px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[80]};
  padding: 44px 24px 40px 112px;
`;

const Card = styled.div`
  width: 100%;
  max-width: 645px;

  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  padding: 40px;
  border-radius: 10px;
`;

const Line = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  height: 1px;

  margin-top: 24px;
`;

const Imagem = styled.img`
  width: 100%;
  margin-top: 24px;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[80]};
  padding: 44px 0px;
`;

const StyledButton = styled(Button)`
  && {
    width: 198px;
  }
`;
export {
  TextContainer,
  HeaderContainer,
  Card,
  Line,
  Imagem,
  FooterContainer,
  StyledButton,
  StyledModal,
  MainContainer,
};
