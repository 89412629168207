import { Tag, Typography, IconButton } from '@flash-tecnologia/hros-web-ui-v2';
import { Divider } from '@mui/material';
import styled, { css } from 'styled-components';

export const SubsectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 45px;
`;

export const Title = styled(Typography)``;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const StyledTag = styled(Tag)`
  padding: 4px 12px;
`;

export const Description = styled(Typography)``;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const SubsectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const TextsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const StyledDivider = styled(Divider)`
  margin-top: 32px;
`;

export const IconButtonStyled = styled(IconButton)<{ editing?: boolean }>`
  && {
    ${({ editing }) =>
      editing &&
      css`
        &.MuiButtonBase-root {
          border-color: ${({ theme }) => theme.colors.feedback.success[40]};

          svg {
            stroke: ${({ theme }) => theme.colors.feedback.success[40]};
          }
        }
      `}
  }
`;
