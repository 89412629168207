import { IconButton } from '@flash-tecnologia/hros-web-ui-v2';

import {
  SuccessWrapper,
  AlterIcon,
  Container,
  TextWrapper,
  Title,
  StyledModal,
} from './styled';
import { ModalSuccessInterface } from './types';
import { Footer } from './components/Footer';

export const ModalSuccess = ({
  isOpen,
  title,
  onClose,
  onSubmit,
  submitText,
  content,
}: ModalSuccessInterface) => {
  return (
    <StyledModal
      open={isOpen}
      onClose={onClose}
      footer={<Footer onSubmit={onSubmit} confirmText={submitText} />}
    >
      <Container>
        <TextWrapper>
          <SuccessWrapper>
            <AlterIcon name="IconCheck" fill="transparent" size={42} />
          </SuccessWrapper>
          <Title>{title}</Title>
          {content}
        </TextWrapper>
        <IconButton
          icon="IconX"
          onClick={onClose}
          variant="line"
          size="small"
        />
      </Container>
    </StyledModal>
  );
};
