import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Checkbox, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import dayjs from 'dayjs';

import {
  AdornmentButton,
  FieldTextInput,
  FieldRow,
  FormContainer,
  BottomTip,
  FieldSelect,
  Title,
  FormHeader,
  RequiredSymbol,
  FieldTextLabel,
} from './styled';
import { defaultFields } from './defaultFields';
import {
  cpfMask,
  phoneMask,
  passportMask,
  removeSpecialCharacters,
  validateEmail,
  validatePhone,
  validateCPF,
} from './utils';

import { Divider } from '@mui/material';

import { CustomFields } from './components/CustomFields';
import { DefaultFieldType, FormInterface } from './types';
import { controlledFields } from './controlledFields';

export const Form = ({ onError, onSubmit, blur, formId }: FormInterface) => {
  const [formFields, setFormFields] =
    useState<DefaultFieldType[]>(defaultFields);

  const [customFields, setCustomFields] = useState({
    selectedPermissionProfile: undefined,
    permissionError: false,
    scheduleInvite: false,
    dateError: false,
    timeError: false,
    inviteDate: null,
    inviteTime: dayjs().set('hour', 8).set('minute', 0).set('second', 0),
  });

  const resetFields = () => {
    const newFormFieldsValues = formFields?.map((field: any) => {
      field = { ...defaultFields.find(({ key }) => key === field.key) };
      return field;
    });

    setFormFields([...newFormFieldsValues]);
  };

  const getRequiredFieldsWithEmptyValue = () =>
    formFields?.filter(
      ({ value, required, hidden }) => required && !value && !hidden,
    );

  const formatData = (fields: Array<any>) => {
    const data: any = {};
    fields.forEach(({ key, value }) => {
      if (!value) return;
      data[key] = ['documentNumber', 'phone'].includes(key)
        ? removeSpecialCharacters(value)
        : value;
    });
    return data;
  };

  const clearWarnings = () =>
    setFormFields((f) => [
      ...f?.map((field) => {
        return {
          ...field,
          error: false,
          helperText: '',
        };
      }),
    ]);

  const showEmptyFieldsError = () => {
    clearWarnings();
    setFormFields((f) => [
      ...f?.map((field) => {
        if (field.required && !field.value) {
          field.error = true;
          field.helperText = 'Campo obrigatório';
        }
        return field;
      }),
    ]);
  };

  const onBlur = (e: any, field: any) => {
    if (e && blur?.onBlur && blur?.affectedFields?.includes(field?.key)) {
      blur
        .onBlur({
          key: field?.key,
          value:
            [
              'nationalDocumentNumber',
              'foreignDocumentNumber',
              'phone',
            ]?.includes(field?.key) && typeof field?.value === 'string'
              ? removeSpecialCharacters(field?.value)
              : field?.value,
        })
        .then(({ error, helperText }) => {
          if (error && helperText && !field?.disabled)
            showFieldError(field?.key, helperText);
        });
    }
  };

  const onSubmitForm = (e: FormEvent) => {
    e.preventDefault();

    let errors: string[] = [];

    const requiredFieldsWithEmptyValue = getRequiredFieldsWithEmptyValue();
    const emailField = formFields.find(({ key }) => key === 'email');
    const phoneField = formFields.find(({ key }) => key === 'phone');
    const documentField = formFields.find(
      ({ key }) => key === 'nationalDocumentNumber',
    );
    const email = typeof emailField?.value === 'string' ? emailField.value : '';
    const phone = typeof phoneField?.value === 'string' ? phoneField.value : '';
    const cpf =
      typeof documentField?.value === 'string' ? documentField.value : '';
    const inviteDate = customFields.inviteDate;
    const inviteTime = customFields.inviteTime;

    if (requiredFieldsWithEmptyValue?.length) {
      showEmptyFieldsError();
      requiredFieldsWithEmptyValue.forEach((error) => errors.push(error.label));
    }

    if (!validateEmail(email) && email !== '' && emailField) {
      showFieldError('email', 'E-mail inválido');
      errors.push(emailField?.label);
    }

    if (!validatePhone(phone) && phone !== '' && phoneField) {
      showFieldError('phone', 'Celular inválido');
      errors.push(phoneField.label);
    }

    if (!validateCPF(cpf) && cpf !== '' && documentField) {
      showFieldError('nationalDocumentNumber', 'CPF inválido');
      errors.push(documentField.label);
    }

    if (!!!inviteDate && customFields.scheduleInvite) {
      setCustomFields((prev) => {
        return {
          ...prev,
          dateError: true,
        };
      });

      errors.push('Data de agendamento');
    }

    if (!!!inviteTime && customFields.scheduleInvite) {
      setCustomFields((prev) => {
        return {
          ...prev,
          timeError: true,
        };
      });

      errors.push('Horário de agendamento');
    }

    if (errors.length) {
      if (onError) onError(errors);
      return;
    }

    if (onSubmit)
      onSubmit(
        formatData(formFields),
        customFields?.selectedPermissionProfile!,
        customFields?.inviteDate!,
        customFields?.inviteTime!,
        customFields?.scheduleInvite,
      );
  };

  const handleAndAddMask = (fieldKey: string, fieldValue: string) => {
    if (typeof fieldValue !== 'string') return fieldValue;
    if (fieldKey === 'phone') return phoneMask(fieldValue);
    if (fieldKey === 'nationalDocumentNumber') return cpfMask(fieldValue);
    if (fieldKey === 'foreignDocumentNumber') return passportMask(fieldValue);

    return fieldValue;
  };

  const onFieldValueChange = (value: string, fieldIndex: number) => {
    const newFormFieldsValues = formFields?.map((field, index) => {
      if (index !== fieldIndex) return field;

      field.error = false;
      field.value = handleAndAddMask(field.key, value);
      return field;
    });

    setFormFields([...newFormFieldsValues]);
  };

  const showFieldError = (
    fieldKey: DefaultFieldType['key'],
    errorMessage: string,
  ) =>
    setFormFields([
      ...formFields?.map((field) => {
        if (field.key === fieldKey) {
          field.error = true;
          field.helperText = errorMessage;
        }
        return field;
      }),
    ]);

  const setRequired = useMemo(
    () => (fieldKey: DefaultFieldType['key']) =>
      setFormFields([
        ...formFields?.map((field) => {
          if (field.key !== fieldKey) return field;
          field.value = '';
          field.error = false;
          field.required = !!!field.required;
          field.disabled = !!!field.required;
          return field;
        }),
      ]),
    [formFields],
  );

  const setNationality = useMemo(
    () => (optionValue: string) => {
      if (optionValue === 'Brasileira') {
        setFormFields((prevFormFields) => {
          return prevFormFields.map((field) => {
            if (field.key === 'nationalDocumentNumber') {
              field.hidden = false;
            }

            if (field.key === 'foreignDocumentNumber') {
              field.hidden = true;
              field.error = false;
            }

            if (field.key === 'nationality') {
              field.value = optionValue;
              field.error = false;
            }

            return field;
          });
        });
      } else if (optionValue === 'Estrangeira') {
        setFormFields((prevFormFields) => {
          return prevFormFields.map((field) => {
            if (field.key === 'nationalDocumentNumber') {
              field.hidden = true;
              field.error = false;
            }

            if (field.key === 'foreignDocumentNumber') {
              field.hidden = false;
            }

            if (field.key === 'nationality') {
              field.value = optionValue;
              field.error = false;
            }

            return field;
          });
        });
      }
    },
    [],
  );

  const buildFieldLabel = useMemo(
    () => (field: DefaultFieldType) => {
      if (field.bottomTip && field.required) {
        return (
          <>
            {`${field.label} para o envio do convite`}
            <RequiredSymbol>*</RequiredSymbol>
          </>
        );
      }

      if (field.required) {
        return (
          <>
            {field.label}
            <RequiredSymbol>*</RequiredSymbol>
          </>
        );
      }

      return field.label;
    },
    [],
  );

  const toggleRequiredField = useCallback(
    (fieldKey: DefaultFieldType['key']) => {
      setFormFields([
        ...formFields?.map((field) => {
          if (field.key !== fieldKey) return field;
          return {
            ...field,
            required: !field.required,
          };
        }),
      ]);
    },
    [formFields],
  );

  const renderAdornment = useMemo(
    () => (fieldKey: DefaultFieldType['key'], callback: () => void) => {
      const disabledField = formFields.find(
        ({ key }) => key === fieldKey,
      )?.disabled;

      return (
        <AdornmentButton
          onClick={() => {
            setRequired(fieldKey);
            callback();
          }}
          type="button"
        >
          <Checkbox
            disabled={disabledField}
            checked={!formFields.find(({ key }) => key === fieldKey)?.required}
          />
          <Typography variant="body3">
            {formFields.find(({ key }) => key === fieldKey)?.adornmentText}
          </Typography>
        </AdornmentButton>
      );
    },
    [formFields],
  );

  const renderDynamicBottomTip = (field: DefaultFieldType) => {
    if (!field.bottomTip || !field.required) return;

    return <BottomTip variant="caption">{field.bottomTip}</BottomTip>;
  };

  const renderFields = useMemo(
    () => () => {
      return formFields.map((field, i) => {
        switch (field.type) {
          case 'text':
            return (
              <div key={field.key}>
                {!field.hidden && (
                  <FieldRow>
                    <FieldTextInput
                      {...field}
                      label={
                        <FieldTextLabel variant="body3">
                          {buildFieldLabel(field)}
                        </FieldTextLabel>
                      }
                      disabled={field.disabled}
                      helperText={field?.error ? field?.helperText : ''}
                      placeholder={field.placeholder}
                      onChange={(e) => onFieldValueChange(e.target.value, i)}
                      InputProps={
                        field.adornment &&
                        ({
                          endAdornment: renderAdornment(field.key, () =>
                            toggleRequiredField(controlledFields[field.key]),
                          ),
                        } as any)
                      }
                      inputProps={{ maxLength: field.maxLength }}
                      onBlur={(e) => onBlur(e, field)}
                    />
                    {renderDynamicBottomTip(field)}
                  </FieldRow>
                )}
              </div>
            );
          case 'select':
            const selectedNationality = field?.options?.find(
              (option) => field.value === option.label,
            )?.value;

            return (
              <FieldRow key={field.key}>
                <FieldSelect
                  {...field}
                  helperText={field?.error ? field?.helperText : ''}
                  label={field.label}
                  options={field.options}
                  value={
                    selectedNationality !== undefined ? selectedNationality : ''
                  }
                  onSelectChange={(_, { value }) => {
                    setNationality(field.options![value].label);
                  }}
                />
              </FieldRow>
            );

          default:
            return <div key={field.key}></div>;
        }
      });
    },
    [formFields],
  );

  useEffect(() => {
    resetFields();

    return () => {
      resetFields();
    };
  }, []);

  return (
    <FormContainer onSubmit={onSubmitForm} id={formId}>
      <FormHeader>
        <Title variant="headline8">Dados para envio do convite</Title>
        <Title variant="caption">
          <RequiredSymbol>*</RequiredSymbol>Campos obrigatórios
        </Title>
      </FormHeader>
      {renderFields()}
      <Divider />
      <CustomFields
        setCustomFields={setCustomFields}
        createInviteProps={{
          scheduleInvite: customFields?.scheduleInvite,
          inviteDate: customFields?.inviteDate!,
          inviteTime: customFields?.inviteTime,
          dateError: customFields?.dateError,
          timeError: customFields?.timeError,
        }}
      />
    </FormContainer>
  );
};
