import { IconButton } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const ImageWrapper = styled.div<{ size: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;
  background: transparent;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  min-height: ${({ size }) => size}px;
  min-width: ${({ size }) => size}px;

  border: 1px solid var(--color-neutral-light2);
  border-radius: 50%;
`;

export const NameWrapper = styled.div<{ size: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;
  background: var(--color-secondary-95);
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  min-height: ${({ size }) => size}px;
  min-width: ${({ size }) => size}px;
`;

export const NameInitials = styled.span<{ size: number }>`
  color: var(--color-primary);
  font-size: ${({ size }) => size / 2.5}px;
  font-weight: 700;
`;

export const EditButtonsWrapper = styled.div`
  display: none;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`;

export const ProfilePictureHover = styled.div<{ size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    background: rgba(21, 25, 28, 0.68);
    backdrop-filter: blur(3px);
    border-radius: 50%;

    ${EditButtonsWrapper} {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-evenly;
    }
  }
`;

export const EditCircle = styled.button<{ size: number }>`
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50%;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  display: flex;
  place-content: center;
  align-items: center;
  justify-content: center;
`;
