import { IconButton } from '@flash-tecnologia/hros-web-ui-v2';
import { HeaderContainer, HeaderText, Title, Subtitle } from './styled';

interface HeaderProps {
  onClose: () => void;
}

export const Header = ({ onClose }: HeaderProps) => {
  return (
    <HeaderContainer>
      <HeaderText>
        <Title variant="headline6">Reenviar convite em massa</Title>
        <Subtitle variant="body3">
          Pessoas com os status de “convite enviado”, “convite agendado” e
          "convite expirado" receberão o convite novamente, substituindo o envio
          ou o agendamento feito anteriormente. Pessoas que já fizeram o
          primeiro acesso não receberão novamente.
        </Subtitle>
      </HeaderText>
      <IconButton icon="IconX" onClick={onClose} variant="line" size="small" />
    </HeaderContainer>
  );
};
