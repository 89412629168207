import { GenericProfilePicture } from '@Components/GenericProfilePicture';
import { ModalConfirmAction } from '@Components/ModalConfirmAction';
import { StatusTag } from '@Components/StatusTag';
import {
  IconButton,
  Icons,
  Menu,
  dayjs,
} from '@flash-tecnologia/hros-web-ui-v2';
import {
  usePermissions,
  useSelectedCompany,
} from '@flash-tecnologia/hros-web-utility';
import dispatchToast from '@Utils/dispatchToast';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  ButtonsWrapper,
  HeaderContainer,
  HeaderContent,
  Name,
  TextsWrapper,
  NameWrapper,
  DetailTextsWrapper,
  Option,
  DetailText,
  DetailReportsTo,
} from './styled';
import { EmployeePageHeaderInterface } from './types';
import { StatusEnum } from '../../common/types/StatusEnum';
import { useEmployeePageHeader } from './useEmployeePageHeader';
import { env } from '@/lib/env';
import { setEventTracking } from '@/utils';
import { useSession } from '@/common/user';
import { useGetEmployeePermissions } from '@/pages/PageTable/hooks/useGetEmployeePermissions';
import { DeactivateConfirmationModal } from '@/pages/PageTable/components/Header/components/DeactivateConfirmationModal/deactivate-confirmation-modal';
import { deactivateModalText } from '@utils/deactivateModalText';

export const EmployeePageHeader = ({
  employeeId,
  name,
  documentNumber,
  profilePicture,
  status,
  startDate,
  manager,
  showOptions,
  showDocumentManagement,
  profilePictureEdit,
  invitationCode,
  profilePictureEditCallback,
  profilePictureDeleteCallback,
}: EmployeePageHeaderInterface) => {
  const {
    deactivateModalIsOpen,
    deleteModalIsOpen,
    isDeactivating,
    isDeleting,
    setDeactivateModalIsOpen,
    setDeleteModalIsOpen,
    deactivateEmployee,
    deleteEmployee,
    activateEmployee,
  } = useEmployeePageHeader();
  const { selectedCompany } = useSelectedCompany();
  const { authUser, userId } = useSession();
  const { companies } = usePermissions();
  const canManageEmployees = companies
    .find((c) => c.id === selectedCompany?.id)
    ?.permissions?.some((p) => p === 'core_create_employee' || p === '*');

  const { isAdmin, companies: companiesWithPermissions } =
    useGetEmployeePermissions(employeeId!);

  const options = useMemo(() => {
    const deactivate = {
      key: 'deactivate',
      onClick: () => setDeactivateModalIsOpen(true),
      children: (
        <Option>
          <Icons name="IconUserOff" fill="transparent" />
          Desativar pessoa
        </Option>
      ),
    };

    const deleteEmp = {
      key: 'delete',
      onClick: () => setDeleteModalIsOpen(true),
      children: (
        <Option>
          <Icons name="IconUserOff" fill="transparent" />
          Deletar pessoa
        </Option>
      ),
    };

    const activate = {
      key: 'activate',
      onClick: () => {
        dispatchToast({
          type: 'warning',
          content: 'Ativando colaborador...',
        });
        activateEmployee({
          variables: { employeeId, companyId: selectedCompany.id },
        });
      },
      children: (
        <Option>
          <Icons name="IconUser" fill="transparent" />
          Ativar pessoa
        </Option>
      ),
    };

    const copyFirstAccessLink = {
      key: 'copyFirstAccessLink',
      onClick: () => {
        setEventTracking('employee_page_copy_invite_clicked', {
          employeeId: employeeId!,
        });
        navigator.clipboard.writeText(
          `${env.HROS_URL}/authentication/first-access?invitationToken=${invitationCode}`,
        );
        dispatchToast({
          type: 'success',
          content: 'Link copiado com sucesso',
        });
      },
      children: (
        <Option>
          <Icons name="IconLink" fill="transparent" />
          Copiar link de primeiro acesso
        </Option>
      ),
    };

    if (!canManageEmployees) {
      return [];
    } else if (
      status === StatusEnum.ACTIVE &&
      employeeId !== userId &&
      authUser?.username !== documentNumber
    ) {
      return [deactivate];
    } else if (status === StatusEnum.INACTIVE) {
      return [activate];
    } else if (status === StatusEnum.INVITATION_SENT && invitationCode) {
      return [copyFirstAccessLink, deleteEmp];
    } else if (employeeId !== userId) {
      return [deleteEmp];
    }
    return [];
  }, [invitationCode, status, selectedCompany, userId, employeeId]);

  return (
    <>
      <HeaderContainer>
        <HeaderContent>
          <GenericProfilePicture
            name={name}
            size={64}
            source={profilePicture}
            enableEdit={profilePictureEdit}
            editCallback={profilePictureEditCallback}
            deleteCallback={profilePictureDeleteCallback}
          />
          <TextsWrapper>
            <NameWrapper>
              <Name variant="headline6">{name}</Name>
              <StatusTag status={status} />
            </NameWrapper>
            <DetailTextsWrapper>
              {startDate && dayjs(startDate).isValid() && (
                <DetailText variant="body4">
                  Admissão em {dayjs(startDate).format('DD/MM/YYYY')}
                </DetailText>
              )}
              {startDate && manager && (
                <DetailText variant="body4">•</DetailText>
              )}
              {manager && (
                <DetailText variant="body4">
                  Reporta para
                  <DetailReportsTo> {manager.name}</DetailReportsTo>
                </DetailText>
              )}
            </DetailTextsWrapper>
          </TextsWrapper>
        </HeaderContent>
        <ButtonsWrapper>
          {showDocumentManagement && (
            <Link to={`/document-management/employee/${employeeId}`}>
              <IconButton variant="line" size="large" icon="IconFolder" />
            </Link>
          )}
          <Link to={`/orgchart`}>
            <IconButton variant="line" size="large" icon="IconHierarchy" />
          </Link>
          {showOptions && (
            <Menu
              type={'select'}
              options={options}
              disableAutoFocusItem={true}
              anchorOrigin={{ vertical: 75, horizontal: -175 }}
            >
              <IconButton variant="line" size="large" icon="IconDotsVertical" />
            </Menu>
          )}
        </ButtonsWrapper>
      </HeaderContainer>
      <DeactivateConfirmationModal
        isOpen={deactivateModalIsOpen}
        loading={isDeactivating}
        isAdmin={isAdmin}
        companiesWithPermissions={companiesWithPermissions}
        submitText="Desativar pessoa"
        confirmWord={
          isAdmin || companiesWithPermissions?.length
            ? deactivateModalText.admin.confirmWord
            : undefined
        }
        onClose={() => setDeactivateModalIsOpen(false)}
        onSubmit={() => {
          setEventTracking('employee_deactivate_confirmed_clicked', {
            deactivatedEmployee: employeeId!,
          });
          deactivateEmployee({
            employeeId,
            companyId: selectedCompany.id,
          });
        }}
      />
      <ModalConfirmAction
        isOpen={deleteModalIsOpen}
        loading={isDeleting}
        title="Tem certeza que deseja deletar esta pessoa?"
        description="Ao deletá-la, esta pessoa perderá o acesso à plataforma e serviços, e não conseguirá acessar a plataforma. Será preciso um novo cadastro."
        submitText="Deletar"
        onClose={() => setDeleteModalIsOpen(false)}
        onSubmit={() =>
          deleteEmployee({
            employeeId: employeeId!,
            companyId: selectedCompany.id,
          })
        }
      />
    </>
  );
};
