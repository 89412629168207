import styled from 'styled-components';
import { Button, Icons, Modal } from '@flash-tecnologia/hros-web-ui-v2';

const StyledModal = styled(Modal)`
  && {
    &.modal-custom-theme .modal-container {
      width: 100%;
      max-width: 645px;
      max-height: 95vh;
    }
    &.modal-custom-theme .modal-content-area {
      padding: 0px;
    }
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  margin: 0 auto;
  width: 100%;
  max-width: 421px;
  padding: 0px 20px 42px 20px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 536px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 24px;
  padding-right: 24px;
  margin-bottom: -20px;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[80]};
  gap: 21px;
  padding: 24px;

  @media screen and (max-width: 450px) {
    flex-direction: column-reverse;
  }
`;

const StyledButton = styled(Button)<{ onLoading?: boolean }>`
  && {
    width: 226px;

    &.button-custom-theme-primary.MuiPaper-root.MuiButtonBase-root {
      background-color: ${({ theme }) => theme.colors.feedback.error[90]};
      color: ${({ theme, onLoading }) =>
        onLoading ? 'whitef' : theme.colors.feedback.error[40]};

      &.MuiPaper-root.MuiButtonBase-root {
        :hover {
          box-shadow: 0px 0px 0px white;
          background-color: ${({ theme }) => theme.colors.feedback.error[90]};
        }
      }
    }
  }
`;

const StyledIcon = styled(Icons)`
  && {
    color: ${({ theme }) => theme.colors.feedback.error[40]}};
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 64px;
  min-width: 64px;
  height: 64px;

  background-color: ${({ theme }) => theme.colors.feedback.error[90]}};;
  border-radius: 200px;
  margin-bottom: 8px;
`;

export {
  StyledModal,
  MainContainer,
  TextContainer,
  HeaderContainer,
  FooterContainer,
  StyledButton,
  StyledIcon,
  IconContainer,
};
